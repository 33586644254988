import React, { Component } from 'react'
import { Search, Icon } from 'semantic-ui-react'
import ClearButton from '../../ClearButton'

import PropTypes from 'prop-types'

class SearchFilter extends Component {
    static propTypes = {
        displayOptions: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        initialValue: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            value: props.initialValue || '',
        }
    }

    onClear() {
        this.setState({ value: '' })
        this.props.onChange('')
        document.getElementById('datatable_search').focus()
    }

    onSearchChange(e, item) {
        this.setState({ value: item.value })
        this.props.onChange(item.value)
    }

    render() {
        return (
            <Search
                id="datatable_search"
                fluid
                loading={false}
                input={{ fluid: true }}
                icon={
                    this.state.value ? (
                        <ClearButton onClear={this.onClear.bind(this)} />
                    ) : (
                        <Icon name="search" />
                    )
                }
                onSearchChange={this.onSearchChange.bind(this)}
                value={this.state.value}
                showNoResults={false}
                placeholder="Rechercher..."
                {...this.props.displayOptions}
            />
        )
    }
}

export default SearchFilter
