import React from 'react'
import { Label, Icon } from 'semantic-ui-react'
import Styles from './styles.module.css'

export const StatusBar = ({ status, align }) => {
    return (
        <Label
            size="large"
            className={`${Styles.StatusBar} ${
                align === 'right' ? Styles.StatusBarRight : ''
            }`}
            data-testid="status-bar"
        >
            <Icon name="setting" />
            {status}
        </Label>
    )
}
