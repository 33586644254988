import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Input, Modal } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"
import { ResultsList } from './ResultsList'
import Styles from './styles.module.css'
import { MIN_WIDTH } from '../../../../app-constants'

export class HeaderSearchProductModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            results: [],
            currentSearch: '',
            focusIndex: -1,
        }

        this.setResults = results => this.setState({ results })
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleSearchChange(e, data) {
        this.setState({
            loading: data.value && data.value.length < 3,
            currentSearch: data.value,
        })
        const results = this.props.runSearch(data.value)
        this.setResults(results)
    }

    clearSearch() {
        this.setState({
            results: [],
            loading: false,
            currentSearch: '',
            focusIndex: 0
        })
    }

    handleSelect(data) {
        this.props.onSelect({
            family: data.family_id,
            category: data.category_id,
        })
        this.clearSearch()
    }

    handleKeyDown(e) {
        if (e.key === 'Enter' && this.state.focusIndex < 0) {
            this.handleSelect(this.state.results[0])
        }
        else if (e.key === 'Enter' && this.state.focusIndex > -1) {
            this.handleSelect(this.state.results[this.state.focusIndex])
        }
        else if (e.key === 'ArrowDown') {
            this.setState({ focusIndex: (this.state.focusIndex + 1) % this.state.results.length })
        }
        else if (e.key === 'ArrowUp' && this.state.focusIndex > 0) {
            this.setState({ focusIndex: this.state.focusIndex - 1 })
        }
    }

    render() {
        const { searchProductModalOpen, closeSearchProductModal } = this.props

        const closeIconClass = `close icon ${Styles.closeIcon}`

        const dimmerClickHandler = () => {
            if (window.innerWidth < 500) {
                closeSearchProductModal()
                this.clearSearch()
            }
        }

        return (
            <Modal
                size="small"
                open={searchProductModalOpen}
                onClose={() => dimmerClickHandler()}
                onOpen={() => closeSearchProductModal()}
                dimmer={{ inverted: true }}
                centered={false}
                title="search-modal"
            >
                <Responsive
                    as={Fragment}
                    minWidth={MIN_WIDTH.MD}
                >
                    <i
                        aria-hidden="true"
                        className={closeIconClass}
                        onClick={closeSearchProductModal}
                    />
                </Responsive>

                <Modal.Content>
                    <div className={Styles.ContentContainer}>
                        <Input
                            fluid
                            action={{ icon: 'search', size: 'big', onClick: () => this.handleSelect(this.state.focusIndex < 0 ? this.state.results[0] : this.state.results[this.state.focusIndex]) }}
                            placeholder="Je cherche un produit..."
                            autoFocus={true}
                            size="big"
                            loading={this.state.loading}
                            onChange={this.handleSearchChange}
                        />
                        <ResultsList
                            results={this.state.results}
                            currentSearch={this.state.currentSearch}
                            handleSelect={this.handleSelect}
                            focusIndex={this.state.focusIndex}
                        />
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

HeaderSearchProductModal.propTypes = {
    runSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    searchProductModalOpen: PropTypes.bool,
    closeSearchProductModal: PropTypes.func.isRequired,
}
