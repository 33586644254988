import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

export const ProductProducerLink = ({ producer, closeCallback }) => (
    <div style={{ textAlign: 'center' }}>
        <Link
            to={`/commande/producteurs/${producer._id}`}
            onClick={closeCallback}
        >
            <Button
                content="Tous les produits de ce producteur"
                icon="right arrow"
                labelPosition="right"
            />
        </Link>
    </div>
)
