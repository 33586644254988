import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/browser'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAGMANAGER_ID,
    auth: process.env.REACT_APP_NAMESPACE === 'production' ? 'GT6lZVIAd9IOK2q8ZIQuew' : 'obaOQkz3OUirC5jUmmV8yA',
    preview: process.env.REACT_APP_NAMESPACE === 'production' ? 'env-1' : 'env-133'
}

export const initSuivi = () => {
    TagManager.initialize(tagManagerArgs)

    // Sentry.init({
    //     dsn: process.env.REACT_APP_SENTRY_API_KEY,
    //     release: 'coursesapp@' + process.env.REACT_APP_VERSION,
    // })
}
