import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'

/**
 * handle a search from the Header search bar...
 */

export const runProductSearch = userSearch => {
    return (_dispatch, getState) => {
        if (userSearch.length < 3) {
            return []
        }

        const {
            search: { items: search },
        } = getState()
        const re = new RegExp(userSearch, 'ig')

        const results = search.filter(i => re.test(i.name))

        return results.slice(0, 10).sort(sortByProps(['type', 'name']))
    }
}
