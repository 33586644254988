import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'

import {
    updateCartItem,
    setOrderContext,
    openZoom,
    closeZoom,
    setSideMenuVisibility,
} from '../../../../actions'

import ProductZoom from '../ProductZoom'

import { PersistentContextBar } from '../PersistentContextBar'
import { PersistentCheckoutBar } from '../PersistentCheckoutBar'
import { mapStateToProps } from './helpers'
import { ProducerListItem } from './ProducerListItem'

import Styles from './styles.module.css'

export class ProducerList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            familyScrollIndex: false,
            changeLocale: false,
            open: false,
        }

        this.onChangeValue = this.onChangeValue.bind(this)
        this.onPassNewSection = this.onPassNewSection.bind(this)
        this.onOpenZoom = this.onOpenZoom.bind(this)
        this.onCloseZoom = this.onCloseZoom.bind(this)
    }

    componentDidMount() {
        const node = document.getElementById(`key-${this.props.startScroll}`)
        node && node.scrollIntoView({ block: 'start', behavior: 'instant' })
        window.scrollBy(0, -95)
    }

    onChangeValue({ productId, quantity, lastAction }) {
        this.props.updateCartItem({ productId, quantity, lastAction })

        if (this.props.zoomProduct._id === productId) {
            const product = {
                ...this.props.zoomProduct,
                quantity,
            }
            this.props.openZoom({ product })
        }
    }

    onPassNewSection(
        { category = '', family = '' },
        e,
        { calculations = {} } = {}
    ) {
        if (calculations && calculations.passing) {
            this.props.setOrderContext({ family, category, undefined })
        }
    }

    onOpenZoom({ product }) {
        this.props.openZoom({ product })
    }

    onCloseZoom() {
        this.props.closeZoom()
    }

    render() {
        const {
            productTree,
            hasCartItems,
            cart,
            isFull,
            zoomProduct,
            currentGlobalOrder,
            setSideMenuVisibility,
            sideMenuVisible,
            currentLocale,
            currentDate,
        } = this.props

        return (
            <div className="singlePageProducts">
                <ProductZoom
                    open={zoomProduct ? true : false}
                    closeCallback={this.onCloseZoom}
                    cart={cart}
                    product={zoomProduct}
                    onChangeValue={this.onChangeValue}
                    isFull={isFull}
                />

                <PersistentContextBar
                    currentGlobalOrder={currentGlobalOrder}
                    currentLocale={currentLocale}
                    currentDate={currentDate}
                    setSideMenuVisibility={setSideMenuVisibility}
                    sideMenuVisible={sideMenuVisible}
                />

                <Grid className={Styles.contentRight}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <br />
                            {productTree.map(producer => (
                                <ProducerListItem
                                    producer={producer}
                                    onChangeValue={this.onChangeValue}
                                    onOpenZoom={this.onOpenZoom}
                                    onPassNewSection={this.onPassNewSection}
                                />
                            ))}
                            <br />
                        </Grid.Column>
                    </Grid.Row>

                    <PersistentCheckoutBar hasCartItems={hasCartItems} />
                </Grid>

                <div
                    style={{ height: '30px' }}
                    id="#tracker"
                />
            </div>
        )
    }
}

ProducerList.propTypes = {
    cart: PropTypes.array,
    closeZoom: PropTypes.func,
    currentDate: PropTypes.instanceOf(Date),
    currentGlobalOrder: PropTypes.object,
    currentLocale: PropTypes.object,
    hasCartItems: PropTypes.bool,
    isFull: PropTypes.bool,
    openZoom: PropTypes.func,
    productTree: PropTypes.array,
    setOrderContext: PropTypes.func,
    startScroll: PropTypes.string,
    setSideMenuVisibility: PropTypes.func.isRequired,
    sideMenuVisible: PropTypes.bool,
    updateCartItem: PropTypes.func,
    zoomProduct: PropTypes.object,
}

const mapDispatchToProps = dispatch => {
    return {
        updateCartItem: params => dispatch(updateCartItem(params)),
        openZoom: params => dispatch(openZoom(params)),
        setOrderContext: params => dispatch(setOrderContext(params)),
        closeZoom: () => dispatch(closeZoom()),
        setSideMenuVisibility: ({ visible }) =>
            dispatch(setSideMenuVisibility({ visible })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProducerList)
