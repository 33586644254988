import { wrapClientApiCall } from '../../../utils'

export const fetchConsumerFeedbackDetail = feedback_id =>
    wrapClientApiCall({
        actionRoot: 'FETCH_CONSUMER_FEEDBACK_DETAIL',
        url: `api/producerproductfeedback/fetch-detail?feedback_id=${feedback_id}`,
        successPayload: data => ({
            feedback: data.feedback,
        }),
    })
