import * as Types from '../../types'
import history from '../../../history'

export const cancelSwitchTo = () => {
    return (dispatch, getState) => {
        dispatch({
            type: Types.SWITCH_TO_CANCEL_INIT,
        })

        history.push('/commande?a=canceled')
        window.location.reload()
        window.scrollTo(0, 0)

        dispatch({
            type: Types.SWITCH_TO_CANCEL_COMPLETE,
        })
    }
}
