export const isDegenerateCase = ({ order, currentGlobalOrder }) => {
    // ignore if this function is not being called by scheduleSyncOrder
    if (!order.nextSyncTime) {
        return true
    }

    if (new Date(order.nextSyncTime) > new Date()) {
        /*
      *** another timeout will take care of the sync ! ***
      there has been another cart update after the one that set this sync in motion
      forget this one and wait now for that one
    */
        return true
    }

    if (order.orderSyncInProgress) {
        return true
    }

    if (!currentGlobalOrder || !currentGlobalOrder._id) {
        return true
    }

    return false
}
