export const getColumnCount = ({
    quickFilterParams,
    searchFilterParams,
    daterangeFilterParams,
}) => {
    let count = 0
    if (searchFilterParams) count++
    if (quickFilterParams || daterangeFilterParams) count++
    return count
}
