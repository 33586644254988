import { Icon, Menu } from 'semantic-ui-react'
import { throttle } from 'lodash';
import Responsive from '../../ui/Responsive'
import { Component, Fragment } from 'react'
import {
    logout,
    runProductSearch,
    selectGlobalOrder,
    selectLocale,
    setOrderContext,
    switchToInit,
    updateCartItem,
} from '../../../actions'
import { useState, useEffect } from 'react'

import CartPopup from './CartPopup'
import { HeaderLogoDisplay } from './HeaderLogoDisplay'
import { HeaderSearchProductModal } from './HeaderSearchProductModal'
import HeaderSelectAnotherStore from './HeaderSelectAnotherStore'
import HeaderSelectUserAccount from './HeaderSelectUserAccount'
import { Link } from 'react-router-dom'
import Styles from './styles.module.css'
import { connect } from 'react-redux'
import { handleSelectSchedule } from '../../pages/ChooseSchedule/handleSelectSchedule'
import { mapStateToProps } from './mapStateToProps'
import { withRouter } from 'react-router'
import LogoutConfirm from './HeaderSelectUserAccount/LogoutConfirm'
import currentUser from '../../../reducers/currentUser'
import livdomZipcodes from '../../../reducers/livdomZipcodes';

export class PageHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: undefined,
            logoutModalOpen: false,
            error: false,
            schedule: {},
            lastScrollY: 0,
            transformY: 0,
        }

        this.triggerModal = this.triggerModal.bind(this)
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
        this.confirmLogoutClick = this.confirmLogoutClick.bind(this)
        this.setLogoutModal = this.setLogoutModal.bind(this)
        this.handleSearchSelect = this.handleSearchSelect.bind(this)
        this.handleSelectSchedule = handleSelectSchedule.bind(this)
        this.handleLocaleClick = this.handleLocaleClick.bind(this)
        this.selectLocale = selectLocale.bind(this)
        this.updateTransformY = this.updateTransformY.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.updateTransformY)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.updateTransformY)
    }

    updateTransformY() {
        const scrollY = window.scrollY;

        this.setState((state) => {
            return {
                ...state,
                lastScrollY: scrollY > 0 ? scrollY : 0,
                transformY: Math.min(0, Math.max(-106, this.state.transformY - (scrollY - this.state.lastScrollY)))
            }
        })

    };

    handleLogoutClick() {
        this.setState({
            modalOpen: undefined,
            error: false,
        })

        if (this.props.cart.list.length > 0) {
            this.setState({
                logoutModalOpen: true,
            })
        }
        else {
            this.confirmLogoutClick()
        }
    }

    setLogoutModal(toggle) {
        this.setState({
            logoutModalOpen: toggle
        })
    }

    confirmLogoutClick() {
        this.props.logout(this.props.persistor)
        this.setState({
            logoutModalOpen: false,
        })

        if (['/choisir-un-magasin', '/commande'].findIndex(e => this.props.location.pathname.includes(e)) < 0) {
            this.props.history.push('/choisir-un-magasin')
        }

        setTimeout(() => {
            if (!currentUser.loggedin) {
                window.location.reload()
            }
        }, 250);
    }

    triggerModal(type) {
        this.setState({
            modalOpen: this.state.modalOpen ? undefined : type,
            error: false,
        })
    }

    handleSearchSelect(data) {
        this.setState({
            modalOpen: undefined,
            error: false,
        })
        this.props.eventEmitter.emit('search-select', data)
    }

    handleLocaleClick(locale) {
        this.props.eventEmitter.emit('boutique-click', locale)
    }

    handleBurgerClick = () => {
        this.setState(prevState => ({
            showBurgerMenu: !prevState.showBurgerMenu,
        }))
    }

    render() {
        const {
            currentUser,
            livdomZipcodes,
            currentLocale,
            currentGlobalOrder,
            showSearch,
            runSearch,
            hideCurrentLocale,
            hideCart,
            updateCartItem,
            cart,
            cartUpdateOpen,
            activeGlobalOrdersList,
            locales,
            nextGlobalOrdersList,
        } = this.props
        const { showBurgerMenu } = this.state

        return (
            <Fragment>
                <Responsive maxWidth={500}>
                    <div className={Styles.VerticalSpacer}></div>
                    <Menu
                        fixed="top"
                        className={Styles.HeaderMenu}
                    >
                        <div style={{ display: 'flex', flexGrow: 1, width: '38%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <div
                                onClick={this.handleBurgerClick}
                                className={Styles.Account}
                            >
                                <Icon
                                    name="bars"
                                    size="big"
                                    style={{ color: 'white' }}
                                    className={Styles.headerIcons}
                                />
                            </div>

                            {showBurgerMenu && (
                                <Menu className={Styles.BurgerMenu}>
                                    {this.props.location.pathname !==
                                        '/choisir-un-magasin' &&
                                        currentGlobalOrder && (
                                            <Menu.Item
                                                className={Styles.headerTitle}
                                                onClick={() => this.handleBurgerClick()}
                                            >
                                                <Link style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} to={'/choisir-un-magasin'}>
                                                    <Icon
                                                        name="reply"
                                                        size="large"
                                                        style={{ color: 'white' }}
                                                        className={Styles.headerIcons}
                                                    />
                                                    <h1>Retourner aux boutiques</h1>
                                                </Link>
                                            </Menu.Item>
                                        )}

                                    {showSearch && currentGlobalOrder && (
                                        <Menu.Item
                                            className={Styles.headerTitle}
                                            onClick={() => {
                                                this.handleBurgerClick()
                                                this.triggerModal('search')
                                            }
                                            }
                                            title="search-modal-trigger"
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Icon
                                                    name="search"
                                                    size="large"
                                                    style={{ color: 'white' }}
                                                    className={Styles.headerIcons}
                                                />
                                                <h1>Rechercher un produit</h1>
                                            </div>
                                        </Menu.Item>
                                    )}

                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Accueil</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/lieux-de-retrait'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Lieux de Retrait</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/les-producteurs-et-productrices-br-espagne-hollande-italie-non-picardie'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Les producteurs et productrices</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/blogs/news'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Actus & recettes</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/contact'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Contact</h1>
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            )}

                            {!hideCurrentLocale && currentLocale && (
                                <HeaderSelectAnotherStore
                                    currentLocale={currentLocale}
                                    handleSelect={this.handleSelectSchedule}
                                    locales={locales}
                                    activeGlobalOrdersList={activeGlobalOrdersList}
                                    nextGlobalOrdersList={nextGlobalOrdersList}
                                />
                            )}
                        </div>

                        <div style={{ display: 'flex', flexGrow: 1, width: '24%', alignItems: 'center', justifyContent: 'center' }}>
                            <HeaderLogoDisplay />
                        </div>

                        <div style={{ display: 'flex', flexGrow: 1, width: '38%', alignItems: 'center', justifyContent: 'flex-end', margin: '0 10px 0 5px!important' }}>

                            {currentUser.loggedin ?
                                <HeaderSelectUserAccount
                                    currentUser={currentUser}
                                    error={this.state.error}
                                    handleLogoutClick={this.handleLogoutClick}
                                />
                                :
                                <div
                                    className={Styles.Account}
                                    title="signin-trigger"
                                >
                                    <Link
                                        to={
                                            '/login?goto=' +
                                            (this.props &&
                                                this.props.location &&
                                                this.props.location.pathname)
                                        }
                                        aria-label="S'inscrire / Se connecter"
                                    >
                                        <Icon
                                            name="sign-in"
                                            size="big"
                                            style={{ color: '#F2AB00' }}
                                            className={Styles.headerIcons}
                                        />
                                    </Link>
                                </div>
                            }

                            {!hideCart && currentGlobalOrder && currentLocale && (
                                <div
                                    className={Styles.Cart}
                                    title="cart-trigger"
                                >
                                    <CartPopup
                                        currentUser={currentUser}
                                        livdomZipcodes={livdomZipcodes}
                                        updateCartItem={updateCartItem}
                                        cart={cart}
                                        currentLocale={currentLocale}
                                        cartUpdateOpen={cartUpdateOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </Menu>
                </Responsive>

                <Responsive minWidth={501} maxWidth={1024}>
                    <div className={Styles.VerticalSpacer}></div>
                    <Menu
                        fixed="top"
                        className={Styles.HeaderMenu}
                    >
                        <div style={{ display: 'flex', flexGrow: 1, width: '38%', alignItems: 'center', justifyContent: 'start' }}>
                            <div
                                onClick={this.handleBurgerClick}
                                className={Styles.Account}
                            >
                                <Icon
                                    name="bars"
                                    size="large"
                                    style={{ color: 'white' }}
                                    className={Styles.headerIcons}
                                />
                            </div>

                            {showBurgerMenu && (
                                <Menu className={Styles.BurgerMenu}>
                                    {this.props.location.pathname !==
                                        '/choisir-un-magasin' &&
                                        currentGlobalOrder && (
                                            <Menu.Item
                                                className={Styles.headerTitle}
                                                onClick={() => this.handleBurgerClick()}
                                            >
                                                <Link to={'/choisir-un-magasin'}>
                                                    <Icon
                                                        name="reply"
                                                        size="large"
                                                        style={{ color: 'white' }}
                                                        className={Styles.headerIcons}
                                                    />
                                                    <h1>Retourner aux boutiques</h1>
                                                </Link>
                                            </Menu.Item>
                                        )}

                                    {showSearch && currentGlobalOrder && (
                                        <Menu.Item
                                            className={Styles.headerTitle}
                                            onClick={() => {
                                                this.handleBurgerClick()
                                                this.triggerModal('search')
                                            }
                                            }
                                            title="search-modal-trigger"
                                        >
                                            <div>
                                                <Icon
                                                    name="search"
                                                    size="large"
                                                    style={{ color: 'white' }}
                                                    className={Styles.headerIcons}
                                                />
                                                <h1>Rechercher un produit</h1>
                                            </div>
                                        </Menu.Item>
                                    )}

                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Accueil</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/lieux-de-retrait'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Lieux de Retrait</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/les-producteurs-et-productrices-br-espagne-hollande-italie-non-picardie'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Les producteurs et productrices</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/blogs/news'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Actus & recettes</h1>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        className={Styles.headerTitle}
                                    >
                                        <a
                                            href={
                                                'https://www.kelbongoo.com/pages/contact'
                                            }
                                            target="_blank"
                                        >
                                            <h1>Contact</h1>
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            )}

                            {!hideCurrentLocale && currentLocale && (
                                <HeaderSelectAnotherStore
                                    currentLocale={currentLocale}
                                    handleSelect={this.handleSelectSchedule}
                                    locales={locales}
                                    activeGlobalOrdersList={activeGlobalOrdersList}
                                    nextGlobalOrdersList={nextGlobalOrdersList}
                                />
                            )}
                        </div>

                        <div style={{ display: 'flex', flexGrow: 1, width: '24%', alignItems: 'center', justifyContent: 'center' }}>
                            <HeaderLogoDisplay />
                        </div>

                        <div style={{ display: 'flex', flexGrow: 1, width: '38%', alignItems: 'center', justifyContent: 'end' }}>

                            {currentUser.loggedin ?
                                <HeaderSelectUserAccount
                                    currentUser={currentUser}
                                    error={this.state.error}
                                    handleLogoutClick={this.handleLogoutClick}
                                />
                                :
                                <div
                                    className={Styles.Account}
                                    title="signin-trigger"
                                >
                                    <Link
                                        to={
                                            '/login?goto=' +
                                            (this.props &&
                                                this.props.location &&
                                                this.props.location.pathname)
                                        }
                                    >
                                        <Icon
                                            name="user"
                                            size="large"
                                            style={{ color: 'white' }}
                                            className={Styles.headerIcons}
                                        />
                                        <span>Connexion</span>
                                    </Link>
                                </div>
                            }

                            {!hideCart && currentGlobalOrder && currentLocale && (
                                <div
                                    className={Styles.Cart}
                                    title="cart-trigger"
                                >
                                    <CartPopup
                                        livdomZipcodes={livdomZipcodes}
                                        currentUser={currentUser}
                                        currentLocale={currentLocale}
                                        updateCartItem={updateCartItem}
                                        cart={cart}
                                        cartUpdateOpen={cartUpdateOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </Menu>
                </Responsive>

                <Responsive minWidth={1025}>
                    {/* <div className={Styles.VerticalSpacer}></div> */}
                    <Menu
                        fixed="top"
                        className={Styles.HeaderMenu}
                        style={{ transform: `translateY(${this.state.transformY}px)` }}
                    >
                        <HeaderLogoDisplay transformY={this.state.transformY} />

                        <div className={Styles.headerContainer}>
                            <Menu.Item
                                className={Styles.headerTitle}
                            >
                                <a
                                    href={
                                        'https://www.kelbongoo.com/'
                                    }
                                    className={Styles.subListItem}
                                    target="_blank"
                                >
                                    <div className={Styles.hoverUnderlineAnimation}>
                                        <h1>Accueil</h1>
                                    </div>
                                </a>
                            </Menu.Item>
                            <Menu.Item
                                className={Styles.headerTitle}
                            >
                                <a
                                    href={
                                        'https://www.kelbongoo.com/pages/lieux-de-retrait'
                                    }
                                    className={Styles.subListItem}
                                    target="_blank"
                                >
                                    <div className={Styles.hoverUnderlineAnimation}>
                                        <h1>Lieux de Retrait</h1>
                                    </div>
                                </a>
                            </Menu.Item>
                            <Menu.Item
                                className={Styles.headerTitle}
                            >
                                <a
                                    href={
                                        'https://www.kelbongoo.com/pages/les-producteurs-et-productrices-br-espagne-hollande-italie-non-picardie'
                                    }
                                    className={Styles.subListItem}
                                    target="_blank"
                                >
                                    <div className={Styles.hoverUnderlineAnimation}>
                                        <h1>Les producteurs et productrices</h1>
                                    </div>
                                </a>
                            </Menu.Item>
                            <Menu.Item
                                className={Styles.headerTitle}
                            >
                                <a
                                    href={
                                        'https://www.kelbongoo.com/blogs/news'
                                    }
                                    className={Styles.subListItem}
                                    target="_blank"
                                >
                                    <div className={Styles.hoverUnderlineAnimation}>
                                        <h1>Actus & recettes</h1>
                                    </div>
                                </a>
                            </Menu.Item>
                            <Menu.Item
                                className={Styles.headerTitle}
                            >
                                <a
                                    href={
                                        'https://www.kelbongoo.com/pages/contact'
                                    }
                                    className={Styles.subListItem}
                                    target="_blank"
                                >
                                    <div className={Styles.hoverUnderlineAnimation}>
                                        <h1>Contact</h1>
                                    </div>
                                </a>
                            </Menu.Item>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flex: 1, }}>

                            {showSearch && currentGlobalOrder && (
                                <div
                                    onClick={() => this.triggerModal('search')}
                                    title="search-modal-trigger"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Icon
                                        name="search"
                                        size="large"
                                        style={{ color: 'white', margin: '0 10px' }}
                                    />
                                </div>
                            )}

                            {!hideCurrentLocale && currentLocale && (
                                <HeaderSelectAnotherStore
                                    currentLocale={currentLocale}
                                    handleSelect={this.handleSelectSchedule}
                                    locales={locales}
                                    activeGlobalOrdersList={activeGlobalOrdersList}
                                    nextGlobalOrdersList={nextGlobalOrdersList}
                                    eventEmitter={this.props.eventEmitter}
                                />
                            )}

                            {currentUser.loggedin ? (
                                <HeaderSelectUserAccount
                                    currentUser={currentUser}
                                    error={this.state.error}
                                    handleLogoutClick={this.handleLogoutClick}
                                />
                            ) : (
                                <div className={Styles.signIn}>
                                    <Link
                                        to={
                                            '/login'
                                        }
                                    >
                                        <span>S'inscrire / Se connecter </span>
                                    </Link>
                                </div>
                            )}

                            {!hideCart && currentGlobalOrder && currentLocale && (
                                <div
                                    className={Styles.Cart}
                                    style={this.props.location.pathname.includes('commande') ? { transform: `translateY(${Math.min(90, -this.state.transformY)}px)` } : {}}
                                    title="cart-trigger"
                                >
                                    <CartPopup
                                        currentUser={currentUser}
                                        livdomZipcodes={livdomZipcodes}
                                        currentLocale={currentLocale}
                                        transformY={this.state.transformY}
                                        updateCartItem={updateCartItem}
                                        cart={cart}
                                        cartUpdateOpen={cartUpdateOpen}
                                    />
                                </div>
                            )}

                        </div>

                    </Menu>
                </Responsive>
                {
                    showSearch && (
                        <HeaderSearchProductModal
                            searchProductModalOpen={
                                this.state.modalOpen === 'search'
                            }
                            closeSearchProductModal={() =>
                                this.triggerModal('search')
                            }
                            runSearch={runSearch}
                            onSelect={this.handleSearchSelect}
                        />
                    )
                }

                <LogoutConfirm logoutModalOpen={this.state.logoutModalOpen} setLogoutModal={this.setLogoutModal} confirmLogoutClick={this.confirmLogoutClick} />
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    runSearch: search => dispatch(runProductSearch(search)),
    onSelect: data => dispatch(setOrderContext(data)),
    updateCartItem: params => dispatch(updateCartItem(params)),
    selectLocale: params => dispatch(selectLocale(params)),
    selectGlobalOrder: params => dispatch(selectGlobalOrder(params)),
    switchToInit: params => dispatch(switchToInit(params)),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PageHeader)
)