import React, { useState, useEffect } from 'react';
import NoPhoto from "../../../assets/logo_no_photo.png"
import Styles from './styles.module.css'

export default function BackgroundImage(props) {
  const [bgImg, setBgImg] = useState(props.src);

  useEffect(() => {
    const image = new Image();
    image.src = props.src;

    image.onload = () => setBgImg(props.src);
    image.onerror = () => setBgImg(NoPhoto);
  }, [props.src]);

  return (
    <div className={Styles.image} style={{ backgroundImage: `url(${bgImg})` }}>
    </div>
  );
}
