import React from 'react'
import { Link } from 'react-router-dom'
import { Step } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"
import { StepGroupXSmall } from './StepGroupXSmall'
import { StepGroupSmall } from './StepGroupSmall'

import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export const CheckoutSteps = () => (
    <div className={Styles.Container}>
        <Responsive maxWidth={MAX_WIDTH.XS}>
            <StepGroupXSmall />
        </Responsive>
        <Responsive
            minWidth={MIN_WIDTH.SM}
            maxWidth={MAX_WIDTH.SM}
        >
            <StepGroupSmall />
        </Responsive>
        <Responsive minWidth={MIN_WIDTH.MD}>
            <Step.Group ordered>
                <Step completed>
                    <Step.Content>
                        <Step.Title>
                            <Link to="/checkout">Confirmation</Link>
                        </Step.Title>
                        <Step.Description>
                            Valider vos produits / retrait
                        </Step.Description>
                    </Step.Content>
                </Step>
                <Step active>
                    <Step.Content>
                        <Step.Title>Règlement</Step.Title>
                        <Step.Description>
                            Choisir votre mode de règlement
                        </Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        </Responsive>
    </div>
)
