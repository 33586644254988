import React from 'react'
import { Image, Button, Table } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import { addImgPrefix } from '../../../../utils'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../app-constants'
import Styles from './styles.module.css'

export const CartListItem = ({ item, bumpUp, bumpDown }) => (
    <Table.Row
        key={item.producerproduct_id}
        title="cart-list-item"
    >
        <Table.Cell>
            <Button.Group size="tiny">
                <Button
                    icon="minus"
                    primary
                    onClick={() =>
                        bumpDown({
                            productId: item.producerproduct_id,
                            quantity: item.quantity,
                        })
                    }
                    title="less"
                />

                <Button.Or text={item.quantity} />

                {item.quantity >= item.available || item.quantity >= 8 ? (
                    <Button
                        icon="plus"
                        primary
                        disabled
                    />
                ) : (
                    <Button
                        icon="plus"
                        primary
                        onClick={() =>
                            bumpUp({
                                productId: item.producerproduct_id,
                                quantity: item.quantity,
                            })
                        }
                        title="more"
                    />
                )}
            </Button.Group>
        </Table.Cell>

        <Table.Cell title={[item.product_name, item.unit_display].join(' - ')}>
            <div className={Styles.Products}>
                {item.src ? (
                    <Responsive
                        minWidth={MIN_WIDTH.MD}
                        style={{ float: 'left' }}
                    >
                        <Image
                            src={addImgPrefix(item.src, 'thumbnail')}
                            alt={item.product_name}
                            className={Styles.ImgProduct}
                        />
                    </Responsive>
                ) : null}

                <div
                    className={Styles.DetailProduct}
                    title="product-info"
                >
                    <div className={Styles.ProductName}>
                        {item.product_name}
                    </div>
                    <div className={Styles.ProducerName}>
                        {item.producer_name}
                    </div>
                    <Responsive maxWidth={MAX_WIDTH.SM}>
                        {formatMoney.run(item.consumer_price, true)} /{' '}
                        {item.unit_display}
                    </Responsive>
                </div>
            </div>
        </Table.Cell>

        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
            textAlign="center"
            singleLine
            title="product-price"
        >
            {formatMoney.run(item.consumer_price, true)}
        </Responsive>

        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
            textAlign="center"
            singleLine
            title="product-unit"
        >
            {item.unit_display}
        </Responsive>

        <Table.Cell
            textAlign="center"
            singleLine
            title="product-total"
        >
            <strong>
                {formatMoney.run(item.quantity * item.consumer_price, true)}
            </strong>
        </Table.Cell>
    </Table.Row>
)
