import { Cart, GlobalOrder } from '../../../models'

export const mapStateToProps = ({
    cart: { list, items: quantities },
    order,
    products,
    available: { products: available },
    currentUser,
    livdomZipcodes,
    currentLocale,
    currentGlobalOrder,
    initRequest,
}) => {
    const cart = new Cart({
        list,
        quantities,
        products,
        available,
        validateConsumer: true,
        isLocalePrivate: currentLocale && !!currentLocale.private,
        isLocaleHomeDelivery: currentLocale && currentLocale.code === 'DOM',
        currentLocale,
    })

    return {
        currentUser,
        livdomZipcodes,
        order,
        cartItems: cart.items,
        addedCharges: cart.added_charges || [],
        currentCartValue: cart.getTotalTTC(),
        currentGlobalOrder: new GlobalOrder(currentGlobalOrder),
        ready: !!initRequest.ready,
        currentLocale,
        initFail: !!initRequest.error,
    }
}
