import * as Types from '../../types'

export const successCallback = async ({ dispatch, data = {} }) => {
    if (data.order_id) {
        dispatch({
            type: Types.EMPTY_CART,
        })

        dispatch({
            type: Types.CONFIRM_ORDER_SUCCESS,
            order_id: data.order_id,
        })

        return data;
    }

    return false;
}
