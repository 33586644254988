import 'whatwg-fetch'
import * as Types from '../../types'
import { API_ADDRESS } from '../../../app-constants.js'

import { fetchLocales } from '../fetchLocales'
import { fetchAvailable } from '../fetchAvailable'
import { handleSuccess } from './handleSuccess'

export const fetchInit = date => {
    return (dispatch, getState) => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        let { currentLocale, currentGlobalOrder, currentUser } = getState()

        const currentDate = date ? new Date(date) : new Date()

        if (
            !currentLocale ||
            !currentLocale.code ||
            !currentGlobalOrder ||
            !currentGlobalOrder._id
        ) {
            return dispatch({
                type: Types.FETCH_INIT_FAIL,
                error: new Error(
                    'currentLocale and currentGlobalOrder required'
                ),
            })
        }

        let localeCode = currentLocale.code
        let globalorder_id = currentGlobalOrder._id

        dispatch({
            type: Types.FETCH_INIT_REQUEST,
            startDate: currentDate,
        })

        return fetch(
            `${API_ADDRESS}/init?locale=${localeCode}&globalorder_id=${globalorder_id}`
        )
            .then(response => {
                dispatch({
                    type: Types.FETCH_INIT_SUCCESS,
                    lastUpdate: currentDate,
                })
                return response.json()
            })
            .then(data => {
                return dispatch(fetchLocales()).then(report => data)
            })
            .then(data => {
                return dispatch(
                    fetchAvailable({
                        currentGlobalOrder,
                        currentDate: date,
                    })
                ).then(available => {
                    return {
                        ...data,
                        quantities: available,
                    }
                })
            })
            .then(
                handleSuccess({
                    dispatch,
                    currentUser,
                    currentLocale,
                    currentGlobalOrder,
                })
            )
            .catch(error => {
                dispatch({
                    type: Types.FETCH_INIT_FAIL,
                    error,
                })
            })
    }
}
