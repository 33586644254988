import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

import { ConfirmButton } from './ConfirmButton'

export class BottomView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    onRemove() {
        this.setState({
            loading: true,
        })
        return this.props.onRemove().finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        let { allowRemove } = this.props
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={allowRemove ? 8 : 16}>
                        {this.props.children}
                    </Grid.Column>

                    {allowRemove && (
                        <Grid.Column width={8}>
                            <ConfirmButton
                                size="small"
                                floated="right"
                                basic
                                color="orange"
                                onConfirm={() => this.onRemove()}
                                content="Supprimer la commande"
                                icon="remove"
                                loading={this.state.loading}
                                modalMessage="Êtes-vous sûr.e de vouloir supprimer cette commande ?"
                            />
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        )
    }
}

BottomView.propTypes = {
    onRemove: PropTypes.func,
    allowRemove: PropTypes.bool,
}
