export const buildProductTreeProductsFilter =
    ({ availableProducts, closed_tags }) =>
    pp => {
        if (!availableProducts) {
            return true
        }

        if (pp.show_soldout) {
            return true
        }

        return (
            availableProducts[pp.producerproduct_id] > 0 &&
            (!pp.tags ||
                pp.tags.length === 0 ||
                !closed_tags.includes(pp.tags[0]))
        )
    }
