import * as Types from '../actions/types'

const search = (state = { items: [] }, action = { type: 'not_set' }) => {
    let items = state.items || []

    switch (action.type) {
        case Types.SEARCH_PRODUCTS_UPDATE:
            items = [
                ...items.filter(i => i.type === 'family'),
                ...action.products,
            ]

            return {
                items,
            }

        case Types.SEARCH_FAMILIES_UPDATE:
            items = [
                ...action.families,
                ...items.filter(i => i.type === 'product'),
            ]
            return {
                items,
            }

        case Types.RESET_INIT:
            return {}

        default:
            return state
    }
}

export default search
