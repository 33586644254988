import { wrapClientApiCall } from '../../../utils'
import * as Types from '../../../actions/types'


const failureCallback = ({ response, dispatch }) => {
    dispatch({
        type: Types.SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_FAIL,
    })

    return response.status
}

export const sendUpdateConsumerEmailConfirmation = ({ email }) =>
    wrapClientApiCall({
        actionRoot: 'SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION',
        url: `api/consumer/send-update-email-confirmation?email=${email}`,
        method: 'POST',
        failureCallback,
    })
