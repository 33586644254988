import React from 'react'
import { Redirect } from 'react-router-dom'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import { OrderLoadingView } from './OrderLoadingView'
import { NoCurrentGlobalOrder } from './NoCurrentGlobalOrder'

export const handleUnknownGlobalOrder = function () {
    const locale = this.props.locale

    if (!locale) {
        return <Redirect to="/choisir-un-magasin" />
    }

    if (!this.props.localesInitialized) {
        return <OrderLoadingView />
    }

    const activeGlobalOrders = (this.props.activeGlobalOrders || []).filter(g =>
        g.locales.includes(locale.code)
    )

    if (activeGlobalOrders.length === 0) {
        const nextGlobalOrder = this.props.nextGlobalOrders.find(g =>
            g.locales.includes(locale.code)
        )

        const nextGlobalOrderStart =
            nextGlobalOrder &&
            nextGlobalOrder.order_start &&
            formatDate.run(
                new Date(nextGlobalOrder.order_start.replace(/-/g, '/')),
                'dddd DD MMMM HH:mm'
            )
        const nextGlobalOrderDistrib =
            nextGlobalOrder &&
            nextGlobalOrder.distribution_date &&
            formatDate.run(
                new Date(nextGlobalOrder.distribution_date),
                'dddd DD MMMM'
            )

        return (
            <NoCurrentGlobalOrder
                prochaineVenteDistrib={nextGlobalOrderDistrib}
                prochaineVenteStart={nextGlobalOrderStart}
                currentUser={this.props.currentUser}
            />
        )
    }

    if (activeGlobalOrders.length > 1) {
        return <Redirect to="/choisir-un-magasin" />
    }

    return <OrderLoadingView />
}
