import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const buttonStyle = {
    width: '18em',
    textAlign: 'center',
    margin: '0.2em 0px',
    background: 'none',
    border: '1px solid #d3d3d3',
    padding: '0.5em 1.5em',
}

export const InfoListDropdown = ({ buttonText, children }) => (
    <Dropdown
        text={buttonText}
        button
        style={buttonStyle}
    >
        <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
)
