import { MIN_WIDTH } from '../../../../../../app-constants'
import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import GenericTable from '../../../../../ui/GenericTable'
import { OrderItemRow } from './OrderItemRow'

export const OrderList = ({
    items,
    reduced,
    removed,
    addedCharges,
    creditUsed,
    onEditItem,
    allowEditItem,
}) => {
    let formattedItems = [
        ...items,
        ...reduced.map(i => Object.assign(i, { type: 'reduced' })),
        ...removed.map(i => Object.assign(i, { type: 'removed' })),
        ...addedCharges.map(i => Object.assign(i, { type: 'added-charge' })),
        ...(creditUsed ? [{ type: 'credit', amount: creditUsed }] : []),
    ].map(item =>
        Object.assign(item, {
            onEditItem: onEditItem(item),
            allowEditItem,
        })
    )

    let quantityCell = (
        <div>
            <div style={{ marginRight: '3px', display: 'inline' }}>
                Quantit&eacute;
            </div>
            {allowEditItem && (
                <Popup
                    content="Cliquer sur la quantité commandée pour éditer"
                    trigger={
                        <Icon
                            name="info circle"
                            size="small"
                            color="blue"
                        />
                    }
                />
            )}
        </div>
    )

    return (
        <GenericTable
            items={formattedItems}
            //hidden are minWidth
            header={{
                columns: [
                    { name: 'product_name', title: 'Produit' },
                    {
                        name: 'unit_display',
                        title: 'Unité',
                        hidden: MIN_WIDTH.MD,
                    },
                    {
                        name: 'consumer_price',
                        title: 'Prix',
                        width: 100,
                        align: 'center',
                        hidden: MIN_WIDTH.SM,
                    },
                    {
                        name: 'quantity',
                        Component: quantityCell,
                        width: 100,
                        align: 'center',
                    },
                    {
                        name: 'value',
                        title: 'Valeur',
                        width: 100,
                        align: 'center',
                    },
                ],
            }}
            RowComponent={OrderItemRow}
        />
    )
}
