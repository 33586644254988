/* istanbul ignore file */

import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

export class ProducerProductFeedback {
    constructor(data) {
        Object.assign(this, data)
    }

    getDistributionDate(format) {
        if (!this.createdAt) {
            return
        }
        return formatDate.run(this.createdAt, 'ddd DD MMM YYYY')
    }

    getConsumerPrice() {
        return formatMoney.run(this.consumer_price)
    }

    hasFeedback() {
        return !!this.validatedAt
    }

    hasProducerReply() {
        return !!this.producerRepliedAt
    }
}
