import React from 'react'
import { Button, Header, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { addImgPrefix } from '../../../../utils'

import Styles from './styles.module.css'

const AllLocalesFullView = ({
    currentUser,
    prochaineVenteStartDays,
    prochaineVenteStartHours,
}) => {
    const showNextSaleHours =
        prochaineVenteStartDays && prochaineVenteStartHours
    const showSignupButton = !currentUser || !currentUser.loggedin

    return (
        <div
            className={Styles.Container}
            data-testid="all-locales-full-container"
        >
            <div>
                <div className={Styles.ImageContainer}>
                    <Image
                        alt="Logo groupe"
                        src={addImgPrefix('group1.svg')}
                    />
                </div>

                <Header as="h2">
                    La vente est complète !
                    <Header.Subheader style={{ marginTop: '25px' }}>
                        L'espace disponible pour la distribution des commandes
                        est épuisé dans toutes nos boutiques.
                    </Header.Subheader>
                </Header>

                {showNextSaleHours && (
                    <div className={Styles.NextSaleHours}>
                        La prochaine vente ouvre le&nbsp;
                        <b>
                            {prochaineVenteStartDays} à{' '}
                            {prochaineVenteStartHours}h
                        </b>
                    </div>
                )}

                {showSignupButton && (
                    <div>
                        <p>
                            Tenez-moi informé.e de l'ouverture de la prochaine
                            vente
                        </p>
                        <Link to="/creer-un-compte">
                            <Button
                                style={{ marginTop: '10px' }}
                                primary
                                size="large"
                                fluid
                            >
                                Je veux m'inscrire !
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AllLocalesFullView
