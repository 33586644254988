import React from 'react'
import SelectFilter from './SelectFilter'

const SelectFilters = params => {
    // let GenericContainer = getGenericContainer({ test: 'test' });
    return (
        <div>
            {params.filters.map(filterParams => (
                <SelectFilter {...filterParams} />
            ))}
        </div>
    )
}

export default SelectFilters
