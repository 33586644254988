import * as Types from '../../types'
import { forceExpiry } from './successCallback'

export const failureCallback = ({ response, dispatch, getState }) => {
    if (response && (response.status === 404 || response.code === 404)) {
        forceExpiry(dispatch, getState)
    }
    dispatch({
        type: Types.ORDER_SYNC_FAIL,
        error: response.statusText,
    })
}
