import { GlobalOrder } from '../../../../../models'
import { buildProducerData } from './buildProducerData'

export const mapStateToProps = ({
    producers,
    producersList,
    products,
    cart,
    currentLocale,
    available: { products: availableProducts },
    session: { sideMenuVisible = false, zoomProduct = false },
    producersContent,
    currentGlobalOrder,
}) => {
    const productTree = producersList
        .map(_id => producers[_id])
        .map(
            buildProducerData({
                producersContent,
                availableProducts,
                cart,
                products,
            })
        )

    const hasCartItems = cart.list.length > 0
    const globalorder = new GlobalOrder(currentGlobalOrder)

    return {
        productTree,
        sideMenuVisible,
        cart,
        zoomProduct,
        hasCartItems,
        producersContent,
        isGlobalOrderFull: globalorder.isFull(),
        currentLocale,
        currentGlobalOrder: globalorder,
    }
}
