import React from 'react'
import { Table } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import { Link } from 'react-router-dom'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export const AccountPageCreditsListItem = data => {
    let {
        data: {
            createdAt,
            product_name,
            producer_name,
            active,
            order_id,
            amount_remaining,
            amount_initial,
        },
    } = data

    return (
        <Table.Row
            disabled={!active}
            data-testid="credits-row"
        >
            <Table.Cell>
                <Responsive maxWidth={MAX_WIDTH.XS}>
                    <Link
                        to={`/account/orders/${order_id}`}
                        style={
                            !active
                                ? {
                                      textDecoration: 'line-through',
                                      opacity: '0.5',
                                  }
                                : {}
                        }
                    >
                        {formatDate.run(createdAt, 'ddd DD MMM YYYY')}
                    </Link>
                </Responsive>
                <Responsive minWidth={MIN_WIDTH.SM}>
                    <Link
                        to={`/account/orders/${order_id}`}
                        style={
                            !active
                                ? {
                                      textDecoration: 'line-through',
                                      opacity: '0.5',
                                  }
                                : {}
                        }
                    >
                        {formatDate.run(createdAt, 'dddd DD MMMM YYYY')}
                    </Link>
                </Responsive>
                <Responsive
                    as="div"
                    maxWidth={MAX_WIDTH.XS}
                >
                    <span
                        style={{
                            textDecoration: !active ? 'line-through' : '',
                        }}
                    >
                        {product_name
                            ? `${product_name} (${producer_name})`
                            : ''}
                    </span>
                </Responsive>
            </Table.Cell>

            <Responsive
                as={Table.Cell}
                minWidth={MIN_WIDTH.SM}
            >
                <span style={{ textDecoration: !active ? 'line-through' : '' }}>
                    {product_name
                        ? `${product_name} (${producer_name})`
                        : 'aucun'}
                </span>
            </Responsive>

            <Table.Cell textAlign="center">
                <span style={{ textDecoration: !active ? 'line-through' : '' }}>
                    {formatMoney.run(amount_initial)}
                </span>
            </Table.Cell>

            <Table.Cell textAlign="center">
                <b>{formatMoney.run(amount_remaining)}</b>
            </Table.Cell>
        </Table.Row>
    )
}
