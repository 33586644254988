import * as Types from '../../types'

export const logout = persistor => {
    return dispatch => {
        if (persistor) {
            return persistor.purge().then(
                () => {
                    dispatch({
                        type: Types.EMPTY_CART,
                    })
                    // dispatch({
                    //     type: Types.UNSET_CURRENT_LOCALE,
                    // })
                    dispatch({
                        type: Types.LOGOUT_SUCCESS,
                    })
                },
                () => {}
            )
        } else {
            dispatch({
                type: Types.EMPTY_CART,
            })
            // dispatch({
            //     type: Types.UNSET_CURRENT_LOCALE,
            // })
            dispatch({
                type: Types.LOGOUT_SUCCESS,
            })
        }
    }
}
