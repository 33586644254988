import * as Types from '../../types'

export const handleCategories = ({
    carts = [],
    familiesByCategory,
    dispatch,
    currentUser,
    categories,
}) => {
    let categoriesDict = {}
    // add promotions to categories

    let headerCategories = [
        {
            _id: 'promotions',
            name: 'Produits du moment',
            index: -1,
        },
    ]

    if (carts.length > 0 && currentUser && currentUser.show_feature_carts) {
        headerCategories[0].index = -2

        headerCategories.push({
            _id: 'carts',
            name: 'Paniers',
            index: -1,
        })
    }

    const allCategories = [...headerCategories, ...categories]

    allCategories.forEach(i => {
        // remove categories with no families present
        if (!familiesByCategory[i._id]) {
            return
        }

        categoriesDict[i._id] = {
            ...i,
            families: familiesByCategory[i._id] || [],
        }
    })

    dispatch({
        type: Types.CATEGORIES_UPDATE,
        dict: categoriesDict,
    })
    dispatch({
        type: Types.CATEGORIES_LIST_UPDATE,
        list: Object.keys(categoriesDict),
    })
}
