import ReactPixel from 'react-facebook-pixel';

export const handleGtm = ({
    type,
    product: { product_name, consumer_price, producerproduct_id },
}) => {
    window.dataLayer.push({
        event: type === 'add' ? `add_to_cart` : type === 'remove' && 'remove_from_cart',
        eventProps: {
            currency: 'EUR',
            value: consumer_price / 100,
            items:
                [{
                    item_name: product_name,
                    item_id: producerproduct_id,
                }],
        },
    })
    if (type === 'add') {
        ReactPixel.track('AddToCart', {
            currency: 'EUR',
            value: consumer_price / 100,
            contents: [{
                item_name: product_name,
                item_id: producerproduct_id,
            }],
        })
    }
}
