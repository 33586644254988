import React from 'react'
import { Grid, Image, Header, Icon } from 'semantic-ui-react'

import { withRouter, Link } from 'react-router-dom'

import { addImgPrefix } from '../../../../utils'

import Styles from './style.module.css'

export const CheckoutOrderFailed = ({ location: { search } }) => {
    let isPartner = !!JSON.parse(
        JSON.parse(localStorage.getItem('persist:root')).currentLocale
    ).partner
    return (
        <Grid>
            <Grid.Row className={Styles.ImageRow}>
                <Grid.Column width={16}>
                    <Image
                        src={addImgPrefix('choufleur-sad.svg')}
                        alt="Logo chou fleur triste"
                        size="small"
                        centered
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <div className={Styles.Upper}>
                        <Header
                            as="h2"
                            textAlign="center"
                        >
                            <Header.Content>
                                Votre paiement a échoué.
                            </Header.Content>
                        </Header>
                    </div>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column
                    width={16}
                    textAlign="center"
                >
                    <Link
                        replace
                        className={Styles.OrderLink}
                        to={`/account/orders/${search.split('order_id=')[1]}`}
                    >
                        <Icon name="circle right arrow" />
                        Tenter à nouveau.
                    </Link>
                    {!isPartner && (
                        <p>
                            Il vous est possible de choisir le paiement sur
                            place.
                        </p>
                    )}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ marginTop: '25px' }}>
                <Grid.Column
                    width={16}
                    textAlign="center"
                >
                    {/* <Message
          className={Styles.Message}
          icon='envelope'
          size='tiny'
          header="Un mail vous a été envoyé"
          content='Il contient toutes les informations sur votre commande.'
        /> */}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withRouter(CheckoutOrderFailed)
