import React, { Component } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export const formatOptions = options => {
    return options.map(option => {
        let icon = option.selected && 'checkmark'
        return {
            icon,
            text: option.title,
            value: option.name,
            selected: option.selected,
            default: option.default,
        }
    })
}

class QuickFilter extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                name: PropTypes.string.isRequired,
                default: PropTypes.bool,
                selected: PropTypes.bool,
            })
        ).isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.defaultOptions = props.options
        this.state = {
            options: props.options,
        }
    }

    onSelect(e, data) {
        const val = data.value
        let options = this.state.options.slice(0)
        options = options.map(option => {
            if (val === option.name) {
                Object.assign(option, { selected: true })
            } else {
                delete option.selected
            }
            return option
        })
        this.setState({ options })
        this.props.onChange(val)
    }

    render() {
        let options = this.state.options
        let selected = options.find(option => option.selected)
        let color = selected && !selected.default ? 'green' : undefined

        return (
            <Button.Group
                color={color}
                fluid
            >
                <Dropdown
                    fluid
                    selection
                    button
                    className="quickfilter"
                    value={selected && selected.name}
                    onChange={this.onSelect.bind(this)}
                    options={formatOptions(options)}
                />
            </Button.Group>
        )
    }
}

export default QuickFilter
