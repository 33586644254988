import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'

import CountDown from '../Countdown'

import Styles from './styles.module.css'

/**
 * subheader underneath global header,
 * shows current category/family and sale closure countdown
 */

export const PersistentContextBar = ({
    category,
    currentDate,
    currentGlobalOrder,
    currentLocale,
    family,
}) => {
    const distributionDate =
        currentLocale &&
        currentLocale.currentSchedule &&
        currentLocale.currentSchedule.day
    const distributionHours =
        currentLocale &&
        currentLocale.currentSchedule &&
        currentLocale.currentSchedule.time
    const defaultHeader = `${currentLocale.name}, le ${distributionDate} (${distributionHours})`
    const backgroundTitle =
        currentGlobalOrder &&
            currentGlobalOrder.background &&
            currentGlobalOrder.title
            ? `${currentGlobalOrder.title} ${distributionHours}`
            : undefined

    return (
        <div
            className={Styles.MainView}
            data-testid="persistent-context-container"
        >
            <div className={Styles.Title}>
                <Header
                    as="h2"
                    color="green"
                    className={Styles.InlineHeader}
                >
                    {window.innerWidth > 500 ?
                        typeof backgroundTitle !== 'undefined'
                            ? backgroundTitle
                            : family && category
                                ? category.name.toUpperCase()
                                : defaultHeader
                        :
                        <MobileHeader
                            currentLocale={currentLocale}
                            distributionDate={distributionDate}
                            distributionHours={distributionHours}
                        />
                    }

                    {currentGlobalOrder && currentGlobalOrder._id ? (
                        <CountDown
                            currentGlobalOrder={currentGlobalOrder}
                            currentDate={currentDate}
                        />
                    ) : null}

                    {window.innerWidth > 500 &&
                        family && category && (
                            <Header.Subheader>{family.name}</Header.Subheader>
                        )}
                </Header>
            </div>
        </div>
    )
}

export const MobileHeader = ({
    currentLocale,
    distributionDate,
    distributionHours
}) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={Styles.headerTile} style={{ backgroundColor: '#55AE68', color: '#075244' }}>{currentLocale.name.length + distributionDate.length + distributionHours.length > 47 ? currentLocale.name.slice(0, 47 - distributionDate.length - distributionHours.length) + '...' : currentLocale.name}</div>
            <div className={Styles.headerTile} style={{ backgroundColor: '#F2AB00', color: 'white' }}>{`${distributionDate} ${distributionHours}`}</div>
        </div>
    )
}

PersistentContextBar.propTypes = {
    category: PropTypes.object,
    currentDate: PropTypes.instanceOf(Date),
    currentGlobalOrder: PropTypes.object,
    currentLocale: PropTypes.object.isRequired,
    family: PropTypes.object,
    setSideMenuVisibility: PropTypes.func.isRequired,
    sideMenuVisible: PropTypes.bool.isRequired,
}
