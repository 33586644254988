import * as Sentry from '@sentry/browser'

export const trackConnected = ({ _id }) => {
    try {
        var _learnq = window._learnq || [] // eslint-disable-line

        _learnq.push([
            'identify',
            {
                $id: _id,
            },
        ])
    } catch (err) {
        Sentry.captureException(
            new Error(
                'Klaviyo trackEvent failed - ' +
                    JSON.stringify({ currentUserId: _id, err })
            )
        )
    }
}
