import { wrapClientApiCall } from '../../../utils'

// NOT secured, since we don't have the token when the page opens

/**
 * this method is called when a link in an email for changing email address is clicked - this page
 * triggers sending a token to the server to confirm the new email address
 * @param {string} changeEmailToken - server token for changing email
 * @returns {function} - dispatchable action function
 */

export const updateConsumerEmail = changeEmailToken =>
    wrapClientApiCall({
        actionRoot: 'UPDATE_CONSUMER_EMAIL',
        url: `consumer/update-email?token=${changeEmailToken}`,
        method: 'POST',
        successPayload: () => ({
            email: changeEmailToken.split('_')[0],
        }),
    })
