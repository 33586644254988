// import { run as isObjectEmpty } from '@kelbongoo/shared-methods/utils/isObjectEmpty';
import * as Types from '../../types'

export const successCallback =
    (currentGlobalOrder, currentDate) =>
    ({ getState, dispatch, data }) => {
        let { globalorder, available, globalorderlocales } = data

        const {currentLocale} = getState()


        const list = Object.keys(available || {})

        dispatch({
            type: Types.QUANTITIES_UPDATE,
            dict: available,
            globalorder,
            globalorderlocales,
            currentLocale,
        })
        dispatch({
            type: Types.QUANTITIES_LIST_UPDATE,
            list,
        })

        dispatch({
            type: Types.FETCH_AVAILABLE_SUCCESS,
        })

        return available
    }
