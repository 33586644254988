/* istanbul ignore file */

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Table } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"
import { CartListItem } from '../../CartListItem'
import { MIN_WIDTH } from '../../../../../app-constants'

export const CartCardDetailModal = ({
    showCartDetailModal,
    closeCartDetailModal,
    bumpUp,
    bumpDown,
    addProducts,
    items,
}) => {
    return (
        <Modal
            closeIcon
            open={showCartDetailModal}
            onClose={closeCartDetailModal}
            dimmer="inverted"
            size="small"
        >
            <Modal.Header>
                Ajouter ces produits &agrave; mon panier
            </Modal.Header>

            <Modal.Content>
                <Table
                    compact="very"
                    basic
                    unstackable
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '90px' }}>
                                Quantit&eacute;
                            </Table.HeaderCell>
                            <Table.HeaderCell>Produit</Table.HeaderCell>
                            <Responsive
                                as={Table.HeaderCell}
                                minWidth={MIN_WIDTH.MD}
                                textAlign="center"
                            >
                                Prix
                            </Responsive>
                            <Responsive
                                as={Table.HeaderCell}
                                minWidth={MIN_WIDTH.MD}
                                textAlign="center"
                            >
                                Unit&eacute;
                            </Responsive>
                            <Table.HeaderCell textAlign="center">
                                Valeur
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items && items.length > 0 ? (
                            items.map(item => (
                                <CartListItem
                                    key={item.producerproduct_id}
                                    item={item}
                                    bumpUp={() =>
                                        bumpUp(item.producerproduct_id)
                                    }
                                    bumpDown={() =>
                                        bumpDown(item.producerproduct_id)
                                    }
                                />
                            ))
                        ) : (
                            <Table.Row key="no-articles">
                                <Table.Cell colSpan="100">
                                    Aucun article dans le panier.
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => closeCartDetailModal()}>Annuler</Button>

                <Button
                    content="Ajouter à mon panier "
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => addProducts()}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

CartCardDetailModal.propTypes = {
    showCartDetailModal: PropTypes.bool,
    closeCartDetailModal: PropTypes.func,
    bumpUp: PropTypes.func,
    bumpDown: PropTypes.func,
    addProducts: PropTypes.func,
    items: PropTypes.array,
}
