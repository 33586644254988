import React, { Component } from 'react'
import GenericTable from '../../GenericTable'
import PropTypes from 'prop-types'
import { Loader, Button, Dimmer } from 'semantic-ui-react'
// import Styles from './styles.module.css'

class ListContainer extends Component {
    static propTypes = {
        params: PropTypes.shape({
            refreshData: PropTypes.func,
            fetchMore: PropTypes.func,
            header: PropTypes.shape({
                columns: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        title: PropTypes.string,
                        height: PropTypes.number,
                        width: PropTypes.string,
                    })
                ),
            }),
            RowComponent: PropTypes.func,
        }),
        hasMore: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            loadingMore: false,
            hasMore: !!props.hasMore,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hasMore !== this.props.hasMore) {
            setTimeout(() => {
                this.setState({
                    hasMore: this.props.hasMore,
                })
            }, 500)
        }
    }

    refreshData() {
        if (
            !this.props.params ||
            typeof this.props.params.refreshData !== 'function'
        ) {
            return
        }
        return this.props.params.refreshData()
    }

    async fetchMore() {
        if (
            !this.props.params ||
            typeof this.props.params.fetchMore !== 'function'
        ) {
            return
        }
        this.setState({
            loadingMore: true,
        })
        this.props.params.fetchMore().finally(() => {
            window.scrollTo(0, document.body.scrollHeight)
            setTimeout(() => {
                this.setState({
                    loadingMore: false,
                })
            }, 2000)
        })
    }

    render() {
        const { RowComponent, header } = this.props.params || {}

        return (
            <Dimmer.Dimmable dimmed={this.props.loading}>
                <Dimmer
                    active={this.props.loading}
                    inverted
                >
                    <Loader size="medium">Chargement</Loader>
                </Dimmer>
                {this.props.params &&
                    typeof this.props.params.refreshData === 'function' && (
                        <Button
                            basic
                            floated="right"
                            icon="refresh"
                            onClick={this.refreshData.bind(this)}
                        />
                    )}
                <GenericTable
                    header={header}
                    items={this.props.items}
                    RowComponent={RowComponent}
                />
                {this.state.hasMore && (
                    <Button
                        basic
                        icon="cloud download"
                        content="Charger plus"
                        fluid
                        loading={this.state.loadingMore}
                        onClick={this.fetchMore.bind(this)}
                    />
                )}
            </Dimmer.Dimmable>
        )
    }
}

export default ListContainer

/*

    <InfiniteScroll
      next={fetchMore}
      pullDownToRefreshThreshold={10}
      pullDownToRefresh={!!refreshData}
      refreshFunction={refreshData}
      pullDownToRefreshContent={<h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>}
      releaseToRefreshContent={<h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>}
      hasMore={hasMore}
      loader={
        <div style={{width: '100%', textAlign:'center', paddingBottom:'10px'}}>
          <Loader active inline size='small' className={Styles.BottomLoader}>
            Chargement
          </Loader>
        </div>
      }
    >
*/
