import { trackEvent } from '../../../utils/trackEvent'

export const handleKlaviyoAdd = ({ currentUser, productId }) => {
    if (currentUser && currentUser._id) {
        trackEvent({
            _id: currentUser._id,
            event: 'Added to Cart',
            data: {
                productId,
            },
        })
    }
}
