import currentGlobalOrder from '../../../reducers/currentGlobalOrder'
import switchTo from '../../../reducers/switchTo'
import * as Types from '../../types'
import history from './../../../history'

export const switchToInit = ({ locale, schedule, cart, globalOrder }) => {
    return (dispatch, getState) => {
        if (!cart) {
            // default to current cart on init
            cart = getState().cart
        }

        if (!globalOrder) {
            globalOrder = getState().currentGlobalOrder
        }

        dispatch({
            type: Types.SWITCH_TO_INIT,
            cart,
            locale,
            schedule,
            globalOrder
        })

        history.push('/verification-du-panier')
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }
}
