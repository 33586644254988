import React from 'react'
import { Table, Icon, Popup } from 'semantic-ui-react'
import  Responsive  from '../../../../../../ui/Responsive'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { ItemsListItemEdit } from '../ItemsListItemEdit'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../../app-constants'

export const OrderItemRow = ({ data }) => {
    if (data.type === 'added-charge') {
        return (
            <Table.Row
                warning={true}
                data-testid="added-charge"
            >
                <Responsive
                    as={Table.Cell}
                    maxWidth={MAX_WIDTH.XS}
                    colSpan={2}
                >
                    {data.title}
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.SM}
                    maxWidth={MAX_WIDTH.SM}
                    colSpan={3}
                >
                    {data.title} &nbsp;
                    {data.description ? (
                        <Popup
                            content={data.description}
                            trigger={<Icon name="info circle" />}
                        />
                    ) : (
                        ''
                    )}
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.MD}
                    colSpan={4}
                >
                    {data.title} &nbsp;
                    {data.description ? (
                        <Popup
                            content={data.description}
                            trigger={<Icon name="info circle" />}
                        />
                    ) : (
                        ''
                    )}
                </Responsive>
                <Table.Cell textAlign="center">
                    <div>{formatMoney.run(data.amount)}</div>
                </Table.Cell>
            </Table.Row>
        )
    } else if (data.type === 'credit') {
        return (
            <Table.Row
                positive={true}
                data-testid="credit"
            >
                <Responsive
                    as={Table.Cell}
                    maxWidth={MAX_WIDTH.XS}
                    colSpan={2}
                >
                    Avoirs appliqués
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.SM}
                    maxWidth={MAX_WIDTH.SM}
                    colSpan={3}
                >
                    Avoirs appliqués &nbsp;
                    <Popup
                        content="Des avoirs liés à votre compte seront appliqués à cette commande"
                        trigger={<Icon name="info circle" />}
                    />
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.MD}
                    colSpan={4}
                >
                    Avoirs appliqués &nbsp;
                    <Popup
                        content="Des avoirs liés à votre compte ont été appliqués à cette commande"
                        trigger={<Icon name="info circle" />}
                    />
                </Responsive>
                <Table.Cell textAlign="center">
                    <div>
                        <b>
                            <i>- {formatMoney.run(data.amount)}</i>
                        </b>
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    } else {
        return (
            <Table.Row
                disabled={data.type === 'removed'}
                warning={data.type === 'reduced'}
                data-testid={`${data.type || 'item'}-item`}
            >
                <Table.Cell>
                    <div>
                        <b>{data.product_name}</b>
                    </div>
                    <div>{data.producer_name}</div>
                    <Responsive
                        as="div"
                        style={{ color: '#999' }}
                        maxWidth={MAX_WIDTH.SM}
                    >
                        {data.unit_display}
                    </Responsive>
                </Table.Cell>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.MD}
                >
                    {data.unit_display}
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.SM}
                    textAlign="center"
                >
                    {formatMoney.run(data.consumer_price)}
                </Responsive>
                <Table.Cell textAlign="center">
                    {!data.allowEditItem || data.type === 'removed' ? (
                        <span
                            style={{
                                textDecoration:
                                    data.type === 'removed'
                                        ? 'line-through'
                                        : '',
                            }}
                        >
                            {data.quantity}
                        </span>
                    ) : (
                        <ItemsListItemEdit item={data} />
                    )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <div>
                        {data.type === 'removed' ? (
                            <span style={{ textDecoration: 'line-through' }}>
                                {formatMoney.run(data.full_value)}
                            </span>
                        ) : (
                            <span>{formatMoney.run(data.full_value)}</span>
                        )}
                    </div>
                    <Responsive
                        as="div"
                        maxWidth={MAX_WIDTH.XS}
                        textAlign="center"
                        style={{ color: '#d3d3d3' }}
                    >
                        <i style={{ whiteSpace: 'nowrap' }}>
                            ({formatMoney.run(data.consumer_price)})
                        </i>
                    </Responsive>
                </Table.Cell>
            </Table.Row>
        )
    }
}
