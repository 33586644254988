import * as Types from '../actions/types'

const currentLocale = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist
            return {
                ...state,
                ...(action.payload && action.payload.currentLocale
                    ? action.payload.currentLocale
                    : {}),
            }

        case Types.SET_CURRENT_LOCALE:
            return {
                ...action.locale
            }

        case Types.UNSET_CURRENT_LOCALE:
            return {}

        default:
            return state
    }
}

export default currentLocale
