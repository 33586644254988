import * as Sentry from '@sentry/browser'

export const retryPayment = function ({ paymentType, paymentToken }) {
    this.setState({
        confirmed: false,
        confirming: true,
    })

    const order_id = this.state.order_id

    if (paymentType === 'CB') {
        try {
            // gtm retry payment
            window.dataLayer.push({
                event: 'order-retry-payment-cb',
            })
        } catch (err) {
            Sentry.captureException(
                new Error(
                    'GTM failed on purchase push, ' + JSON.stringify(err)
                )
            )
        }

        this.props
            .retryOnlinePayment({ order_id, paymentToken })
            .then(result => {
                // if its true we cool
                if (!!result) {
                    try {
                        // gtm retry cb started
                        window.dataLayer.push({
                            event: 'order-retry-payment-cb-started',
                        })
                    } catch (err) {
                        Sentry.captureException(
                            new Error(
                                'GTM failed on purchase push, ' +
                                JSON.stringify(err)
                            )
                        )
                    }

                    return this.setState({
                        confirming: false,
                        confirmed: true,
                        paymentFormData:
                            paymentType === 'CB'
                                ? result.paymentFormData
                                : undefined,
                    })
                } else {
                    try {
                        // gtm retry cb failed
                        window.dataLayer.push({
                            event: 'order-retry-payment-cb-failed',
                        })
                    } catch (err) {
                        Sentry.captureException(
                            new Error(
                                'GTM failed on purchase push, ' +
                                JSON.stringify(err)
                            )
                        )
                    }

                    return this.setState({
                        serverError: result,
                    })
                }
            })
    } else {
        try {
            // gtm retry payment
            window.dataLayer.push({
                event: 'order-retry-payment-instore',
            })
        } catch (err) {
            Sentry.captureException(
                new Error(
                    'GTM failed on purchase push, ' + JSON.stringify(err)
                )
            )
        }

        this.props.setInStorePayment(order_id).then(result => {
            // if its true we cool
            if (!!result) {
                return this.setState({
                    confirming: false,
                    confirmed: true,
                })

                // if not we print the error message
            } else {
                return this.setState({
                    serverError: result,
                })
            }
        })
    }
}
