export const mapStateToProps = (
    { session },
    ownProps
) => {

    return {
        family: session.family,
        category: session.category,
        selectedCategory: session.selectedCategory,
        selectedFamily: session.selectedFamily,
        onMenuClick: ownProps.onMenuClick,
    }
}
