import React, { Fragment } from 'react'
import { Button } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

import {
    MAX_ITEM_QUANTITY,
    MAX_WIDTH,
    MIN_WIDTH,
} from '../../../../../app-constants'

export const AvailableAddRemoveButton = ({
    bumpUp,
    bumpDown,
    buttonClassName,
    buttonOrClassName,
    cartQuantity,
    quantityAvailable,
    isZoom,
}) => {
    return (
        <Fragment>
            <Responsive maxWidth={MAX_WIDTH.XS}>
                <Button.Group size={isZoom ? 'huge' : 'medium'}>
                    <Button
                        icon="minus"
                        primary
                        onClick={bumpDown}
                        className={buttonClassName}
                        title="less"
                    />
                    <Button.Or
                        text={cartQuantity}
                        className={buttonOrClassName}
                    />

                    {cartQuantity >= quantityAvailable ||
                    cartQuantity >= MAX_ITEM_QUANTITY ? (
                        <Button
                            icon="plus"
                            primary
                            disabled
                            className={buttonClassName}
                            title="more"
                        />
                    ) : (
                        <Button
                            icon="plus"
                            primary
                            onClick={bumpUp}
                            className={buttonClassName}
                            title="more"
                        />
                    )}
                </Button.Group>
            </Responsive>

            <Responsive minWidth={MIN_WIDTH.SM} maxWidth={MAX_WIDTH.MD}>
                <Button.Group size={isZoom ? 'huge' : 'mini'}>
                    <Button
                        icon="minus"
                        primary
                        onClick={bumpDown}
                        className={buttonClassName}
                        title="less"
                    />
                    <Button.Or
                        text={cartQuantity}
                        className={buttonOrClassName}
                    />

                    {cartQuantity >= quantityAvailable ||
                    cartQuantity >= MAX_ITEM_QUANTITY ? (
                        <Button
                            icon="plus"
                            primary
                            disabled
                            className={buttonClassName}
                            title="more"
                        />
                    ) : (
                        <Button
                            icon="plus"
                            primary
                            onClick={bumpUp}
                            className={buttonClassName}
                            title="more"
                        />
                    )}
                </Button.Group>
            </Responsive>

            <Responsive minWidth={MIN_WIDTH.LG}>
                <Button.Group size="medium">
                    <Button
                        icon="minus"
                        primary
                        onClick={bumpDown}
                        className={buttonClassName}
                        title="less"
                    />
                    <Button.Or
                        text={cartQuantity}
                        className={buttonOrClassName}
                    />

                    {cartQuantity >= quantityAvailable || cartQuantity >= 8 ? (
                        <Button
                            icon="plus"
                            primary
                            disabled
                            className={buttonClassName}
                            title="more"
                        />
                    ) : (
                        <Button
                            icon="plus"
                            primary
                            onClick={bumpUp}
                            className={buttonClassName}
                            title="more"
                        />
                    )}
                </Button.Group>
            </Responsive>
        </Fragment>
    )
}
