import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { AccountPageOrdersPaymentFailedActive } from '../AccountPageOrdersPaymentFailedActive'
import { AccountPageOrdersPaymentFailedInactive } from '../AccountPageOrdersPaymentFailedInactive'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export class AccountPageOrdersRow extends Component {
    static propTypes = {
        data: PropTypes.shape({
            distribution_date: PropTypes.string.isRequired,
            locale: PropTypes.string,
            units_total: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            amount_total: PropTypes.number.isRequired,
            isGlobalOrderActive: PropTypes.bool,
            handleRetryPayment: PropTypes.func.isRequired,
            _id: PropTypes.string.isRequired,
        }),
    }

    render() {
        const { data } = this.props
        const statusData =
            data.status === 'créée' && data.isGlobalOrderActive ? (
                <AccountPageOrdersPaymentFailedActive
                    handleRetry={data.handleRetryPayment}
                />
            ) : data.status === 'créée' && !data.isGlobalOrderActive ? (
                <AccountPageOrdersPaymentFailedInactive />
            ) : (
                data.status
            )

        return (
            <Table.Row
                warning={data.status === 'créée'}
                data-testid="orders-row"
            >
                <Table.Cell>
                    <Responsive
                        as={Link}
                        maxWidth={MAX_WIDTH.XS}
                        to={`/account/orders/${data._id}`}
                        style={{ fontSize: '0.9em' }}
                    >
                        <b>
                            {formatDate.run(
                                data.distribution_date,
                                'ddd DD MMM YYYY'
                            )}
                        </b>
                        <div>{data.locale}</div>
                    </Responsive>

                    <Responsive
                        as={Link}
                        minWidth={MIN_WIDTH.SM}
                        to={`/account/orders/${data._id}`}
                    >
                        {formatDate.run(
                            data.distribution_date,
                            'dddd DD MMMM YYYY'
                        )}
                    </Responsive>
                </Table.Cell>

                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.SM}
                >
                    {data.locale}
                </Responsive>

                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.SM}
                    textAlign="center"
                >
                    {data.units_total}
                </Responsive>

                <Table.Cell textAlign="center">
                    <Responsive
                        maxWidth={MAX_WIDTH.XS}
                        style={{ fontSize: '0.9em' }}
                    >
                        {formatMoney.run(data.amount_total, true)}
                    </Responsive>

                    <Responsive minWidth={MIN_WIDTH.SM}>
                        {formatMoney.run(data.amount_total, true)}
                    </Responsive>
                </Table.Cell>

                <Table.Cell textAlign="center">
                    <Responsive
                        maxWidth={MAX_WIDTH.XS}
                        style={{ fontSize: '0.9em' }}
                    >
                        {statusData}
                    </Responsive>

                    <Responsive minWidth={MIN_WIDTH.SM}>
                        {statusData}
                    </Responsive>
                </Table.Cell>
            </Table.Row>
        )
    }
}
