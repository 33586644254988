import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { FamilyItem } from './FamilyItem'

import Styles from './styles.module.css'

export const CategoryItem = ({
    categoryItem,
    family,
    category,
    handleClick,
}) => (
    <Menu.Item
        key={categoryItem._id}
        className={`${Styles.CategoryItem} ${
            categoryItem._id === category ? 'active' : ''
        }`}
        data-testid="category-item"
    >
        <Menu.Header
            className={Styles.CategoryItemHeader}
            as={Link}
            to={`/commande/${categoryItem._id}`}
            onClick={() =>
                handleClick({
                    category: categoryItem._id,
                    family: null,
                })
            }
        >
            {categoryItem.name.toLocaleUpperCase()}
        </Menu.Header>

        <Menu.Menu
            className={`${categoryItem._id === category ? 'active' : ''} ${
                Styles.FamilyMenu
            }`}
        >
            {categoryItem.families.map(familyItem => (
                <FamilyItem
                    familyItem={familyItem}
                    family={family}
                    handleClick={handleClick}
                    categoryItem={categoryItem}
                    key={familyItem.name}
                />
            ))}
        </Menu.Menu>
    </Menu.Item>
)

CategoryItem.propTypes = {
    categoryItem: PropTypes.object,
    category: PropTypes.string,
    family: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
}
