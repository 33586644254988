import React, { Fragment } from 'react'
import { List, Label, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { PrintButton } from './PrintButton'

export const UpperLeftView = ({
    allowPrint,
    consumer,
    handlePrintRequest,
    locale,
    paymentLabel,
    title,
}) => (
    <Fragment>
        <div data-testid="upper-left-view">
            <List size="large">
                {title && <h3>{title}</h3>}
                {consumer && consumer.lastname && (
                    <List.Item key={0}>
                        <List.Icon name="user" />
                        <List.Content>{`${consumer.lastname.toUpperCase()} ${
                            consumer.firstname
                        }`}</List.Content>
                    </List.Item>
                )}
                {consumer && consumer.email && (
                    <List.Item key={1}>
                        <List.Icon name="envelope" />
                        <List.Content>{consumer.email}</List.Content>
                    </List.Item>
                )}
                {consumer && consumer.telephone && (
                    <List.Item key={2}>
                        <List.Icon name="phone" />
                        <List.Content>{consumer.telephone}</List.Content>
                    </List.Item>
                )}
                {locale && (
                    <List.Item key={3}>
                        <List.Icon name="home" />
                        <List.Content>{locale}</List.Content>
                    </List.Item>
                )}
                {paymentLabel && (
                    <Label
                        color={paymentLabel.color}
                        size="tiny"
                        style={{ marginTop: '5px' }}
                    >
                        <Icon name={paymentLabel.icon} /> {paymentLabel.text}
                    </Label>
                )}
            </List>
        </div>
        {allowPrint && (
            <div>
                <PrintButton handlePrintRequest={handlePrintRequest} />
            </div>
        )}
    </Fragment>
)

UpperLeftView.propTypes = {
    allowPrint: PropTypes.bool,
    consumer: PropTypes.shape({
        lastname: PropTypes.string,
        email: PropTypes.string,
        telephone: PropTypes.string,
    }),
    handlePrintRequest: PropTypes.func,
    locale: PropTypes.string,
    paymentLabel: PropTypes.string,
    title: PropTypes.string,
}

export default UpperLeftView
