import React from 'react'
import { Modal, Header, Icon, Button, Image } from 'semantic-ui-react'
import Styles from './styles.module.css'
import banana from '../../../assets/banane-sad.svg'
import coq from '../../../assets/coq.png'

const InfoModal = ({
    show = false,
    isError = false,
    isSuccess = false,
    isInfo = false,
    onClose,
    header,
    body,
    currentCartExpirationMessage,
    icon,
    returnHome,
    actionButton,
    closeButton = true,
    modalClass = null,
    headerColor,
    closeButtonFluid,
}) => {
    if (isError) {
        header = typeof header === 'undefined' ? 'Oops...' : header
        body = body || 'Il y a eu une erreur, merci de recharger la page.'
        icon = icon || 'bug'
        headerColor = 'red'
    }
    if (isSuccess) {
        header = 'Succès !'
        body = body || "L'action a réussi"
        icon = 'checkmark'
        headerColor = 'green'
    }
    if (isInfo) {
        icon = 'info circle'
        headerColor = 'blue'
    }

    return (
        <Modal
            open={!!show}
            onClose={onClose}
            size="tiny"
            aria-label="info-modal"
            className={modalClass ? Styles[modalClass] : ''}
        >
            <Modal.Content className={modalClass ? Styles.ModalContentVersion : Styles.ModalContent}>
                {icon === 'banana' ? <Image src={banana} style={{ width: '60px', height: '60px' }} /> : icon === 'version' ? <Image src={coq} style={{ width: '60px', height: '60px' }} /> : <Icon name={icon} style={{ fontSize: '2.5em' }} />}
                <Header as="h3" color={headerColor} textAlign="center">
                    {header}
                </Header>
                {body}
            </Modal.Content>

            {(closeButton || actionButton) && (
                <Modal.Actions className={!modalClass ? "" : Styles.ModalActionsVersion}>
                    {closeButton && (
                        <Button
                            onClick={currentCartExpirationMessage !== 0 ? onClose : returnHome}
                            fluid={closeButtonFluid}
                            aria-label="close-info-modal-button"
                        >
                            {currentCartExpirationMessage !== 0 ? 'Fermer' : 'Annuler'}
                        </Button>
                    )}

                    {actionButton && (
                        <Button
                            color={actionButton.color || 'blue'}
                            onClick={actionButton.onClick}
                            aria-label="validate-info-modal-button"
                        >
                            <Icon name={actionButton.icon || 'checkmark'} />
                            {actionButton.text || 'Valider'}
                        </Button>
                    )}
                </Modal.Actions>
            )
            }
        </Modal >
    )
}

export default InfoModal
