import React, { Component } from 'react'
import history from '../../../../history'

import InfoModal from '../../../ui/InfoModal'

const getCartWarningParams = type => {
    if (type === 5) {
        return {
            type: 'warning',
            header: 'Attention : votre panier va expirer dans 5 minutes',
            body: 'Sans action de votre part, le contenu du panier risque de ne plus être disponible.',
            icon: 'info',
            button: 'Je continue mes achats',
        }
    }
    if (type === 1) {
        return {
            type: 'error',
            header: "Vite ! Plus qu'une minute avant l'expiration de votre panier",
            body: 'Sans action de votre part, le contenu du panier risque de ne plus être disponible.',
            icon: 'exclamation circle',
            button: 'Je continue mes achats',
        }
    }
    if (type === 0) {
        return {
            type: 'error',
            header: 'Mince ! Votre panier a expiré',
            body: 'Vous avez la possibilité de récupérer votre panier si vos articles sont toujours disponibles en cliquant le bouton ci-dessous',
            icon: 'banana',
            button: 'Vérifier la disponibilité',
        }
    }
}

class CartWarnings extends Component {
    constructor(props) {
        super(props)
        this.handleModalAction = this.handleModalAction.bind(this)
        this.returnHome = this.returnHome.bind(this)
    }

    handleModalAction = () => {
        if (this.props.currentCartExpirationMessage > 0) {
            this.props.scheduleSyncOrder()
        } else {
            if (this.props.orderRecovery) {
                this.props.switchToInit(this.props.orderRecovery)
            }
        }
        this.props.clearCurrentCartExpirationMessage()
    }

    returnHome = () => {
        history.push('/choisir-un-magasin')
        window.location.reload()
        window.scrollTo(0, 0)
    }

    render() {
        const showModal =
            typeof this.props.currentCartExpirationMessage !== 'undefined'
        const warningParams =
            showModal &&
            getCartWarningParams(this.props.currentCartExpirationMessage)

        return (
            <InfoModal
                icon={warningParams.icon}
                header={warningParams.header}
                returnHome={this.returnHome}
                currentCartExpirationMessage={this.props.currentCartExpirationMessage}
                body={warningParams.body}
                show={showModal}
                onClose={this.handleModalAction}
                actionButton={{
                    text: warningParams.button,
                    onClick: this.handleModalAction,
                    fluid: true,
                }}
            />
        )
    }
}

export default CartWarnings
