import * as Types from '../../types'
import { handleProducts } from './handleProducts'
import { handleProducers } from './handleProducers'
import { handleCategories } from './handleCategories'
import { handleFamilies } from './handleFamilies'
import { handleCarts } from './handleCarts'

/**
 * NOTE - a "dictionary" is an object whose keys are an identifier, and whose values
 * are the data for that identifier
 * a "list" is an array of the corresponding dictionary's keys
 */

export const handleSuccess =
    ({ dispatch, currentUser }) =>
    ({
        categories = [],
        families = [],
        products = [],
        producers = [],
        promogroups = [],
        carts = [],
        quantities = {},
    }) => {
        // we want categories -> families -> producers -> products

        let familiesByCategory = {}
        let productsByProducerByFamily = {}
        let productsByFamilyByProducer = {}
        let productsByProducer = {}
        let productsByFamily = {}

        const productsResult = handleProducts({
            products,
            quantities,
            productsByProducer,
            productsByFamily,
            productsByProducerByFamily,
            productsByFamilyByProducer,
            dispatch,
        })

        productsByProducer = productsResult.productsByProducer
        productsByFamily = productsResult.productsByFamily
        productsByProducerByFamily = productsResult.productsByProducerByFamily
        productsByFamilyByProducer = productsResult.productsByFamilyByProducer

        // PRODUCERS

        handleProducers({
            producers,
            productsByProducer,
            dispatch,
        })

        // FAMILIES

        const familiesResult = handleFamilies({
            promogroups,
            carts,
            currentUser,
            productsByFamily,
            families,
            dispatch,
            familiesByCategory,
        })

        familiesByCategory = familiesResult.familiesByCategory
        productsByFamily = familiesResult.productsByFamily

        // CATEGORIES

        handleCategories({
            categories,
            carts,
            familiesByCategory,
            dispatch,
            currentUser,
        })

        // CARTS

        handleCarts({
            carts,
            currentUser,
            dispatch,
        })

        return dispatch({
            type: Types.FETCH_INIT_READY,
        })
    }
