import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

export const OrderLoadingView = () => (
    <Dimmer
        active
        inverted
    >
        <Loader
            size="large"
            data-testid="order-loading-view"
        >
            Mise à jour des produits...
        </Loader>
    </Dimmer>
)
