// auth and account
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const CREATE_CONSUMER_REQUEST = 'CREATE_CONSUMER_REQUEST'
export const CREATE_CONSUMER_FAIL = 'CREATE_CONSUMER_FAIL'
export const CREATE_CONSUMER_SUCCESS = 'CREATE_CONSUMER_SUCCESS'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const CLEAR_FORGOT_PASSWORD_ERROR = 'CLEAR_FORGOT_PASSWORD_ERROR'

export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST'
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS'
export const FETCH_USER_INFO_FAIL = 'FETCH_USER_INFO_FAIL'

export const STORE_LIVDOM_DETAILS = 'STORE_LIVDOM_DETAILS'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'

export const REFERRAL_SENT = 'REFERRAL_SENT'

// cart
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const EMPTY_CART = 'EMPTY_CART'
export const HIDE_UPDATE_CART_NOTIFICATION = 'HIDE_UPDATE_CART_NOTIFICATION'

// version
export const UPDATE_VERSION = 'UPDATE_VERSION'

// confirm order
export const CONFIRM_ORDER_REQUEST = 'CONFIRM_ORDER_REQUEST'
export const CONFIRM_ORDER_FAIL = 'CONFIRM_ORDER_FAIL'
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS'
export const GLOBAL_ORDER_ALREADY_CLOSED = 'GLOBAL_ORDER_ALREADY_CLOSED';

export const RETRY_ONLINE_PAYMENT_REQUEST = 'RETRY_ONLINE_PAYMENT_REQUEST'
export const RETRY_ONLINE_PAYMENT_FAIL = 'RETRY_ONLINE_PAYMENT_FAIL'
export const RETRY_ONLINE_PAYMENT_SUCCESS = 'RETRY_ONLINE_PAYMENT_SUCCESS'

export const SET_INSTORE_PAYMENT_REQUEST = 'SET_INSTORE_PAYMENT_REQUEST'
export const SET_INSTORE_PAYMENT_FAIL = 'SET_INSTORE_PAYMENT_FAIL'
export const SET_INSTORE_PAYMENT_SUCCESS = 'SET_INSTORE_PAYMENT_SUCCESS'

export const CLEAR_RETRY_PAYMENT_ERROR = 'CLEAR_RETRY_PAYMENT_ERROR'

// account
export const CLEAR_CONSUMER_ORDERS = 'CLEAR_CONSUMER_ORDERS'
export const FETCH_CONSUMER_ORDERS_REQUEST = 'FETCH_CONSUMER_ORDERS_REQUEST'
export const FETCH_CONSUMER_ORDERS_SUCCESS = 'FETCH_CONSUMER_ORDERS_SUCCESS'
export const FETCH_CONSUMER_ORDERS_FAIL = 'FETCH_CONSUMER_ORDERS_FAIL'
export const CLEAR_FETCH_CONSUMER_ORDERS_ERROR =
    'CLEAR_FETCH_CONSUMER_ORDERS_ERROR'

export const FETCH_CONSUMER_ORDER_DETAIL_REQUEST =
    'FETCH_CONSUMER_ORDER_DETAIL_REQUEST'
export const FETCH_CONSUMER_ORDER_DETAIL_SUCCESS =
    'FETCH_CONSUMER_ORDER_DETAIL_SUCCESS'
export const FETCH_CONSUMER_ORDER_DETAIL_FAIL =
    'FETCH_CONSUMER_ORDER_DETAIL_FAIL'
export const CLEAR_FETCH_CONSUMER_ORDER_DETAIL_ERROR =
    'CLEAR_FETCH_CONSUMER_ORDER_DETAIL_ERROR'

export const EDIT_ORDER_ITEM_REQUEST = 'EDIT_ORDER_ITEM_REQUEST'
export const EDIT_ORDER_ITEM_FAIL = 'EDIT_ORDER_ITEM_FAIL'
export const EDIT_ORDER_ITEM_SUCCESS = 'EDIT_ORDER_ITEM_SUCCESS'
export const CLEAR_EDIT_ORDER_ITEM_ERROR = 'CLEAR_EDIT_ORDER_ITEM_ERROR'

export const REMOVE_ORDER_REQUEST = 'REMOVE_ORDER_REQUEST'
export const REMOVE_ORDER_FAIL = 'REMOVE_ORDER_FAIL'
export const REMOVE_ORDER_SUCCESS = 'REMOVE_ORDER_SUCCESS'
export const CLEAR_REMOVE_ORDER_ERROR = 'CLEAR_REMOVE_ORDER_ERROR'
export const CLEAR_VALIDATE_ORDER_ERROR = 'CLEAR_VALIDATE_ORDER_ERROR'

export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST'
export const CREATE_FEEDBACK_FAIL = 'CREATE_FEEDBACK_FAIL'
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS'
export const CLEAR_CREATE_FEEDBACK_ERROR = 'CLEAR_CREATE_FEEDBACK_ERROR'

export const FETCH_CONSUMER_PAYMENT_TOKENS_REQUEST =
    'FETCH_CONSUMER_PAYMENT_TOKENS_REQUEST'
export const FETCH_CONSUMER_PAYMENT_TOKENS_SUCCESS =
    'FETCH_CONSUMER_PAYMENT_TOKENS_SUCCESS'
export const FETCH_CONSUMER_PAYMENT_TOKENS_FAIL =
    'FETCH_CONSUMER_PAYMENT_TOKENS_FAIL'
export const DELETE_CONSUMER_PAYMENT_TOKEN_REQUEST =
    'DELETE_CONSUMER_PAYMENT_TOKEN_REQUEST'
export const DELETE_CONSUMER_PAYMENT_TOKEN_SUCCESS =
    'DELETE_CONSUMER_PAYMENT_TOKEN_REQUEST'
export const DELETE_CONSUMER_PAYMENT_TOKEN_FAIL =
    'DELETE_CONSUMER_PAYMENT_TOKEN_REQUEST'

export const FETCH_CONSUMER_FEEDBACK_REQUEST = 'FETCH_CONSUMER_FEEDBACK_REQUEST'
export const FETCH_CONSUMER_FEEDBACK_SUCCESS = 'FETCH_CONSUMER_FEEDBACK_SUCCESS'
export const FETCH_CONSUMER_FEEDBACK_FAIL = 'FETCH_CONSUMER_FEEDBACK_FAIL'
export const CLEAR_FETCH_CONSUMER_FEEDBACK_ERROR =
    'CLEAR_FETCH_CONSUMER_FEEDBACK_ERROR'

export const FETCH_CONSUMER_FEEDBACK_DETAIL_REQUEST =
    'FETCH_CONSUMER_FEEDBACK_DETAIL_REQUEST'
export const FETCH_CONSUMER_FEEDBACK_DETAIL_SUCCESS =
    'FETCH_CONSUMER_FEEDBACK_DETAIL_SUCCESS'
export const FETCH_CONSUMER_FEEDBACK_DETAIL_FAIL =
    'FETCH_CONSUMER_FEEDBACK_DETAIL_FAIL'
export const CLEAR_FETCH_CONSUMER_FEEDBACK_DETAIL_ERROR =
    'CLEAR_FETCH_CONSUMER_FEEDBACK_DETAIL_ERROR'

export const FETCH_CONSUMER_CREDITS_REQUEST = 'FETCH_CONSUMER_CREDITS_REQUEST'
export const FETCH_CONSUMER_CREDITS_SUCCESS = 'FETCH_CONSUMER_CREDITS_SUCCESS'
export const FETCH_CONSUMER_CREDITS_FAIL = 'FETCH_CONSUMER_CREDITS_FAIL'

export const FETCH_CURRENT_CONSUMER_CREDIT_REQUEST =
    'FETCH_CURRENT_CONSUMER_CREDIT_REQUEST'
export const FETCH_CURRENT_CONSUMER_CREDIT_SUCCESS =
    'FETCH_CURRENT_CONSUMER_CREDIT_SUCCESS'
export const FETCH_CURRENT_CONSUMER_CREDIT_FAIL =
    'FETCH_CURRENT_CONSUMER_CREDIT_FAIL'

export const CONSUMER_HAS_FAILED_PAYMENT_ORDER_REQUEST =
    'CONSUMER_HAS_FAILED_PAYMENT_ORDER_REQUEST'
export const CONSUMER_HAS_FAILED_PAYMENT_ORDER_SUCCESS =
    'CONSUMER_HAS_FAILED_PAYMENT_ORDER_SUCCESS'
export const CONSUMER_HAS_FAILED_PAYMENT_ORDER_FAIL =
    'CONSUMER_HAS_FAILED_PAYMENT_ORDER_FAIL'

export const HANDLE_FAILED_PAYMENT_ORDER_WARNING_SEEN =
    'HANDLE_FAILED_PAYMENT_ORDER_WARNING_SEEN'

export const UPDATE_CONSUMER_EMAIL_REQUEST = 'UPDATE_CONSUMER_EMAIL_REQUEST'
export const UPDATE_CONSUMER_EMAIL_SUCCESS = 'UPDATE_CONSUMER_EMAIL_SUCCESS'
export const UPDATE_CONSUMER_EMAIL_FAIL = 'UPDATE_CONSUMER_EMAIL_FAIL'

export const SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_REQUEST =
    'SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_REQUEST'
export const SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_SUCCESS =
    'SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_SUCCESS'
export const SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_FAIL =
    'SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_FAIL'

export const UPDATE_CONSUMER_DATA_REQUEST = 'UPDATE_CONSUMER_DATA_REQUEST'
export const UPDATE_CONSUMER_DATA_SUCCESS = 'UPDATE_CONSUMER_DATA_SUCCESS'
export const UPDATE_CONSUMER_DATA_FAIL = 'UPDATE_CONSUMER_DATA_FAIL'

export const CLOSE_CONSUMER_ACCOUNT_REQUEST = 'CLOSE_CONSUMER_ACCOUNT_REQUEST'
export const CLOSE_CONSUMER_ACCOUNT_SUCCESS = 'CLOSE_CONSUMER_ACCOUNT_SUCCESS'
export const CLOSE_CONSUMER_ACCOUNT_FAIL = 'CLOSE_CONSUMER_ACCOUNT_FAIL'
export const CREATE_REFERRAL_REQUEST = 'CREATE_REFERRAL_REQUEST'
export const CREATE_REFERRAL_SUCCESS = 'CREATE_REFERRAL_SUCCESS'
export const CREATE_REFERRAL_FAIL = 'CREATE_REFERRAL_FAIL'
export const CLEAR_CURRENT_USER_ERROR = 'CLEAR_CURRENT_USER_ERROR'

// fetchInit
export const LOCALES_UPDATE = 'LOCALES_UPDATE'
export const LOCALES_LIST_UPDATE = 'LOCALES_LIST_UPDATE'

export const NEXT_GLOBALORDERS_LIST_UPDATE = 'NEXT_GLOBALORDERS_LIST_UPDATE'
export const ACTIVE_GLOBALORDERS_LIST_UPDATE = 'ACTIVE_GLOBALORDERS_LIST_UPDATE'

export const PRODUCTS_UPDATE = 'PRODUCTS_UPDATE'
export const PRODUCTS_LIST_UPDATE = 'PRODUCTS_LIST_UPDATE'
export const PRODUCERS_UPDATE = 'PRODUCERS_UPDATE'
export const PRODUCERS_LIST_UPDATE = 'PRODUCERS_LIST_UPDATE'
export const FAMILIES_UPDATE = 'FAMILIES_UPDATE'
export const FAMILIES_LIST_UPDATE = 'FAMILIES_LIST_UPDATE'
export const CATEGORIES_UPDATE = 'CATEGORIES_UPDATE'
export const CATEGORIES_LIST_UPDATE = 'CATEGORIES_LIST_UPDATE'

export const SEARCH_FAMILIES_UPDATE = 'SEARCH_FAMILIES_UPDATE'
export const SEARCH_PRODUCTS_UPDATE = 'SEARCH_PRODUCTS_UPDATE'

export const CURRENT_GLOBAL_ORDER_UPDATE = 'CURRENT_GLOBAL_ORDER_UPDATE'

export const FETCH_LOCALES_REQUEST = 'FETCH_LOCALES_REQUEST'
export const FETCH_LOCALES_FAIL = 'FETCH_LOCALES_FAIL'
export const FETCH_LOCALES_SUCCESS = 'FETCH_LOCALES_SUCCESS'
export const FETCH_LOCALES_READY = 'FETCH_LOCALES_READY'

export const FETCH_INIT_REQUEST = 'FETCH_INIT_REQUEST'
export const FETCH_INIT_FAIL = 'FETCH_INIT_FAIL'
export const FETCH_INIT_SUCCESS = 'FETCH_INIT_SUCCESS'
export const FETCH_INIT_READY = 'FETCH_INIT_READY'
export const RESET_INIT = 'RESET_INIT'

export const FETCH_CONTENTFUL_ZIPCODES_REQUEST =
    'FETCH_CONTENTFUL_ZIPCODES_REQUEST'
export const FETCH_CONTENTFUL_ZIPCODES_FAIL = 'FETCH_CONTENTFUL_ZIPCODES_FAIL'
export const FETCH_CONTENTFUL_ZIPCODES_SUCCESS =
    'FETCH_CONTENTFUL_ZIPCODES_SUCCESS'

export const FETCH_PRODUCERS_CONTENT_REQUEST = 'FETCH_PRODUCERS_CONTENT_REQUEST'
export const FETCH_PRODUCERS_CONTENT_FAIL = 'FETCH_PRODUCERS_CONTENT_FAIL'
export const FETCH_PRODUCERS_CONTENT_SUCCESS = 'FETCH_PRODUCERS_CONTENT_SUCCESS'

export const FETCH_CONSUMER_CARTS_REQUEST = 'FETCH_CONSUMER_CARTS_REQUEST'
export const FETCH_CONSUMER_CARTS_FAIL = 'FETCH_CONSUMER_CARTS_FAIL'
export const FETCH_CONSUMER_CARTS_SUCCESS = 'FETCH_CONSUMER_CARTS_SUCCESS'
export const CONSUMER_CARTS_UPDATE = 'CONSUMER_CARTS_UPDATE'
export const CONSUMER_CARTS_LIST_UPDATE = 'CONSUMER_CARTS_LIST_UPDATE'

export const FETCH_SIGNUP_REASONS_REQUEST = 'FETCH_SIGNUP_REASONS_REQUEST'
export const FETCH_SIGNUP_REASONS_FAIL = 'FETCH_SIGNUP_REASONS_FAIL'
export const FETCH_SIGNUP_REASONS_SUCCESS = 'FETCH_SIGNUP_REASONS_SUCCESS'

export const FETCH_PRODUCTS_HEADER_CONTENT_REQUEST =
    'FETCH_PRODUCTS_HEADER_CONTENT_REQUEST'
export const FETCH_PRODUCTS_HEADER_CONTENT_FAIL =
    'FETCH_PRODUCTS_HEADER_CONTENT_FAIL'
export const FETCH_PRODUCTS_HEADER_CONTENT_SUCCESS =
    'FETCH_PRODUCTS_HEADER_CONTENT_SUCCESS'

export const AVAILABLE_SYNC_TIME_UPDATE = 'AVAILABLE_SYNC_TIME_UPDATE'
export const FETCH_AVAILABLE_REQUEST = 'FETCH_AVAILABLE_REQUEST'
export const FETCH_AVAILABLE_FAIL = 'FETCH_AVAILABLE_FAIL'
export const FETCH_AVAILABLE_SUCCESS = 'FETCH_AVAILABLE_SUCCESS'

export const QUANTITIES_UPDATE = 'QUANTITIES_UPDATE'
export const QUANTITIES_LIST_UPDATE = 'QUANTITIES_LIST_UPDATE'
export const QUANTITIES_TIMEOUT_UPDATE = 'QUANTITIES_TIMEOUT_UPDATE'

// switchTo & substitute
export const FETCH_SUBSTITUTE_REQUEST = 'FETCH_SUBSTITUTE_REQUEST'
export const FETCH_SUBSTITUTE_FAIL = 'FETCH_SUBSTITUTE_FAIL'
export const FETCH_SUBSTITUTE_SUCCESS = 'FETCH_SUBSTITUTE_SUCCESS'
export const SWITCH_TO_INIT = 'SWITCH_TO_INIT'
export const SWITCH_TO_CANCEL_INIT = 'SWITCH_TO_CANCEL_INIT'
export const SWITCH_TO_CANCEL_COMPLETE = 'SWITCH_TO_CANCEL_COMPLETE'
export const SWITCH_TO_CONFIRM_INIT = 'SWITCH_TO_CONFIRM_INIT'
export const SWITCH_TO_CONFIRM_COMPLETE = 'SWITCH_TO_CONFIRM_COMPLETE'
export const SWITCH_TO_UPDATE_SUBSTITUTES = 'SWITCH_TO_UPDATE_SUBSTITUTES'

// currentLocale
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE'
export const UNSET_CURRENT_LOCALE = 'UNSET_CURRENT_LOCALE'

// session
export const ORDER_CONTEXT_UPDATE = 'ORDER_CONTEXT_UPDATE'
export const SIDE_MENU_VISIBILITY_UPDATE = 'SIDE_MENU_VISIBILITY_UPDATE'
export const OPEN_ZOOM = 'OPEN_ZOOM'
export const CLOSE_ZOOM = 'CLOSE_ZOOM'
export const HOME_DELIVERY_WARNING_SEEN = 'HOME_DELIVERY_WARNING_SEEN'

export const SHOW_CART_EXPIRED_MESSAGE = 'SHOW_CART_EXPIRED_MESSAGE'
export const SHOW_CART_EXPIRATION_ONE_MESSAGE =
    'SHOW_CART_EXPIRATION_ONE_MESSAGE'
export const SHOW_CART_EXPIRATION_FIVE_MESSAGE =
    'SHOW_CART_EXPIRATION_FIVE_MESSAGE'
export const CLEAR_CURRENT_CART_EXPIRATION_MESSAGE =
    'CLEAR_CURRENT_CART_EXPIRATION_MESSAGE'

// orderReservation
export const ORDER_SYNC_TIME_UPDATE = 'ORDER_SYNC_TIME_UPDATE'
export const ORDER_SYNC_REQUEST = 'ORDER_SYNC_REQUEST'
export const ORDER_SYNC_SUCCESS = 'ORDER_SYNC_SUCCESS'
export const ORDER_SYNC_FAIL = 'ORDER_SYNC_FAIL'
export const ORDER_SYNC_RESET_TIMERS = 'ORDER_SYNC_RESET_TIMERS'
export const ORDER_RECOVERY_SAVE = 'ORDER_RECOVERY_SAVE'
