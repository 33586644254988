import React from 'react'
import { Header, Divider, Card, Button, Visibility } from 'semantic-ui-react'

import ProductCard from '../../ProductCard'

import Styles from './styles.module.css'

import { slugify } from '../helpers'

export const ProducerListItem = ({
    producer,
    onChangeValue,
    onOpenZoom,
    onPassNewSection,
}) => {
    let slug
    try {
        slug = producer && producer.name ? slugify(producer.name) : ''
    } catch {
        slug = ''
    }

    return (
        <Visibility
            onTopPassed={onPassNewSection({
                category: 'producteurs',
                family: producer._id,
            })}
            onBottomPassedReverse={onPassNewSection({
                category: 'producteurs',
                family: producer._id,
            })}
            offset={200}
            once={false}
            updateOn="repaint"
            key={producer._id}
        >
            <div
                className={Styles.Container}
                id={`key-${producer._id}`}
            >
                <Header
                    as="h1"
                    color="green"
                >
                    {producer.name.toUpperCase()}

                    {producer.producer_names &&
                    producer.producer_names !== producer.name ? (
                        <Header.Subheader>
                            {producer.producer_names}
                        </Header.Subheader>
                    ) : (
                        ''
                    )}
                </Header>

                <div className={Styles.ProducersDetailLinkContainer}>
                    <a
                        href={`https://www.kelbongoo.com/producteurs-et-productrices/${slug}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Button
                            size="small"
                            labelPosition="right"
                            icon="right chevron"
                            content="Découvrir ce producteur"
                        />
                    </a>
                </div>
            </div>

            <Card.Group centered>
                {producer.products.map(product => (
                    <ProductCard
                        product={product}
                        producer={producer}
                        onChangeValue={onChangeValue}
                        onOpenZoom={onOpenZoom}
                        key={product.producerproduct_id}
                    />
                ))}
            </Card.Group>

            <br />

            <Divider />
        </Visibility>
    )
}
