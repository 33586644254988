import * as Types from '../../types'

export const handleCarts = ({ carts, currentUser, dispatch }) => {
    if (carts.length && currentUser && currentUser.show_feature_carts) {
        let cartsDict = {}

        carts.forEach(i => (cartsDict[i._id] = i))

        dispatch({
            type: Types.CONSUMER_CARTS_UPDATE,
            dict: cartsDict,
        })
        dispatch({
            type: Types.CONSUMER_CARTS_LIST_UPDATE,
            list: Object.keys(cartsDict),
        })
    }
}
