import { isDegenerateCase } from './isDegenerateCase'

export const prerequestHook = ({ getState }) => {
    const { cart, currentGlobalOrder, order, currentLocale } = getState()

    if (isDegenerateCase({ order, currentGlobalOrder })) {
        return { abandon: true }
    }

    return {
        body: {
            items: cart.list.map(l => ({
                quantity: cart.items[l],
                producerproduct_id: l,
            })),
            globalorder_id: currentGlobalOrder._id,
            orderreservation_id: order.id,
            locale: currentLocale.code,
        },
    }
}
