import { persist } from './persist'
import * as Types from '../actions/types'

const clearTimers = (state) => {
    state.expirationTimer0 && clearTimeout(state.expirationTimer0)
    state.expirationTimer1 && clearTimeout(state.expirationTimer1)
    state.expirationTimer5 && clearTimeout(state.expirationTimer5)
}

const orderReservation = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            return {
                ...state,
                ...(action.payload &&
                    action.payload.id && {
                    id: action.payload.id,
                    cartExpiration: action.payload.cartExpiration,
                }),
            }

        case Types.ORDER_SYNC_TIME_UPDATE:
            return {
                ...state,
                nextSyncTime: action.timestamp,
                error: undefined,
            }

        case Types.ORDER_SYNC_RESET_TIMERS:
            return {
                ...state,
                error: undefined,
                expirationTimer0: action.expirationTimer0,
                expirationTimer1: action.expirationTimer1,
                expirationTimer5: action.expirationTimer5,
            }

        case Types.ORDER_SYNC_REQUEST:
            return {
                ...state,
                orderSyncInProgress: action.timestamp,
                error: undefined,
            }

        case Types.ORDER_SYNC_SUCCESS:
            return {
                ...state,
                nextSyncTime: undefined,
                orderSyncInProgress: false,
                id: action.orderreservation_id,
                cartExpiration: action.cartExpiration,
                syncErrorCount: 0,
            }

        case Types.ORDER_SYNC_FAIL:
            return {
                ...state,
                nextSyncTime: undefined,
                orderSyncInProgress: false,
                syncErrorCount: (state.syncErrorCount || 0) + 1,
            }

        case Types.CONFIRM_ORDER_SUCCESS:
            clearTimers(state)

            return {
                ...state,
                nextSyncTime: undefined,
                orderSyncInProgress: false,
                error: undefined,
                id: undefined,
                cartExpiration: undefined,
                expirationTimer0: undefined,
                expirationTimer1: undefined,
                expirationTimer5: undefined,
                syncErrorCount: 0,
            }

        case Types.GLOBAL_ORDER_ALREADY_CLOSED:
            clearTimers(state);

            return {
                ...state,
                globalOrderAlreadyClosed: true,
            }

        case Types.CONFIRM_ORDER_FAIL:
            clearTimers(state)

            return {
                ...state,
                nextSyncTime: undefined,
                orderSyncInProgress: false,
                error: action.error,
                id: undefined,
                cartExpiration: undefined,
                expirationTimer0: undefined,
                expirationTimer1: undefined,
                expirationTimer5: undefined,
                syncErrorCount: 0,
            }

        case Types.CLEAR_VALIDATE_ORDER_ERROR:
            return {
                ...state,
                error: undefined,
                globalOrderAlreadyClosed: undefined,
            }

        case Types.EMPTY_CART:
            clearTimers(state)

            return {
                ...state,
                nextSyncTime: undefined,
                orderSyncInProgress: false,
                error: undefined,
                id: undefined,
                cartExpiration: undefined,
                expirationTimer0: undefined,
                expirationTimer1: undefined,
                expirationTimer5: undefined,
                syncErrorCount: 0,
            }

        default:
            return state
    }
}

export default persist('order', ['id', 'cartExpiration'], orderReservation)
