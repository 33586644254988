import * as Types from '../../types'

export const handleProducts = ({
    products,
    quantities,
    productsByProducer,
    productsByFamily,
    productsByProducerByFamily,
    productsByFamilyByProducer,
    dispatch,
}) => {
    // PRODUCTS
    let productsDict = {}

    products.forEach(i => {
        // hide from view if quantity is negative and not a promo or a special product
        if (
            !i.show_soldout &&
            !i.promogroup &&
            quantities[i.producerproduct_id] < 0
        ) {
            return
        }

        // build (producer) => products
        if (!productsByProducer[i.producer_id]) {
            productsByProducer[i.producer_id] = []
        }
        productsByProducer[i.producer_id].push(i.producerproduct_id)

        // build (family) => products
        if (!productsByFamily[i.family_id]) {
            productsByFamily[i.family_id] = []
        }
        productsByFamily[i.family_id].push(i.producerproduct_id)

        // build (producer, family) => products
        if (!productsByProducerByFamily[i.producer_id]) {
            productsByProducerByFamily[i.producer_id] = {}
        }
        if (!productsByProducerByFamily[i.producer_id][i.family_id]) {
            productsByProducerByFamily[i.producer_id][i.family_id] = []
        }
        productsByProducerByFamily[i.producer_id][i.family_id].push(
            i.producerproduct_id
        )

        // build (family, producer) => products
        if (!productsByFamilyByProducer[i.family_id]) {
            productsByFamilyByProducer[i.family_id] = {}
        }
        if (!productsByFamilyByProducer[i.family_id][i.producer_id]) {
            productsByFamilyByProducer[i.family_id][i.producer_id] = []
        }
        productsByFamilyByProducer[i.family_id][i.producer_id].push(
            i.producerproduct_id
        )

        // build promotions
        if (i.promogroup) {
            const promoFamily_id = i.promogroup

            if (!productsByFamily[promoFamily_id]) {
                productsByFamily[promoFamily_id] = []
            }

            productsByFamily[promoFamily_id].push(i.producerproduct_id)
        }

        i.src = i.image ? `${i.producerproduct_id}/${i.image}` : ''

        productsDict[i.producerproduct_id] = i
    })

    dispatch({
        type: Types.PRODUCTS_UPDATE,
        dict: productsDict,
    })

    dispatch({
        type: Types.PRODUCTS_LIST_UPDATE,
        list: Object.keys(productsDict),
        productsByProducerByFamily,
        productsByFamilyByProducer,
    })

    dispatch({
        type: Types.SEARCH_PRODUCTS_UPDATE,
        products: products.map(p => ({
            name: p.product_name,
            family_id: p.family_id,
            category_id: p.category_id,
            type: 'product',
        })),
    })

    return {
        productsByProducer,
        productsByFamily,
        productsByProducerByFamily,
        productsByFamilyByProducer,
    }
}
