import React, { Component, Fragment } from 'react'
import { Form, Message, Icon, Header, Button, Modal } from 'semantic-ui-react'

export class AccountPageDetailsUpdateEmailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: props.email,
            modalOpen: false,
            running: false,
        }
    }

    shouldComponentUpdate() {
        return true
    }

    onChange = (e, { value }) => this.setState({ email: value })

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false, showDupe: false })

    onSubmit = e => {
        this.setState({ running: true })
        return this.props
            .sendUpdateEmailConfirmation({
                email: this.state.email,
            })
            .then(res => {
                if (res === 403) {
                    this.setState({
                        running: false,
                        showDupe: true,
                    })
                }
                else {
                    this.setState({
                        running: false,
                        showInfo: true,
                    })
                }
            })
            .catch(() => {
                this.setState({ running: false })
                this.handleClose()
            })
    }

    render() {
        const { email } = this.props

        return (
            <Modal
                trigger={
                    <Button
                        icon="refresh"
                        content={email}
                        type="button"
                        onClick={this.handleOpen}
                    />
                }
                open={this.state.modalOpen}
                onClose={this.handleClose}
            >
                <Modal.Header>Changer mon adresse mail</Modal.Header>

                {this.state.showInfo ?
                    <Fragment>
                        <Modal.Content>
                            <Header
                                as="h3"
                                icon
                                textAlign="center"
                            >
                                <Icon name="mail" style={{ marginBottom: '20px' }} />
                                <Header.Subheader style={{ fontSize: '16px' }}>
                                    <b>Confirmer votre nouvelle adresse mail</b><br /><br />
                                    Un lien de confirmation vient de vous être envoyé à votre
                                    nouvelle adresse email.
                                </Header.Subheader>
                            </Header>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.handleClose}>Fermer</Button>
                        </Modal.Actions>
                    </Fragment>
                    : this.state.showDupe ?
                        <Fragment>
                            <Modal.Content>
                                <Header
                                    as="h3"
                                    icon
                                    textAlign="center"
                                >
                                    <Icon name="exclamation triangle" style={{ marginBottom: '20px' }} />
                                    <Header.Subheader style={{ fontSize: '16px' }}>
                                        <b>Ce mail existe déjà pour un compte Kelbongoo !</b><br /><br />
                                        Connectez-vous avec cette adresse ou renseignez une nouvelle adresse<br />
                                        Si vous rencontrez un pépin, n’hésitez pas à nous contacter à contact@kelbongoo.com
                                    </Header.Subheader>
                                </Header>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={this.handleClose}>Fermer</Button>
                            </Modal.Actions>
                        </Fragment>
                        :
                        <Fragment>
                            <Modal.Content>
                                <Message>
                                    <Message.Header>
                                        Validation n&eacute;cessaire
                                    </Message.Header>
                                    <Message.Content>
                                        Pour confirmer votre nouvelle adresse mail,
                                        nous vous enverrons un lien de confirmation
                                        à cette adresse.
                                    </Message.Content>
                                </Message>
                                <Form>
                                    <Form.Field>
                                        <label>Nouvelle adresse mail</label>
                                        <Form.Input
                                            name="email"
                                            id="email"
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Form>
                            </Modal.Content>

                            <Modal.Actions style={{ overflow: 'auto' }}>
                                <Button
                                    primary
                                    content="Valider"
                                    icon="check"
                                    floated="right"
                                    onClick={this.onSubmit}
                                    loading={this.state.running}
                                />
                            </Modal.Actions>
                        </Fragment>
                }
            </Modal>
        )
    }
}
