export const buildProductTreeProductsMap =
    ({ producers, cart, availableProducts }) =>
    pp => ({
        ...pp,
        producer: producers[pp.producer_id],
        quantity: cart.items[pp.producerproduct_id] || 0,
        available: !!availableProducts
            ? availableProducts[pp.producerproduct_id]
            : 0,
    })
