import React from 'react'
import { Header, Icon, Label, Popup } from 'semantic-ui-react'

export const AddressHeader = ({ has_valid_delivery_address }) => (
    <Header
        as="h4"
        style={{ marginTop: '40px' }}
    >
        <Icon name="home" />
        <Header.Content>
            {typeof has_valid_delivery_address !== 'undefined' &&
            has_valid_delivery_address ? (
                <Label color="green">
                    Adresse de livraison &nbsp;
                    <Icon
                        name="checkmark"
                        style={{ marginRight: '0' }}
                    />
                </Label>
            ) : typeof has_valid_delivery_address !== 'undefined' &&
              !has_valid_delivery_address ? (
                <Label color="orange">
                    Adresse de livraison &nbsp;
                    <Icon
                        name="ban"
                        style={{ marginRight: '0' }}
                    />
                </Label>
            ) : typeof has_valid_delivery_address === 'undefined' ? (
                <Label>Adresse de livraison</Label>
            ) : (
                ''
            )}

            <Popup
                style={{ marginLeft: '0.3em' }}
                position="top center"
                trigger={
                    <Icon
                        name="question circle"
                        style={{ fontSize: '1.4em', verticalAlign: 'middle' }}
                    />
                }
                content="Si vous choissisez la livraison à domicile, nous aurions besoin de votre adresse et un numéro de téléphone"
            />
        </Header.Content>
    </Header>
)
