import { trackConnected } from '../../../utils'

export const handleSubmit = function () {
    const { email, password } = this.state

    this.props
        .login({
            email,
            password,
        })
        .then(isOK => {
            if (!isOK) {
                this.setState({ error: true })
            } else {
                // gtm login successful
                window.dataLayer.push({
                    event: 'login',
                })

                try {
                    trackConnected({ _id: this.props.currentUser._id })
                } catch (err) {
                }
            }
        })
}
