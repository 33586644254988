// import formatDate from '@kelbongoo/shared-methods/utils/formatDate';

export class GlobalOrder {
    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * check if the params given correspond to a "full" globalorder
     * the params should be the globalorderlocale for the chosen globalorder and locale
     * the globalorderlocale is "full" if it's order_tags prop has
     * - the 'ORDERS' tag, ie the orders limit for the boutique has been reached
     * - both 'FRAIS' and 'SEC' tags, ie both the frais AND sec bac storage spaces are full
     * @param {object} params - props for the globalorderlocale
     * @returns {boolean}
     */

    static isFull(params) {
        return (
            Array.isArray(params.closed_tags) &&
            (params.closed_tags.includes('ORDERS') ||
                (params.closed_tags.includes('FRAIS') &&
                    params.closed_tags.includes('SEC')))
        )
    }

    /**
     * check if the globalorder (given in params) is active, ie status = 2 and order_start < now < order_end
     * @param {object} params - props for the globalorder
     * @param {date} date - for testing
     * @returns {boolean}
     */
    static isActive(params, date) {
        const currentDate = date ? new Date(date) : new Date()
        return (
            params.order_start &&
            params.order_end &&
            params.status === 2 &&
            new Date(params.order_start.replace(/-/g, '/')) <
                new Date(currentDate) &&
            new Date(currentDate) <
                new Date(params.order_end.replace(/-/g, '/'))
        )
    }

    static getCurrentStatus(data) {
        return data && data.status
    }

    static getClosedTags(data) {
        return data && data.length > 0 ? data[0].closed_tags : []
    }

    static getQuantityUpdateData({
        currentLocale,
        globalorderlocales,
        globalorder,
    }) {

        let distributionDate, distributionHours

        try {

            distributionDate =
                currentLocale &&
                currentLocale.currentSchedule &&
                currentLocale.currentSchedule.day
            distributionHours =
                currentLocale &&
                currentLocale.currentSchedule &&
                currentLocale.currentSchedule.time
        } catch (err) {
        }

        return {
            status: this.getCurrentStatus(globalorder),
            closed_tags: this.getClosedTags(globalorderlocales),
            locale_distribution_date: distributionDate,
            locale_distribution_hours: distributionHours,
        }
    }

    isFull() {
        return this.constructor.isFull(this)
    }

    isActive(date) {
        return this.constructor.isActive(this, date)
    }

}
