import { useState, useCallback } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import tomateRouge from '../../../../../assets/tomate-rouge.svg'
import tomateJaune from '../../../../../assets/tomate-jaune.svg'
import camion from '../../../../../assets/camion.svg'

import { mapStyle } from '../TheMap/mapStyle'

const options = {
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true,
}

const StaticMap = props => {
    const {
        locales,
        center,
        staticRef,
        cardListRef,
        selectedLocale,
        setSelectedLocale,
    } = props

    const [zoom, setZoom] = useState(11)
    const [map, setMap] = useState(null)

    const mapContainerStyle = (window.innerWidth > 1024 ?
        {
            height: 'calc(87vh - 106px)',
            width: '60%',
            marginTop: 0,
        }
        : window.innerWidth < 500 ?
            {
                display: 'none'
            }
            :
            {
                height: '45vh',
                width: '100%',
                marginTop: 0,
            }
    )

    const onMapLoad = useCallback(map => {
        staticRef.current = map
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            styles={mapStyle}
            options={options}
            onLoad={onMapLoad}
            onUnmount={onUnmount}
        >
            {locales.map((locale, index) => (
                <Marker
                    key={`${locale.lat}-${locale.lng}`}
                    id={locale.code}
                    style={{ position: 'relative', cursor: 'default' }}
                    position={{
                        lat: locale.geolocation.lat,
                        lng: locale.geolocation.lng,
                    }}
                    icon={{
                        url:
                            locale.type === 0 ?
                                tomateRouge
                                : locale.type === 2 ?
                                    tomateJaune
                                    : camion,
                        origin: new window.google.maps.Point(0, 0),
                        anchor: locale.partner
                            ? new window.google.maps.Point(16, 16)
                            : new window.google.maps.Point(25, 25),
                        scaledSize: locale.type === 1
                            ? new window.google.maps.Size(30, 30)
                            : new window.google.maps.Size(25, 25),
                    }}
                    onClick={() => {
                        setSelectedLocale(locale)

                        if (cardListRef && cardListRef.current) {
                            if (window.innerWidth > 1000) {
                                cardListRef.current.children[index].scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                })
                                // Assuming your map component exposes a method to pan to the location
                                staticRef.current.setZoom(12)
                                setTimeout(() => {
                                    staticRef.current.panTo({
                                        lat: locale.geolocation.lat,
                                        lng: locale.geolocation.lng,
                                    })
                                    staticRef.current.setZoom(13) // You can adjust the zoom level as needed
                                }, 500)
                            }
                        }
                    }}

                />
            ))
            }
        </GoogleMap>
    )
}

export default StaticMap
