import React from 'react'
import { Message, Icon, Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

export const RetryPayment = ({ handleRetryPayment }) => (
    <Message
        icon
        warning
        data-testid="retry-payment"
    >
        <Icon name="warning sign" />
        <Message.Content>
            <div className={Styles.MessageContentLeft}>
                <Message.Header>En attente de paiement</Message.Header>
                <p>Merci de r&eacute;gler votre commande</p>
            </div>
            <div className={Styles.MessageContentRight}>
                <Button
                    size="tiny"
                    color="yellow"
                    onClick={handleRetryPayment}
                >
                    <Icon name="refresh" /> R&eacute;essayer le r&egrave;glement
                </Button>
            </div>
        </Message.Content>
    </Message>
)
