import React, { Fragment } from 'react'
import { Dimmer, Loader, Container } from 'semantic-ui-react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'

import Styles from './styles.module.css'

import AccountPageOrdersDetail from '../AccountPageOrdersDetail'
import AccountPageFeedbackDetail from '../AccountPageFeedbackDetail'
import AccountPageDetails from '../AccountPageDetails'
import AccountPageOrders from '../AccountPageOrders'
import AccountPageFeedback from '../AccountPageFeedback'
import AccountPageCredits from '../AccountPageCredits'
import AccountPageReferral from '../AccountPageReferral'
import AccountPageCarts from '../AccountPageCarts'
import AccountPageMenu from './AccountPageMenu'

export const AccountPage = props => {
    if (props.location.pathname === '/account') {
        return <Redirect to="/account/details" />
    }

    const showReferral = true // props.currentUser.show_feature_referral;
    const showFeedback = true // props.currentUser.show_feature_feedback;
    const showCarts = props.currentUser.show_feature_carts

    return (
        <Fragment>
            {!props.initialized && (
                <Dimmer active>
                    <Loader size="medium">Chargement</Loader>
                </Dimmer>
            )}

            <Container>
                <div className={Styles.Container}>
                    <AccountPageMenu
                        showFeedback={showFeedback}
                        showReferral={showReferral}
                        showCarts={showCarts}
                    />
                </div>

                <div style={{ marginTop: '30px', textAlign: 'left' }}>
                    <Switch>
                        <Route
                            exact
                            path="/account/details"
                            component={AccountPageDetails}
                        />
                        <Route
                            exact
                            path="/account/orders"
                            component={AccountPageOrders}
                        />
                        <Route
                            exact
                            path="/account/orders/:order_id"
                            component={AccountPageOrdersDetail}
                        />
                        <Route
                            exact
                            path="/account/credits"
                            component={AccountPageCredits}
                        />

                        {showReferral && (
                            <Route
                                exact
                                path="/account/referral"
                                component={AccountPageReferral}
                            />
                        )}

                        {showFeedback && [
                            <Route
                                exact
                                path="/account/feedback"
                                component={AccountPageFeedback}
                            />,
                            <Route
                                exact
                                path="/account/feedback/:feedback_id"
                                component={AccountPageFeedbackDetail}
                            />,
                        ]}

                        {showCarts && (
                            <Route
                                exact
                                path="/account/carts"
                                component={AccountPageCarts}
                            />
                        )}
                    </Switch>
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = ({ currentUser, initialized }) => {
    return {
        currentUser,
        initialized: true, // initialized
    }
}

const withStateAndProps = connect(mapStateToProps)

export default compose(withRouter, withStateAndProps)(AccountPage)
