/* istanbul ignore file */

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Datatable from '../../../ui/Datatable'
import EmptyFooter from '../../../ui/EmptyFooter'
import InfoModal from '../../../ui/InfoModal'

import { fetchConsumerFeedback, clearError } from '../../../../actions'
import { ProducerProductFeedback } from '../../../../models/producerproductfeedback'
import ErrorBoundary from '../../../layout/ErrorBoundary'
import { AccountPageFeedbackRow } from './AccountPageFeedbackRow'

export class FeedbackList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
        }
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    componentDidMount() {
        return this.props.fetchConsumerFeedback(1)
    }

    async fetchMoreFeedback() {
        if (!this.props.hasMoreFeedback) {
            return
        }
        await this.props.fetchMoreFeedback(this.state.currentPage + 1)
        this.setState({
            currentPage: this.state.currentPage + 1,
        })
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    render() {
        const { feedback, hasMoreFeedback, serverError } = this.props

        if (!feedback || feedback.length === 0) {
            return (
                <EmptyFooter
                    text="Aucun retour trouvé"
                    icon="frown"
                />
            )
        }

        return (
            <div>
                <InfoModal
                    show={!!serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />
                <Datatable
                    listParams={{
                        header: {
                            columns: [
                                { name: 'product_name', title: 'Produit' },
                                {
                                    name: 'createdAt',
                                    title: 'Distribution',
                                    hidden: 768,
                                },
                                {
                                    name: 'consumer_price',
                                    title: 'Prix',
                                    align: 'center',
                                    width: 80,
                                    hidden: 500,
                                },
                                {
                                    name: 'feedback',
                                    title: 'Votre retour',
                                    align: 'center',
                                },
                                {
                                    name: 'validatedAt',
                                    title: 'Date',
                                    hidden: 768,
                                },
                                {
                                    name: 'producerRepliedAt',
                                    title: 'Réponse producteur',
                                    hidden: 768,
                                },
                            ],
                        },
                        RowComponent: AccountPageFeedbackRow,
                        fetchMore: this.fetchMoreFeedback.bind(this),
                    }}
                    items={feedback}
                    hasMore={hasMoreFeedback}
                />
            </div>
        )
    }
}

export const AccountPageFeedback = ({
    feedback,
    initialized,
    hasMoreFeedback,
    fetchConsumerFeedback,
}) => (
    <ErrorBoundary page="account-page-feedback">
        <FeedbackList
            feedback={feedback}
            hasMoreFeedback={hasMoreFeedback}
            fetchConsumerFeedback={fetchConsumerFeedback}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, feedbackHistory, initialized }) => {
    return {
        currentUser,
        feedback:
            feedbackHistory &&
            feedbackHistory.feedback &&
            feedbackHistory.feedback.map(
                feedback => new ProducerProductFeedback(feedback)
            ),
        hasMoreFeedback:
            feedbackHistory &&
            feedbackHistory.count &&
            feedbackHistory.count > feedbackHistory.feedback.length,
        initialized,
        serverError: !!feedbackHistory.error,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchConsumerFeedback: page => dispatch(fetchConsumerFeedback(page)),
        clearServerError: () => dispatch(clearError('FETCH_CONSUMER_FEEDBACK')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageFeedback)
