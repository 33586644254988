import sharedMethods from '@kelbongoo/shared-methods/orders/model'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'

class Order {
    constructor(data) {
        Object.assign(this, data)
    }

    getDistributionDate(format) {
        return formatDate.run(
            this.distribution_date,
            format || 'dddd DD MMMM YYYY'
        )
    }

    getLocaleName(locales) {
        const currentLocale = locales[this.locale]
        return currentLocale && currentLocale.name
    }

    hasFailedOnlinePayment() {
        const lastPayment = this.payments[this.payments.length - 1]
        return (
            lastPayment &&
            lastPayment.method === 3 &&
            lastPayment.create_success === false
        )
    }

    hasCancelledOnlinePayment() {
        return (
            this.isCancelled() &&
            this.payments.some(p => p.method === 3 && p.create_success === true)
        )
    }
}

Object.assign(Order.prototype, sharedMethods)

export { Order }
