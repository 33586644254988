import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import { Result } from './Result'

export const ResultsList = ({ results, currentSearch, handleSelect, focusIndex }) => {
    const Results = () =>
        results.map((r, i) => (
            <>
            {i === focusIndex ?
                <Result
                    currentSearch={currentSearch}
                    onSelect={() => handleSelect(r)}
                    inFocus={true}
                    key={r.name}
                    {...r}
                />
            :
                <Result
                    currentSearch={currentSearch}
                    onSelect={() => handleSelect(r)}
                    inFocus={false}
                    key={r.name}
                    {...r}
                />
            }
            </>
        ))

    return (
        <List
            relaxed
            divided
            selection
            size="big"
            data-testid="search-results-container"
        >
            {results.length > 0 ? <Results /> : ''}

            {results.length === 0 && currentSearch.length > 2 ? (
                <List.Item data-testid="empty-search-result">
                    Aucun produit trouv&eacute;
                </List.Item>
            ) : (
                ''
            )}
        </List>
    )
}

ResultsList.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ),
    currentSearch: PropTypes.string,
    handleSelect: PropTypes.func.isRequired,
}
