import * as Types from '../../types'
import { syncOrder } from '../syncOrder'

export const scheduleSyncOrder = (dispatch, currentDate, mode) => {
    // push back sync time
    /*
        each time there is a cart item update,
        set an update method for 0.5 seconds later
        in order to only sync with > 0.5s intervals 
    */
    currentDate = currentDate || new Date()

    let timestamp = new Date(currentDate)
    timestamp.setSeconds(timestamp.getSeconds() + 0.5)

    dispatch({
        type: Types.ORDER_SYNC_TIME_UPDATE,
        timestamp: timestamp.toISOString(),
    })

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return dispatch(syncOrder(currentDate, mode))
                .then(() => resolve())
                .catch(err => reject(err))
        }, 500)
    })
}
