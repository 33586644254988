/* istanbul ignore file */

import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Image, Label } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"

import { CartCardDetailModal } from './CartCardDetailModal'

import Styles from './styles.module.css'

import panier2 from '../../../../assets/panier2.svg'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../app-constants'

class CartCard extends Component {
    static propTypes = {
        cart: PropTypes.object.isRequired,
        addCart: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            showCartDetailModal: false,
            items: props.cart.items.map(i => ({
                producer_name: i.producer_name,
                product_name: i.product_name,
                unit_display: i.unit_display,
                consumer_price: i.consumer_price,
                quantity: i.quantity,
                producerproduct_id: i.producerproduct_id,
                lastAction: undefined
            })),
        }

        this.showCartDetailModal = this.showCartDetailModal.bind(this)
        this.closeCartDetailModal = this.closeCartDetailModal.bind(this)
        this.addProducts = this.addProducts.bind(this)
    }

    showCartDetailModal() {
        this.setState({
            showCartDetailModal: true,
        })
    }

    closeCartDetailModal() {
        this.setState({
            showCartDetailModal: false,
        })
    }

    bumpUp(producerproduct_id) {
        let items = this.state.items.map(i => ({ ...i }))
        let ind = items.findIndex(
            i => i.producerproduct_id === producerproduct_id
        )
        if (items[ind].quantity < 8) {
            items[ind].quantity += 1
            items['lastAction'] = 'add'
            this.setState({ items })
        }
    }

    bumpDown(producerproduct_id) {
        let items = this.state.items.map(i => ({ ...i }))
        let ind = items.findIndex(
            i => i.producerproduct_id === producerproduct_id
        )

        if (items[ind].quantity > 0) {
            items[ind].quantity -= 1
            items['lastAction'] = 'remove'
            this.setState({ items })
        }
    }

    addProducts() {
        const items = this.state.items

        for (let i = 0; i < items.length; i++) {
            this.props.onChangeValue({
                productId: items[i].producerproduct_id,
                quantity: items[i].quantity,
                lastAction: 'add',
                isConsumerCart: true,
            })
        }

        this.closeCartDetailModal()
    }

    render() {
        const { cart } = this.props

        return (
            <Fragment>
                <Card className={Styles.Card}>
                    <Card.Content className={Styles.CardHeader}>
                        <table style={{ width: '100%', height: '44px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>
                                        <span className="name">
                                            {cart.name}
                                        </span>
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'right',
                                            width: '30px',
                                        }}
                                    >
                                        <Label circular>
                                            {cart.getTotalQuantity()}
                                        </Label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card.Content>

                    <Card.Content className={Styles.CardBody}>
                        <div className={Styles.ImageContainer}>
                            <Image
                                size="small"
                                src={panier2}
                                centered
                            />
                        </div>

                        <Responsive
                            as="div"
                            className={Styles.DescriptionContainer}
                            maxWidth={MAX_WIDTH.SM}
                        >
                            {cart.description}
                        </Responsive>

                        <Responsive
                            as="div"
                            className={Styles.DescriptionContainer}
                            minWidth={MIN_WIDTH.MD}
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{cart.description}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Responsive>
                    </Card.Content>

                    <Card.Content extra>
                        <Button
                            fluid
                            onClick={() => this.showCartDetailModal()}
                            primary
                            size="medium"
                        >
                            Voir les produits
                        </Button>
                    </Card.Content>
                </Card>

                <CartCardDetailModal
                    showCartDetailModal={this.state.showCartDetailModal}
                    closeCartDetailModal={this.closeCartDetailModal}
                    bumpUp={this.bumpUp}
                    bumpDown={this.bumpDown}
                    addProducts={this.addProducts}
                    items={this.state.items}
                />
            </Fragment>
        )
    }
}

export default CartCard
