import React, { useState, useEffect, ReactNode, ElementType } from 'react';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowWidth;
}

interface ResponsiveProps {
    as?: ElementType;
    minWidth?: number;
    maxWidth?: number;
    children: ReactNode;
    [x: string]: any; // Pour d'autres propriétés
}

const Responsive: React.FC<ResponsiveProps> = ({ as: Component = 'div', minWidth, maxWidth, children, ...rest }) => {
    const windowWidth = useWindowWidth();
    const isWithinRange = (minWidth ? windowWidth >= minWidth : true) && (maxWidth ? windowWidth <= maxWidth : true);
    
    if (isWithinRange) {
        return React.createElement(Component, rest, children);
    } else {
        return null;
    }
};

export default Responsive;
