export const componentDidMount = function () {
    // gtm order card payment started
    window.dataLayer &&
        window.dataLayer.push({
            event: 'order-payment-cb-started',
        })

    // setup timeout handling mechanism, via messages from the iframe

    function onMessage(event) {
        if (event.origin !== 'https://paiement.systempay.fr') {
            return
        }

        var data = event.data

        if (typeof window[data.func] == 'function') {
            window[data.func].call(this)
        }
    }

    if (window.addEventListener) {
        window.addEventListener('message', onMessage, false)
    } else if (window.attachEvent) {
        window.attachEvent('onmessage', onMessage, false)
    }

    window.handlePaymentDisconnection = function () {
        window.removeIframe && window.removeIframe()

        this.setState({
            hasTimeout: true,
        })
    }

    // WARN: this is not working
    // when manually doing this iframe.src finds ventes.kbgstaging.com/checkout/reglement and load it into iframe
    window.refreshIframe = () => {
        const iframe = document.getElementById('kelbongoo-payment')
        // eslint-disable-next-line no-self-assign
        iframe.src = iframe.src
    }

    window.iframeSuccess = () => {
        this.setState({
            paymentSuccess: true,
            paymentFailure: false,
        })
    }

    window.iframeFailure = () => {
        this.setState({
            paymentSuccess: false,
            paymentFailure: true,
        })
    }
}
