import * as Types from '../actions/types'

const initRequest = (state = {}, action) => {
    switch (action.type) {
        case Types.FETCH_INIT_REQUEST:
            return {
                ...state,
                loading: true,
                started: action.startDate,
                error: false,
                ready: false,
            }

        case Types.FETCH_LOCALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.FETCH_LOCALES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error || action.message,
            }

        case Types.FETCH_LOCALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                lastUpdate: action.lastUpdate,
            }

        case Types.FETCH_LOCALES_READY:
            return {
                ...state,
                localesReady: true,
            }

        case Types.FETCH_INIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case Types.FETCH_INIT_SUCCESS:
            return {
                ...state,
                loading: false,
                lastUpdate: action.lastUpdate,
            }

        case Types.FETCH_INIT_READY:
            return {
                ...state,
                ready: true,
            }

        case Types.RESET_INIT:
            return {
                ...state,
                ready: false,
            }
        default:
            return state
    }
}

export default initRequest
