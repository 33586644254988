import 'react-app-polyfill/ie11'
import 'semantic-ui-css/semantic.min.css'
import './globals.css'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import App from './components/layout/App'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import { fr } from 'date-fns/locale'
import { initStore } from './store.js'
import { initSuivi } from './utils/initSuivi'
import promiseFinally from 'promise.prototype.finally'
import { render } from 'react-dom'
import { setDefaultOptions } from 'date-fns'

export const history = createBrowserHistory();

setDefaultOptions({ locale: fr })

// FIXME: Do a better cache management
// const LAST_BUILD_STORAGE_KEY = 'LAST_DOWNLOADED_BUILD'
// const LAST_BUILD_ID = localStorage.getItem(LAST_BUILD_STORAGE_KEY)
// const BUILD_ID = process.env.REACT_APP_BUILD_ID
// if (LAST_BUILD_ID !== BUILD_ID) {
//     localStorage.clear()
//     sessionStorage.clear()
// }
// localStorage.setItem(LAST_BUILD_STORAGE_KEY, BUILD_ID)

const isProductionEnv =
    ['staging', 'production', 'development'].includes(
        process.env.REACT_APP_NAMESPACE
    ) || ['staging', 'production'].includes(process.env.NODE_ENV)

// Add `finally()` to `Promise.prototype`
promiseFinally.shim()

export const AppBase = ({ store, persistor }) => (
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistor}
        >
            <Router history={history}>
                <App persistor={persistor} />
            </Router>
        </PersistGate>
    </Provider>
)

if (isProductionEnv) {
    const { store, persistor } = initStore()

    initSuivi()


    render(
        <AppBase
            store={store}
            persistor={persistor}
        />,
        document.getElementById('root') || document.createElement('div')
    )
}
