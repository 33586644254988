import { Component } from 'react'

import InfoModal from '../../../ui/InfoModal'

class VersionChangeConfirm extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <InfoModal
                header='BONJOUR,'
                icon='version'
                closeButton={false}
                modalClass='VersionUpdate'
                body={<p><b>Le site s'est refait une petite beauté !</b><br />Pour que vous puissiez en profiter, nous avons dû <b>vous déconnecter.</b><br />Si vous rencontrez un pépin, <b>n'hésitez pas à nous écrire à contact@kelbongoo.com 👋!</b></p>}
                show={this.props.versionChangeModalOpen}
                actionButton={{
                    text: "Fermer",
                    onClick: () => this.props.confirmVersionChange(),
                    fluid: true,
                    color: 'green'
                }}
            />
        )
    }
}

export default VersionChangeConfirm
