import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import Styles from './styles.module.css'

/*
  NOTE - b/c this component uses ref, it must be a class and not functional
*/

export class FamilyItem extends React.Component {
    render() {
        const { categoryItem, familyItem, handleClick, family } = this.props

        return (
            <div
                ref={familyItem._id}
                key={familyItem._id}
            >
                <Menu.Item
                    as={Link}
                    className={Styles.FamilyItem}
                    to={`/commande/${categoryItem._id}/${familyItem._id}`}
                    // name={`${familyItem.name}`}
                    active={familyItem._id === family}
                    onClick={() =>
                        handleClick({
                            category: categoryItem._id,
                            family: familyItem._id,
                        })
                    }
                    data-testid="family-item"
                    style={{ textTransform: 'none' }}
                >
                    {`${familyItem.name}`}
                </Menu.Item>
            </div>
        )
    }
}

FamilyItem.propTypes = {
    categoryItem: PropTypes.object,
    familyItem: PropTypes.object,
    handleClick: PropTypes.func.isRequired,
    family: PropTypes.string,
}
