import * as Types from '../actions/types'

const ordersHistory = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.CLEAR_CONSUMER_ORDERS:
            return {
                loading: true,
                error: false,
                orders: [],
                count: 0,
            }

        case Types.FETCH_CONSUMER_ORDERS_REQUEST:
            return {
                loading: true,
                error: false,
                orders: state.orders || [],
                count: 0,
            }

        case Types.FETCH_CONSUMER_ORDERS_FAIL:
            return {
                loading: false,
                error: action.error,
                orders: [],
                count: 0,
            }

        case Types.FETCH_CONSUMER_ORDERS_SUCCESS:
            return {
                loading: false,
                error: null,
                orders: [...state.orders, ...action.items],
                count: action.count,
            }

        case Types.CLEAR_FETCH_CONSUMER_ORDERS_ERROR:
            return {
                ...state,
                error: false,
            }

        case Types.RETRY_ONLINE_PAYMENT_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            }

        case Types.RETRY_ONLINE_PAYMENT_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case Types.RETRY_ONLINE_PAYMENT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
            }

        case Types.SET_INSTORE_PAYMENT_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            }

        case Types.SET_INSTORE_PAYMENT_FAIL:
            return {
                ...state,
                error: action.error ? action.error.message : action.message,
                loading: false,
            }

        case Types.SET_INSTORE_PAYMENT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
            }

        case Types.CLEAR_RETRY_PAYMENT_ERROR:
            return {
                ...state,
                error: false,
            }

        default:
            return state
    }
}

export default ordersHistory
