import * as Types from '../../types'

export const handleFamilies = ({
    promogroups,
    carts = [],
    currentUser,
    productsByFamily,
    families,
    dispatch,
    familiesByCategory,
}) => {
    let familiesDict = {}

    // add promogroups / carts top families

    const headerFamilies = promogroups.map(i => ({
        ...i,
        category_id: 'promotions',
    }))

    // add default promotions family
    headerFamilies.push({
        _id: 'promotions',
        name: 'Promotions',
        category_id: 'promotions',
    })

    if (carts.length > 0 && currentUser && currentUser.show_feature_carts) {
        headerFamilies.push({
            name: 'Paniers tout fait !',
            category_id: 'carts',
            _id: 'carts',
        })

        // updating an external reference !!!
        productsByFamily['carts'] = carts.map(c => c._id)
    }

    // combine into all families
    const allFamilies = [...headerFamilies, ...families]

    allFamilies.forEach(i => {
        // ignore unused
        if (!productsByFamily[i._id]) {
            return
        }

        // build category => family
        if (!familiesByCategory[i.category_id]) {
            familiesByCategory[i.category_id] = []
        }

        // updating an external reference !!!
        familiesByCategory[i.category_id].push(i._id)

        familiesDict[i._id] = {
            ...i,
            products: productsByFamily[i._id],
        }
    })

    dispatch({
        type: Types.FAMILIES_UPDATE,
        dict: familiesDict,
    })

    dispatch({
        type: Types.FAMILIES_LIST_UPDATE,
        list: Object.keys(familiesDict),
    })

    dispatch({
        type: Types.SEARCH_FAMILIES_UPDATE,
        families: families.map(f => ({
            name: f.name,
            category_id: f.category_id,
            family_id: f._id,
            type: 'family',
        })),
    })

    return {
        familiesByCategory,
        productsByFamily,
    }
}
