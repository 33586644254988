import React from 'react'
import { List, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'

export const getPaymentTypeIcon = type => {
    return {
        card: 'credit card',
        check: 'checkmark box',
        cash: 'money',
    }[type]
}

export const formatMetadata = meta => {
    return JSON.stringify(meta)
}

export const ListItem = ({ username, type, date, amount, comment, meta }) => (
    <List.Item style={{ padding: '1em 0.5em 0.5em' }}>
        <Icon
            name={getPaymentTypeIcon(type)}
            size="big"
        />
        <List.Content style={{ verticalAlign: 'middle' }}>
            <List.Header>{`${formatDate.run(
                date,
                'YYYY/MM/DD HH:mm'
            )} : ${formatMoney.run(amount, true)}`}</List.Header>
            <List.Description>
                {username}
                {comment && `|| ${comment}`}
                {meta && `|| ${formatMetadata(meta)}`}
            </List.Description>
        </List.Content>
    </List.Item>
)

export const PaymentHistory = ({ history, direction, style }) => (
    <List
        size="tiny"
        horizontal={direction === 'horizontal'}
        style={style}
    >
        {history && history.length > 0 ? (
            history.map(item => <ListItem {...item} />)
        ) : (
            <List.Item>
                <Icon
                    disabled
                    name="ban"
                    size="big"
                />
                <List.Content style={{ verticalAlign: 'middle' }}>
                    <List.Description>
                        Pas encore de r&egrave;glement
                    </List.Description>
                </List.Content>
            </List.Item>
        )}
    </List>
)

PaymentHistory.propTypes = {
    history: PropTypes.arrayOf(
        PropTypes.shape({
            username: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            comment: PropTypes.string,
            meta: PropTypes.object,
        })
    ),
    direction: PropTypes.string,
}
