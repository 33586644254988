import { run as formatMoney } from '@kelbongoo/shared-methods/utils/formatMoney'

export const getKgPrice = product => {
    if (!product.unit_weight) {
        return
    }

    let price_per_unit = product.consumer_price * (1 / product.unit_weight)

    let unit =
        product.unit_display.indexOf('L') > -1 ||
        product.unit_display.indexOf('mL') > -1
            ? 'litre'
            : 'kg'

    return `${formatMoney(price_per_unit)} / ${unit}`
}
