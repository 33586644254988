import { Image, Icon } from 'semantic-ui-react'
import Styles from './styles.module.css'
import LocationPin from '../../../../../../assets/location_pin.svg'
import BoutiqueIcon from '../../../../../../assets/boutique.svg'
import PointRelaisIcon from '../../../../../../assets/point-relais.svg'
import PointPartenaireIcon from '../../../../../../assets/point-partenaire.svg'
import newLocale from '../../../../../../assets/new-locale.png'
import newPrivateLocale from '../../../../../../assets/new-private-locale.png'
import MiniDateSlider from '../MiniDateSlider'

const BoutiqueTile = ({
    isHover,
    setIsHover,
    locale,
    mapRef,
    currentUser,
    setMapCenter,
    handleNavigate,
}) => {
    const handleAddLocale = schedule => {
        handleNavigate(locale, schedule)
    }

    const localSchedule = locale.schedules.filter(function (val) {
        if (val === '' || val === undefined || val == null) {
            return false
        }
        return true
    })

    const distributions = locale.distributions
    const dayArray = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

    const posMapping = { 0: 'Boutique', 1: 'Camion Kelbongoo', 2: 'Point Relais' }

    return (
        <div className={Styles.cardContainer}>
            {!isHover &&
                <Icon name="close" size="large" onClick={() => setIsHover(null)} className={Styles.closeButton} />}
            <div className={Styles.infoContainer}>
                <div className={Styles.titleContainer}>
                    <h2
                        className={Styles.title}
                    >
                        {locale.name}
                    </h2>
                    {locale.new && !locale.company_domain ? <Image className={Styles.sideImage} alt="nouveau" src={newLocale} /> : locale.company_domain && <Image className={Styles.sideImage} alt="nouveau" src={newPrivateLocale} />}
                </div>
                {!isHover &&
                    <div className={Styles.flex}>
                        <Image className={Styles.cardIcons} src={LocationPin} alt="" />
                        <p className={Styles.address}>
                            {(locale.address.street && locale.address.street.split(',').length > 1 ? locale.address.street.split(',')[0] : locale.address.street) + ', ' + locale.address.zip + ' ' + locale.address.city}
                        </p>
                    </div>
                }
                <div className={Styles.dayOpenedContainer}>
                    <>
                        {locale.type === 0 ?
                            <Image className={Styles.cardIcons} src={BoutiqueIcon} alt="" />
                            :
                            locale.type === 1 ?
                                <Image className={Styles.cardIcons} src={PointPartenaireIcon} alt="" />
                                :
                                locale.type === 2 &&
                                <Image className={Styles.cardIcons} src={PointRelaisIcon} alt="" />
                        }
                        <p className={Styles.undertitle}>
                            {posMapping[locale.type]}
                        </p>
                        {dayArray.map((day, index) => {
                            return (
                                <div
                                    className={
                                        distributions.find(d => d.day === index + 1)
                                            ? Styles.dayContainerGreen
                                            : Styles.dayContainerGrey
                                    }
                                >
                                    {day}
                                </div>
                            )
                        })}
                    </>
                </div>
                {!isHover &&
                    <MiniDateSlider
                        currentUser={currentUser}
                        locale={locale}
                        localeSchedule={localSchedule}
                        handleNavigate={handleAddLocale}
                    />
                }
            </div>
        </div>
    )
}

export default BoutiqueTile