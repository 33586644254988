import 'whatwg-fetch'
import * as Types from '../../types'
import { API_ADDRESS } from '../../../app-constants.js'

export const handleReferralLink = ({ code }) => {
    return dispatch => {
        const payload = { code }

        return fetch(`${API_ADDRESS}/handle-referral-link`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(res => {
            if (res.status !== 200) {
                return false
            }

            return dispatch({
                type: Types.REFERRAL_SENT,
            })
        })
    }
}
