import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"

import { CartListItem } from '../../Order/CartListItem'
import { CartListAddedChargeListItem } from './CartListAddedChargeListItem'

import { CartListFooter } from './CartListFooter'
import { MIN_WIDTH } from '../../../../app-constants'

class CartList extends Component {
    constructor(props) {
        super(props)
        this.bumpUp = this.bumpUp.bind(this)
        this.bumpDown = this.bumpDown.bind(this)
    }

    bumpUp({ productId, quantity }) {
        const newQuantity = quantity + 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
            lastAction: 'add'
        })
    }

    bumpDown({ productId, quantity }) {
        const newQuantity = quantity - 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
            lastAction: 'remove'
        })
    }

    render() {
        const { items = [], addedCharges = [], currentCartValue } = this.props

        return (
            <div>
                <Table
                    compact="very"
                    basic
                    unstackable
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '90px' }}>
                                Quantit&eacute;
                            </Table.HeaderCell>
                            <Table.HeaderCell>Produit</Table.HeaderCell>

                            {/* hide price/units on small screens */}
                            <Responsive
                                as={Table.HeaderCell}
                                minWidth={MIN_WIDTH.MD}
                                textAlign="center"
                            >
                                Prix
                            </Responsive>
                            <Responsive
                                as={Table.HeaderCell}
                                minWidth={MIN_WIDTH.MD}
                                textAlign="center"
                            >
                                Unit&eacute;
                            </Responsive>

                            <Table.HeaderCell textAlign="center">
                                Valeur
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.length === 0 ? (
                            <Table.Row key="no-articles">
                                <Table.Cell colSpan="100">
                                    Aucun article dans le panier.
                                </Table.Cell>
                            </Table.Row>
                        ) : (
                            ''
                        )}

                        {items.length > 0
                            ? items.map(item => (
                                <CartListItem
                                    key={item.producerproduct_id}
                                    item={item}
                                    bumpUp={this.bumpUp}
                                    bumpDown={this.bumpDown}
                                />
                            ))
                            : ''}

                        {addedCharges
                            ? addedCharges.map((item, ind) => (
                                <CartListAddedChargeListItem
                                    item={item}
                                    key={`added_charges_${ind}`}
                                />
                            ))
                            : ''}
                    </Table.Body>

                    <CartListFooter currentCartValue={currentCartValue} />
                </Table>

            </div>
        )
    }
}

export default CartList
