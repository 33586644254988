import React, { Component } from 'react'

import InfoModal from '../../../../ui/InfoModal'

class LogoutConfirm extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <InfoModal
                icon='info'
                header='Déconnexion'
                body='Si vous vous déconnectez sans avoir validé votre panier, il sera perdu. Confirmez-vous la déconnexion?'
                show={this.props.logoutModalOpen}
                onClose={() => this.props.setLogoutModal(false)}
                actionButton={{
                    text: "Se déconnecter",
                    onClick: this.props.confirmLogoutClick,
                    fluid: true,
                }}
            />
        )
    }
}

export default LogoutConfirm
