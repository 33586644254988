import * as Types from '../types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getContentfulClient } from '../../utils/getContentfulClient'

const contentfulClient = getContentfulClient()

/**
 * fetch the current products list header layout from contentful, and then format into useable data
 * @returns {function} - dispatchable function to fetch header data
 */

export const fetchProductsHeaderContent = () => {
    return dispatch => {
        dispatch({
            type: Types.FETCH_PRODUCTS_HEADER_CONTENT_REQUEST,
            startDate: new Date(),
        })

        return contentfulClient
            .getEntries({
                content_type: 'productHeader',
                select: 'fields.headerTitle,fields.headerSubtitle,fields.headerText,fields.headerImage,fields.globalOrderId,fields.default,fields.salesDate,fields.locales', // must not put a space between elements !!!
            })
            .then(function (result) {
                let items =
                    result &&
                    result.items &&
                    result.items.map(item => {
                        const output = {}
                        if (!item.fields) {
                            return output
                        }
                        if (item.fields.headerTitle) {
                            output.headerTitle = item.fields.headerTitle
                        }
                        if (item.fields.headerImage) {
                            output.headerImage = item.fields.headerImage.fields.file.url
                        }
                        if (item.fields.headerSubtitle) {
                            output.headerSubtitle = documentToHtmlString(item.fields.headerSubtitle)
                        }
                        if (item.fields.headerText) {
                            output.headerText = documentToHtmlString(item.fields.headerText)
                        }
                        if (item.fields.globalOrderId) {
                            output.globalorder_id = item.fields.globalOrderId
                        }
                        if (item.fields.salesDate) {
                            output.salesDate = item.fields.salesDate
                        }
                        if (item.fields.locales) {
                            output.locales = item.fields.locales
                        }
                        output.default = item.fields.default

                        return output
                    })

                dispatch({
                    type: Types.FETCH_PRODUCTS_HEADER_CONTENT_SUCCESS,
                    items,
                })
            })
            .catch(error => {
                dispatch({
                    type: Types.FETCH_PRODUCTS_HEADER_CONTENT_FAIL,
                    error,
                })
            })
    }
}
