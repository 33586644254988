import { wrapClientApiCall } from '../../../utils'

export const fetchConsumerPaymentTokens = () =>
    wrapClientApiCall({
        actionRoot: 'FETCH_CONSUMER_PAYMENT_TOKENS',
        url: `api/consumer/fetch-payment-tokens`,
        successPayload: data => ({
            tokens: data.tokens,
        }),
    })
