import { MAX_ITEM_QUANTITY } from '../../../app-constants'

/**
 * check that the given quantity is valid - quantities must not
 * - be NaN
 * - be <= 0
 * - be > MAX_ITEM_QUANTITY... UNLESS the currentUser has the right to go over the limit
 * @param {object} currentUser - currentUser document
 * @param {number} quantity - quantity to test
 * @returns {boolean}
 */

export const isQuantityValid = ({ currentUser = {}, quantity }) => {
    if (isNaN(quantity)) {
        return false
    }

    if (quantity < 0) {
        return false
    }

    if (quantity > MAX_ITEM_QUANTITY) {
        if (!currentUser) {
            return false
        }

        if (!currentUser.suppress_cart_quantity_limit) {
            return false
        }
    }

    return true
}
