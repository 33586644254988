export const mapItems = (destination, item) => {
    if (destination === 'gtm') {
        return {
            item_name: item.product_name,
            item_id: item.producerproduct_id,
            item_price: item.consumer_price / 100,
            item_quantity: item.quantity,
        }
    }

    if (destination === 'klaviyo') {
        return {
            ProductID: item.producerproduct_id,
            Quantity: item.quantity,
            ItemPrice: item.consumer_price / 100,
            RowTotal: (item.consumer_price / 100) * item.quantity,
        }
    }
}
