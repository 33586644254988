import { GlobalOrder } from '../../../models'

export const mapStateToProps = ({
    cart,
    products,
    currentUser,
    currentLocale,
    currentGlobalOrder,
    initRequest,
    switchTo,
    orderRecovery
}) => {

    return {
        currentUser,
        cart,
        currentGlobalOrder: new GlobalOrder(currentGlobalOrder),
        ready: !!initRequest.ready,
        currentLocale,
        initFail: !!initRequest.error,
        switchTo,
        orderRecovery,
        products,
    }
}
