import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import Styles from './styles.module.css'

export const Result = ({ name, type, onSelect, currentSearch, inFocus }) => {
    const re = new RegExp(currentSearch, 'ig')
    
    return (
        <List.Item
            key={name}
            className={inFocus ? Styles.inFocus : (type === 'family' ? 'search-family' : '')}
            onClick={onSelect}
            title="search-result"
        >
            {type === 'family' && (
                <List.Icon
                    name="chevron right"
                    data-testid="family-indicator"
                />
            )}
            <List.Content>
                <span
                    style={{
                        fontStyle: type === 'product' ? 'inherit' : 'italic',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: name.replace(
                            re,
                            '<b><u>' + currentSearch + '</u></b>'
                        ),
                    }}
                />
            </List.Content>
        </List.Item>
    )
}

Result.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    currentSearch: PropTypes.string,
}
