import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Grid } from 'semantic-ui-react'
import { formatDate } from '../../../../utils/formatDate'

// import Flatpickr from 'react-flatpickr'
// import './flatpickr.css';
// import { French } from "flatpickr/dist/l10n/fr.js"

// import './styles.css'

export const areRangesEqual = (a, b) => {
    return a.every((item, ind) => item.getTime() === b[ind].getTime())
}

export const ButtonInput = ({ onClick, value }) => (
    <Button
        fluid
        onClick={onClick}
    >
        {value}
    </Button>
)

class DaterangeFilter extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        defaultRange: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired)
            .isRequired,
        dateFormat: PropTypes.string,
        showTimeSelect: PropTypes.bool,
        locale: PropTypes.string,
    }

    constructor(props) {
        super(props)

        if (!this.props.defaultRange) {
            throw new Error('Invalid props - defaultRange is required')
        }

        this.defaultRange = this.props.defaultRange
        this.state = {
            range: this.defaultRange,
            modalOpen: false,
        }
    }

    validate() {
        this.props.onChange(this.state.range)
        this.closeModal()
    }

    reset() {
        this.setState({
            range: this.defaultRange,
        })
        this.props.onChange(this.defaultRange)
        this.closeModal()
    }

    openModal() {
        this.setState({
            modalOpen: true,
        })
    }

    closeModal() {
        this.setState({
            modalOpen: false,
        })
    }

    getModalTrigger({ text, active }) {
        return (
            <Button
                toggle
                fluid
                className="trigger"
                active={active}
                onClick={this.openModal.bind(this)}
            >
                {text}
            </Button>
        )
    }

    isActive() {
        return !areRangesEqual(this.defaultRange, this.state.range)
    }

    handleChange(str) {
        let range = str.split(' au ').map(d => new Date(d))
        if (range.length !== 2) {
            return
        }
        this.setState({ range })
    }

    formatDateRange(format = 'DD/MM/YY') {
        return `${formatDate.run(
            this.state.range[0],
            format
        )} - ${formatDate.run(this.state.range[1], format)}`
    }

    render() {
        const isActive = this.isActive()
        const format = this.props.dateFormat || 'DD/MM/YY'
        const currentButtonText = this.formatDateRange(format)
        // const locale = this.props.locale || 'fr-FR';
        // const pickerOptions = Object.assign({
        //   mode: 'range',
        //   inline: true,
        //   locale: 'French',
        //   // dateFormat: format,
        //   defaultDate: this.state.range
        // }, this.props.pickerOptions || {});

        return (
            <Modal
                className="daterange-filter-modal"
                trigger={this.getModalTrigger.call(this, {
                    text: currentButtonText,
                    active: isActive,
                })}
                open={this.state.modalOpen}
                onClose={this.closeModal.bind(this)}
                size="small"
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Column width={8}>
                            Filtrer par plage de dates
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Button
                                primary
                                className="validate"
                                floated="right"
                                onClick={this.validate.bind(this)}
                            >
                                Valider
                            </Button>
                            <Button
                                className="reset"
                                floated="right"
                                onClick={this.reset.bind(this)}
                            >
                                R&eacute;initialiser
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Header>
                <Modal.Content className="centered">
                    <div className="picker-container">
                        {/* <Flatpickr
              options={pickerOptions}
              onChange={(_, str) => this.handleChange(str)}
            /> */}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default DaterangeFilter

/*
locale={{
              locale: require('date-fns/locale/fr'),
              headerFormat: 'DD MMMM YY',
              weekdays: ["Dim","Lun","Mar","Mer","Jeu","Ven","Sam"],
              blank: 'Aucune date selectionnee',
              todayLabel: {
               long: 'Aujourd\'hui',
               short: 'Auj.'
              }
            }}
            width={'100%'}
            height={300}
            onSelect={this.handleChange.bind(this)}
            Component={withRange(Calendar)}
            selected={{
              start: this.state.range[0],
              end: this.state.range[1]
            }}
*/
