import * as Types from '../../types'

export const selectGlobalOrder = globalorder_id => {
    return (dispatch, getState) => {
        // const { activeGlobalOrdersList, cart, order } = getState();
        const { activeGlobalOrdersList } = getState()

        const currentGlobalOrder = activeGlobalOrdersList.find(
            g => g._id === globalorder_id
        )

        dispatch({
            type: Types.CURRENT_GLOBAL_ORDER_UPDATE,
            currentGlobalOrder,
        })
    }
}
