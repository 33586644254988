export class ConsumerCart {
    constructor(
        { items = [], name, description },
        { products = {}, available = {} }
    ) {
        // takes a list and a dictionary

        const _items = items.reduce((acc, item) => {
            const ref = products[item.producerproduct_id]
            const av = available[item.producerproduct_id]

            if (!ref || !av) return acc

            acc.push({
                producer_name: ref.producer_name,
                product_name: ref.product_name,
                unit_display: ref.unit_display,
                consumer_price: ref.consumer_price,
                producerproduct_id: ref.producerproduct_id,
                quantity: item.quantity,
                available: av,
            })

            return acc
        }, [])

        Object.assign(this, { items: _items, name, description })
    }

    getTotalTVA() {
        return this.getTotalTTC() - this.getTotalHT()
    }

    getTotalHT() {
        return this.items.reduce((acc, item) => {
            acc += item.consumer_price * item.quantity
            return acc
        }, 0)
    }

    isAvailable() {
        return this.items.some(i => !!i.available)
    }

    getTotalTTC() {
        return this.items.reduce((acc, item) => {
            acc += item.consumer_price * item.quantity
            return acc
        }, 0)
    }

    getTotalQuantity() {
        return this.items.reduce((t, i) => (t += i.quantity), 0)
    }

    getLastNItems(n) {
        return this.items.slice(-n)
    }

    getRemainingCount(n) {
        return this.items.length - this.items.slice(-n).length
    }
}
