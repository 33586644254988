import React from 'react'
import { Dimmer, Message, Icon, Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

const ErrorMessage = ({ handleRetry }) => (
    <Dimmer
        active
        inverted
        className={Styles.Dimmer}
    >
        <Message
            size="large"
            compact
            data-testid="error-message"
        >
            <Message.Content>
                <Icon
                    name="bug"
                    size="huge"
                    style={{ marginBottom: '30px' }}
                />
                <Message.Header>Pas de chance !</Message.Header>

                <p style={{ marginBottom: '16px' }}>
                    Le service est indisponible...
                </p>

                <Button
                    primary
                    onClick={handleRetry}
                >
                    Réessayer
                </Button>
            </Message.Content>
        </Message>
    </Dimmer>
)

export default ErrorMessage
