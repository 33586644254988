import 'whatwg-fetch'
import { API_ADDRESS } from '../../../app-constants.js'

/**
 * user signup for email notifications about Kelbongoo
 * just an API call, no dispatching
 * NOTE the return value is 'hasError', so false if status === 200
 * @param {string} email - signup email
 * @param {string} locale - signup locale
 * @returns {promise}
 */

export const signupForEmailNotifications = ({ email, locale }) => {
    return () => {
        return fetch(`${API_ADDRESS}/signup-for-notifications`, {
            body: JSON.stringify({ email, locale }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(res => res.status !== 200)
        // NOTE return value is 'hasError'
    }
}
