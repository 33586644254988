import * as Types from '../../types'

/**
 * updates substitute quantity
 */

export const updateSubstituteQuantity = ({ productId, quantity }) => {
    return (dispatch, getState) => {
        const { switchTo } = getState()
        const substituteProducts = switchTo.substituteProducts.map(item => {
            if (item.producerproduct_id === productId) {
                item.quantity = quantity
            }
            return item
        })

        return dispatch({
            type: Types.SWITCH_TO_UPDATE_SUBSTITUTES,
            substituteProducts,
        })
    }
}
