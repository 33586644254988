import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Table } from 'semantic-ui-react'

export const EllipsisItem = ({ notShown }) => (
    <Table.Row
        key="ellipsis"
        title="undisplayed-cart-items"
    >
        <Table.Cell
            colSpan="3"
            style={{ color: '#ccc', textAlign: 'center' }}
        >
            <Icon name="ellipsis horizontal" />
            <br />
            {notShown} article{notShown > 1 ? 's' : ''} non affiché
            {notShown > 1 ? 's' : ''}
        </Table.Cell>
    </Table.Row>
)

EllipsisItem.propTypes = {
    notShown: PropTypes.number.isRequired,
}
