import React, { Component } from 'react'
import { Button, Modal, Grid, Icon } from 'semantic-ui-react'

export class ConfirmButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
        }
    }

    handleClick() {
        this.setState({
            modalOpen: true,
        })
    }

    handleCancel() {
        this.setState({
            modalOpen: false,
        })
    }

    async handleConfirm() {
        this.setState({
            modalOpen: false,
        })
        return this.props.onConfirm()
    }

    render() {
        let { modalMessage, ...buttonProps } = this.props
        return (
            <Modal
                trigger={
                    <Button
                        onClick={this.handleClick.bind(this)}
                        {...buttonProps}
                    />
                }
                open={this.state.modalOpen}
                onClose={this.handleCancel.bind(this)}
            >
                <Modal.Content>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column
                                    width={16}
                                    textAlign="center"
                                >
                                    <Icon
                                        name="warning sign"
                                        color="orange"
                                        size="massive"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column
                                    width={16}
                                    textAlign="center"
                                    style={{ fontSize: '1.2em' }}
                                >
                                    {this.props.modalMessage}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Annuler"
                        icon="remove"
                        onClick={this.handleCancel.bind(this)}
                    />
                    <Button
                        content="Valider"
                        icon="checkmark"
                        color="orange"
                        onClick={this.handleConfirm.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
