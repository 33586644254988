import * as Types from '../../types'
import { wrapClientApiCall } from '../../../utils'

export const fetchConsumerOrders = (page = 1) =>
    wrapClientApiCall({
        actionRoot: 'FETCH_CONSUMER_ORDERS',
        url: `api/orders/fetch-for-consumer?page=${page}`,
        prerequestHook: ({ dispatch }) => {
            if (page === 1) {
                dispatch({
                    type: Types.CLEAR_CONSUMER_ORDERS,
                })
            }
        },
    })
