import * as Types from '../actions/types'

const orderRecovery = (state = {}, action = {}) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist
            return {
                ...state,
                ...(action.payload && action.payload.orderRecovery
                    ? {
                          ...action.payload.orderRecovery,
                      }
                    : {}),
            }

        case Types.ORDER_RECOVERY_SAVE:
            return {
                cart: action.cart,
                locale: action.locale,
                schedule: action.schedule,
            }

        case Types.SWITCH_TO_CONFIRM_COMPLETE:
            return {}

        default:
            return state
    }
}

export default orderRecovery
