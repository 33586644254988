import { API_ADDRESS } from '../../../../app-constants.js'

export const handlePrintRequest =
    (order_id, token) =>
    (isFacture = false) => {
        let url = isFacture
            ? `${API_ADDRESS}/api/orders/print-facture?order_id=${order_id}`
            : `${API_ADDRESS}/api/orders/print?order_id=${order_id}`

        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (result) {
                window.open(result.link, 'Votre commande')
            })
    }
