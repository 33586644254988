import React from 'react'
import { Menu, Dropdown } from 'semantic-ui-react'

export class PrintButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
        this.handlePrintRequest = this.handlePrintRequest.bind(this)
    }

    handlePrintRequest(e, { value }) {
        this.setState({ loading: true })
        const isFacture = value === 'facture'
        return this.props
            .handlePrintRequest(isFacture)
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        const options = [
            { key: 1, text: 'Imprimer ma commande', value: 'basic' },
            { key: 2, text: 'Imprimer ma facture', value: 'facture' },
        ]
        return (
            <Menu compact>
                <Dropdown
                    text="Imprimer"
                    options={options}
                    simple
                    item
                    loading={this.state.loading}
                    onChange={this.handlePrintRequest}
                />
            </Menu>
        )
    }
}
