export const prerequestHook =
    ({ paymentType, paymentToken }) =>
        ({ getState }) => {
            const { cart, currentGlobalOrder, order, currentLocale } = getState()

            return {
                body: {
                    items: cart.list.map(l => ({
                        quantity: cart.items[l],
                        producerproduct_id: l,
                    })),
                    globalorder_id: currentGlobalOrder._id,
                    orderreservation_id: order.id,
                    locale: currentLocale.code,
                    paymentType,
                    paymentToken,
                },
            }
        }
