import React, {FunctionComponent} from 'react'
import { Table, Icon } from 'semantic-ui-react'
import Responsive  from "../../../../../ui/Responsive"
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

type CartCreditProps = {
    amount: number,
    type: 'global-credit' | 'welcome-credit'
}

const labelsFrom: Record<CartCreditProps['type'], string> = {
    "global-credit": "Avoirs appliqués",
    "welcome-credit": "Crédit de bienvenue"
}

type CreditLineProps = {
    type: CartCreditProps["type"]
}

const CreditLine: FunctionComponent<CreditLineProps> = ({type}) => (
    <div style={{ float: 'left' }}>
        {labelsFrom[type]}
    </div>
)

export const CartCredit = ({ amount, type }: CartCreditProps) => (
    <Table.Row key="credits" data-testid="cart-credit" className="positive">
        <Table.Cell textAlign="center">
            <Icon name="gift"/>
        </Table.Cell>

        <Responsive as={Table.Cell} maxWidth={MAX_WIDTH.SM} singleLine>
            <CreditLine type={type}/>
        </Responsive>
        <Responsive as={Table.Cell} minWidth={MIN_WIDTH.MD} singleLine colSpan={3}>
            <CreditLine type={type}/>
        </Responsive>

        <Table.Cell textAlign="center" singleLine>
            <strong>{formatCurrency(-amount)}</strong>
        </Table.Cell>
    </Table.Row>
)
