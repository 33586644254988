import React from 'react'
import { Table, Icon } from 'semantic-ui-react'
import Responsive from "../../../../../ui/Responsive"
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'

export const CartAddedCharge = ({ item }) => (
    <Table.Row
        key={'added_charge_' + item.code}
        data-testid="cart-added-charge"
    >
        <Table.Cell textAlign="center">
            <Icon name="minus" />
        </Table.Cell>

        <Responsive
            as={Table.Cell}
            maxWidth={MAX_WIDTH.SM}
            singleLine
        >
            <div style={{ float: 'left' }}>
                <i>{item.title}</i>
            </div>
        </Responsive>

        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
            singleLine
            colSpan={3}
        >
            <div style={{ float: 'left' }}>
                <i>{item.title}</i>
            </div>
        </Responsive>

        <Table.Cell
            textAlign="center"
            singleLine
        >
            <strong>{formatMoney.run(item.amount, true)}</strong>
        </Table.Cell>
    </Table.Row>
)
