import React, { Component, Fragment } from 'react'
import { Grid} from 'semantic-ui-react'
import  Responsive  from '../../../../ui/Responsive'
import PropTypes from 'prop-types'

import { getAddedChargeDefinition } from '@kelbongoo/shared-methods/orders/collection'

import { OrderList } from './OrderList'
import { BottomView } from './BottomView'
import { ItemsListHeader } from './ItemsListHeader'
import { UpperRightView } from './UpperRightView'
import { UpperLeftView } from './UpperLeftView'
import { RetryPayment } from './RetryPayment'
import { getPaymentLabelData, getStatusName } from './helpers'

import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

class OrderView extends Component {
    static propTypes = {
        order: PropTypes.object.isRequired,
        allowPrint: PropTypes.bool,
        handlePrintRequest: PropTypes.func,
        allowRemove: PropTypes.bool,
        allowEditItem: PropTypes.bool,
        onRemove: PropTypes.func,
        onEditItem: PropTypes.func,
        allowRetryPayment: PropTypes.bool.isRequired,
        handleRetryPayment: PropTypes.func.isRequired,
    }

    render() {
        let {
            order,
            items,
            reduced,
            removed,
            addedCharges,
            creditUsed,
            allowPrint,
            handlePrintRequest,
            onRemove,
            allowRemove,
            allowEditItem,
            onEditItem,
            allowRetryPayment,
            handleRetryPayment,
        } = this.props

        addedCharges = (addedCharges || []).map(a => {
            return {
                ...getAddedChargeDefinition({ code: a.code }),
                amount: a.amount,
                amount_ht: a.amount_ht,
                tva: a.tva,
            }
        })

        allowRetryPayment = allowRetryPayment && !order.paidAt

        return (
            <div data-testid="order-container">
                {/* retry payment */}

                {allowRetryPayment && (
                    <RetryPayment handleRetryPayment={handleRetryPayment} />
                )}

                {/* basic order info */}
                <div className={Styles.UpperViewContainer}>
                    <Responsive
                        as={Fragment}
                        maxWidth={MAX_WIDTH.SM}
                    >
                        <div>
                            <UpperLeftView
                                allowPrint={allowPrint}
                                consumer={order.consumer}
                                handlePrintRequest={handlePrintRequest}
                                locale={order.locale}
                                paymentLabel={getPaymentLabelData(order)}
                                title={order.getDistributionDate()}
                            />
                        </div>
                    </Responsive>
                    <Responsive
                        as={Fragment}
                        minWidth={MIN_WIDTH.MD}
                    >
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <UpperLeftView
                                        consumer={order.consumer}
                                        locale={order.locale}
                                        allowPrint={allowPrint}
                                        handlePrintRequest={handlePrintRequest}
                                        title={order.getDistributionDate()}
                                        paymentLabel={getPaymentLabelData(
                                            order
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <UpperRightView
                                        events={order.events}
                                        status_name={getStatusName({ order })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Responsive>
                </div>

                {/* items header */}
                <div className={Styles.ItemsListHeaderContainer}>
                    <ItemsListHeader
                        articlesCount={order.getUnitsTotal()}
                        total={order.getAmountTotal()}
                    />
                </div>

                {/* items list */}
                <div className={Styles.OrderListContainer}>
                    <OrderList
                        items={items}
                        reduced={reduced}
                        removed={removed}
                        addedCharges={addedCharges}
                        creditUsed={creditUsed}
                        allowEditItem={allowEditItem}
                        onEditItem={onEditItem}
                    />
                </div>

                {/* cancel order button footer */}
                {allowRemove && (
                    <BottomView
                        onRemove={onRemove}
                        allowRemove={allowRemove}
                    />
                )}
            </div>
        )
    }
}

export default OrderView
