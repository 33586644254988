import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export const formatOptions = options => {
    return options.map(option => {
        return {
            text: option.title,
            value: option.name,
            selected: option.default,
        }
    })
}

const SelectFilter = ({ title, options, onChange }) => {
    let selected = options.find(option => option.selected)
    let onSelected = (e, data) => onChange(data.value)
    return (
        <Dropdown
            fluid
            selection
            placeholder={title}
            className="selectfilter"
            value={selected && selected.name}
            onChange={onSelected}
            options={formatOptions(options)}
        />
    )
}

export default SelectFilter
