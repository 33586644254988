import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

import Styles from './styles.module.css'

export const ProductQuantityLimited = () => (
    <div
        className={Styles.LastProducts}
        title="product-quantity-limited"
    >
        <Responsive maxWidth={MAX_WIDTH.MD}>
            <Popup
                content="Quantité limitée !"
                trigger={
                    <Icon
                        name="warning circle"
                        title=""
                    />
                }
            />
        </Responsive>
        <Responsive minWidth={MIN_WIDTH.LG}>Quantité limitée !</Responsive>
    </div>
)
