export const isDataValid = ({ updatedList, updatedItems }) => {
    let outputItems = updatedList.map(l => ({
        quantity: updatedItems[l],
        producerproduct_id: l,
    }))

    for (let item of outputItems) {
        if (!item) {
            return false
        }
        if (!item.producerproduct_id) {
            return false
        }
        if (isNaN(item.quantity) || item.quantity <= 0) {
            return false
        }
    }

    return true
}
