import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import Styles from './styles.module.css'

export const PersistentCheckoutBar = ({ hasCartItems }) => (
    <Grid.Row data-testid="persistent-checkout-bar">
        <Grid.Column
            width={16}
            textAlign="center"
        >
            <div className={Styles.PersistentCheckoutBar}>
                {hasCartItems ? (
                    <Link to="/cart">
                        <Button
                            primary
                            size="huge"
                        >
                            Finaliser ma commande
                        </Button>
                    </Link>
                ) : (
                    <Button
                        primary
                        size="huge"
                        disabled={true}
                    >
                        Finaliser ma commande
                    </Button>
                )}
            </div>
        </Grid.Column>
    </Grid.Row>
)
