import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Modal, Header, Button } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import { getKgPrice, getProductSpecialIngredients } from '../../../../utils'

import { ProductBioLabel } from '../ProductBioLabel'
import { ProductQuantityEdit } from '../ProductQuantityEdit'
import { ProductImage } from './ProductImage'
import { ProductIngredients } from './ProductIngredients'
import { ProductProducerLink } from './ProductProducerLink'

import Styles from './styles.module.css'

const getCurrentQuantity = (cart, product) => {
    return cart.items[product.producerproduct_id] || 0
}

class ProductZoom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.defaultValue || 0,
        }

        this.bumpDown = this.bumpDown.bind(this)
        this.bumpUp = this.bumpUp.bind(this)
    }

    shouldComponentUpdate() {
        return true
    }

    bumpUp(e) {
        if (
            this.props.max !== undefined &&
            this.state.value >= this.props.max
        ) {
            e && e.stopPropagation()
            e && e.preventDefault()
            return
        }
        const quantity =
            getCurrentQuantity(this.props.cart, this.props.product) + 1

        this.props.onChangeValue({
            e,
            productId: this.props.product.producerproduct_id,
            quantity,
            lastAction: 'add'
        })
    }

    bumpDown(e) {
        if (
            this.props.min !== undefined &&
            this.state.value <= this.props.min
        ) {
            e && e.stopPropagation()
            e && e.preventDefault()
            return
        }
        const quantity =
            getCurrentQuantity(this.props.cart, this.props.product) - 1

        this.props.onChangeValue({
            e,
            productId: this.props.product.producerproduct_id,
            quantity,
            lastAction: 'remove'
        })
    }

    createMarkup() {
        return {
            __html: getProductSpecialIngredients(
                this.props.product.ingredients
            ),
        }
    }

    render() {
        const {
            open,
            closeCallback,
            product,
            product: { producer },
            cart,
            isFull,
        } = this.props

        let quantity = getCurrentQuantity(cart, product)

        return (
            <Modal
                open={open}
                onClose={closeCallback}
                dimmer="inverted"
                closeIcon
                className={Styles.Modal}
                data-testid="product-zoom"
            >
                {product ? (
                    <Fragment>
                        <Modal.Content
                            image
                            scrolling
                        >
                            <ProductImage product={product} />

                            <Modal.Description>
                                <Header
                                    as="h3"
                                    style={{ marginBottom: '0' }}
                                >
                                    <i>{producer.name}</i>
                                </Header>

                                <Header
                                    as="h2"
                                    style={{ marginTop: '10px' }}
                                >
                                    {product.product_name}
                                    <Header.Subheader>
                                        {product.unit_display}
                                    </Header.Subheader>
                                </Header>

                                <div>
                                    <Header
                                        as="h3"
                                        style={{ display: 'inline-block' }}
                                    >
                                        {formatMoney.run(
                                            product.consumer_price
                                        )}
                                        {product.unit_weight ? (
                                            <Header.Subheader>
                                                {getKgPrice(product)}
                                            </Header.Subheader>
                                        ) : (
                                            ''
                                        )}
                                    </Header>

                                    {product.bio ? (
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            <ProductBioLabel
                                                bioClass={Styles.Bio}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                {product.subtitle ? (
                                    <p style={{ fontStyle: 'italic' }}>
                                        {product.subtitle}
                                    </p>
                                ) : (
                                    ''
                                )}

                                {/* comment */}

                                {product.ingredients ? (
                                    <ProductIngredients
                                        ingredientsMarkup={this.createMarkup()}
                                    />
                                ) : (
                                    ''
                                )}

                                <ProductProducerLink
                                    producer={product.producer}
                                    closeCallback={closeCallback}
                                />
                            </Modal.Description>
                        </Modal.Content>

                        <Modal.Actions>
                            <Button
                                size="huge"
                                floated="left"
                                onClick={() => this.props.closeCallback()}
                            >
                                OK
                            </Button>

                            <ProductQuantityEdit
                                cartQuantity={quantity}
                                quantityAvailable={product.available}
                                isFull={isFull}
                                bumpUp={this.bumpUp}
                                bumpDown={this.bumpDown}
                                isZoom={true}
                            />
                        </Modal.Actions>
                    </Fragment>
                ) : (
                    ''
                )}
            </Modal>
        )
    }
}

ProductZoom.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    cart: PropTypes.object.isRequired,
    product: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    closeCallback: PropTypes.func.isRequired,
    isFull: PropTypes.bool,
}

export default ProductZoom
