import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'

export const mapStateToProps = (
    { categories, families, producers, producersList = [], session },
    ownProps
) => {
    const productTree = Object.values(categories).map(c => ({
        ...c,
        families: c.families
            .map(f => families[f])
            .slice(0)
            .sort(sortByProps(['name'])),
    }))

    const producerSection = {
        _id: 'producteurs',
        name: 'producteurs',
        families: producersList.map(_id => ({
            _id,
            name: producers[_id].name,
        })),
    }

    productTree.push(producerSection)

    return {
        family: session.family,
        category: session.category,
        families,
        onMenuClick: ownProps.onMenuClick,
        categories: productTree,
    }
}
