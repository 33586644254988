import * as Types from '../../../actions/types'
import { wrapClientApiCall } from '../../../utils'
import { fetchUserInfo } from '../fetchUserInfo'

/**
 * login the user on the server
 * returns a token which is stored with currentUser - then we immediately fetch the details
 * of the user which are also stored
 * @param {string} email - user's email
 * @param {string} password - user's password
 * @returns {string} - orderreservation_id (if exists)
 */

export const login = ({ email, password }) => {
    return wrapClientApiCall({
        actionRoot: 'LOGIN',
        url: `login`,
        hasJsonResponse: true,
        method: 'POST',
        body: { email, password },
        successCallback({ dispatch, data: { token, orderreservation_id } }) {
            if (token) {
                // NOTE must return here ! or else the promise chain is broken...
                return dispatch(fetchUserInfo(token)).then(() => {
                    dispatch({
                        type: Types.LOGIN_SUCCESS,
                        token,
                        orderreservation_id,
                    })
                    return token
                })
            }
            else {
                return false
            }
        },
    })
}
