import { wrapClientApiCall } from '../../../utils'
import { successCallback } from './successCallback'

/**
 * fetch all active locales, all active globalorders and all "next" globalorders (ie globalorders
 * whose previous globalorder is in the active globalorders list)
 * the user is now able to choose his locale, and then we can immediately know globalorder options
 * for the locale, and if no globalorder is open, we can use next globalorder to inform the
 * user about when the following globalorder will open
 * @returns {function} - action function to fetch locales etc from the server
 */

export const fetchLocales = consumer_id =>
    wrapClientApiCall({
        actionRoot: 'FETCH_LOCALES',
        url: `locales?consumer_id=${consumer_id}`,
        successCallback,
        token: 'token',
    })
