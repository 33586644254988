const weekdays = 'Dimanche,Lundi,Mardi,Mercredi,Jeudi,Vendredi,Samedi'.split(
    ','
)

const months =
    'Janvier,Février,Mars,Avril,Mai,Juin,Juillet,Aout,Septembre,Octobre,Novembre,Décembre'.split(
        ','
    )

const formatDate = function (dateString) {
    const jour = new Date(dateString)

    return `${weekdays[jour.getDay()]} ${jour.getDate()} ${months[jour.getMonth()]
        }`
}

export const daysOfDistributionByLocation = ({
    locales,
    activeGlobalOrders,
}) => {
    if (!activeGlobalOrders) {
        return locales
    }

    // compile all possible dates
    const globalOrders = [...activeGlobalOrders]
    globalOrders.sort((a, b) => {
        if (a.distribution_date > b.distribution_date) {
            return 1
        }
        if (a.distribution_date < b.distribution_date) {
            return -1
        }
        return 0
    })

    // pour chaque locale
    return Object.keys(locales).map(localCode => {
        const locale = locales[localCode]

        locale.schedules = []

        // group distributions by day
        locale.dailyDistributions = {}
        locale.distributions.forEach(d => {
            if (!locale.dailyDistributions[d.day]) {
                locale.dailyDistributions[d.day] = {
                    day: d.day,
                    time: '',
                }
            }
            locale.dailyDistributions[d.day].time += d.start
                .replace('00', '')
                .replace(':', 'h')
            locale.dailyDistributions[d.day].time += '-'
            locale.dailyDistributions[d.day].time += d.end
                .replace('00', '')
                .replace(':', 'h')
            locale.dailyDistributions[d.day].time += ' '
        })

        // pour chaque créneau de distribution
        for (const globalOrder of globalOrders) {
            const distributionDays = Object.values(locale.dailyDistributions)

            for (const distributionDay of distributionDays) {
                if (
                    new Date(globalOrder.distribution_date).getDay() ===
                    Number(distributionDay.day) &&
                    // FIXME:
                    globalOrder.locales?.includes(locale.code)
                ) {
                    const time = distributionDay.time
                    const day = formatDate(globalOrder.distribution_date)

                    locale.schedules.push({
                        globalOrder,
                        day,
                        time,
                    })
                }
            }
        }

        return locale
    })
}
