/* istanbul ignore file */

import React, { Component } from 'react'
import { Header, Grid, Container, Image, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { createReferral, clearError } from '../../../../actions'

import ErrorBoundary from '../../../layout/ErrorBoundary'
import InfoModal from '../../../ui/InfoModal'

const formatErrorText = text => {
    if (text === 'Invalid email/password') {
        return 'Paramètres de connexion non valides'
    }
    if (text === 'Consumer not found') {
        return 'Compte non-trouvé'
    }
    return 'Un erreur est survenu'
}

export class ReferralPage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
        this.handleSuccessDismiss = this.handleSuccessDismiss.bind(this)
        this.state = {
            loading: false,
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, error: false })
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    handleSuccessDismiss() {
        this.setState({
            success: false,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleSubmit(event) {
        const { email } = this.state

        this.setState({ loading: true })
        this.props
            .createReferral({
                email,
            })
            .then(() => this.setState({ success: true }))
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        const { success } = this.state
        const { serverError } = this.props

        return (
            <div
                style={{
                    maxWidth: '100%',
                    textAlign: 'center',
                    paddingTop: '2em',
                    margin: '2em',
                }}
            >
                <InfoModal
                    show={!!serverError}
                    icon="info circle"
                    header=""
                    body={serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />

                <InfoModal
                    show={!!success}
                    body="Merci pour votre aide !"
                    onClose={this.handleSuccessDismiss}
                    isSuccess={true}
                />

                <Grid
                    verticalAlign="middle"
                    columns={4}
                    centered
                >
                    <Grid.Row>
                        <Container textAlign="center">
                            <Image
                                src="https://cdn.kelbongoo.com/images/group1.svg"
                                style={{ width: '250px', margin: '0 auto' }}
                            />
                        </Container>

                        <Header as="h2">
                            Recommander Kelbongoo &agrave; votre entourage !
                            {/* <Header.Subheader>
                ...et r&eacute;cup&eacute;rer des petites surprises pour vous :)
              </Header.Subheader> */}
                        </Header>

                        {/* <Container text style={{fontSize: '1em', marginTop: '2em'}}>
              Entrez l'adresse mail de la personne que vous souhaitez recommander, et elle va recevoir direct un mail de notre part avec des infos sur Kelbongoo.
            </Container> */}
                    </Grid.Row>

                    <Grid.Row>
                        <Message
                            icon="cog"
                            header="En cours de construction..."
                            content=""
                        />
                        {/*  <Form 
              onSubmit={this.handleSubmit} 
              style={{marginBottom: '50px', width: '600px'}}
            >
              <Form.Input 
                label='Email' 
                placeholder='contact@email.com' 
                name="email" 
                value={this.state.email}
                required 
                error={false}
                autoComplete="off"
                // error={this.state.error} 
                onChange={this.handleChange} />

              <Form.Button icon primary fluid loading={loading ? true : false} size="large">
                Valider
              </Form.Button>

            </Form>
            */}
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

const AccountPageReferral = ({
    currentUser,
    createReferral,
    serverError,
    clearServerError,
}) => (
    <ErrorBoundary page="account-page-referral">
        <ReferralPage
            currentUser={currentUser}
            createReferral={createReferral}
            serverError={serverError}
            clearServerError={clearServerError}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, session }) => {
    return {
        serverError: !!session.error && formatErrorText(session.error),
        currentUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createReferral: params => dispatch(createReferral(params)),
        clearServerError: () => dispatch(clearError('CURRENT_USER')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageReferral)
