import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'

import AB from '../../../../assets/bio.png'

export const ProductBioLabel = ({ bioClass }) => (
    <div
        title="product-bio"
    >
        <Image
            className={bioClass}
            src={AB}
            alt="bio logo"
        />
    </div>
)

ProductBioLabel.propTypes = {
    bioClass: PropTypes.string.isRequired,
}
