import { Image, Button, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Styles from './styles.module.css'

export const ProductsHeader = ({ productsHeader, currentUser }) => (
    // New header format - to be modified with VWO
    <div className={Styles.contentRight}>
        <Grid stackable columns={2}>
            <Grid.Column mobile={16} tablet={16} computer={4} className={Styles.imgContainer}>
                <Image
                    src={productsHeader && productsHeader.headerImage}
                    className={Styles.contentRightMainImage}
                    alt="nouveautés"
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={9} className={Styles.textContainer}>
                <h3 className={Styles.headerTitle}>{productsHeader && productsHeader.headerTitle}</h3>
                <div className={Styles.headerSubTitle} dangerouslySetInnerHTML={{ __html: productsHeader && productsHeader.headerSubtitle }} />
                <div dangerouslySetInnerHTML={{ __html: productsHeader && productsHeader.headerText }} />
                {!currentUser.loggedin && (
                    <div className={Styles.signupButton}>
                        <Link to="/creer-un-compte">
                            <Button
                                style={{ marginTop: '10px' }}
                                secondary
                                size="small"
                            >
                                Je veux m'inscrire chez Kelbongoo !
                            </Button>
                        </Link>
                    </div>
                )}
            </Grid.Column>
        </Grid>
    </div>
)
