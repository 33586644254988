import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

const LinkButton = props => {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        className,
        ...rest
    } = props

    return (
        <Button
            className={`${Styles.Button} ${className}`}
            basic
            onClick={event => {
                onClick && onClick(event)
                to && history.push(to)
            }}
            {...rest} // `children` is just another prop!
        />
    )
}

LinkButton.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node,
}

export default withRouter(LinkButton)
