import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

export const SubscribeButton = () => (
    <div className={Styles.InscriptionContainer}>
        <Link to="/creer-un-compte">
            <Button
                primary
                fluid
                size="large"
            >
                Je m'inscris
            </Button>
        </Link>
    </div>
)
