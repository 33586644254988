import { wrapClientApiCall } from '../../../utils'
import { prerequestHook } from './prerequestHook'
import { failureCallback } from './failureCallback'
import { successCallback } from './successCallback'

// we call sync order to push the local cart to the server.
// the server returns a new orderreservation_id and expirationDate

export const syncOrder = (currentDate, mode) =>
    wrapClientApiCall({
        actionRoot: 'ORDER_SYNC',
        method: 'POST',
        url: `order_reservation`,
        hasJsonResponse: true,
        prerequestHook,
        failureCallback,
        successCallback: successCallback(currentDate, mode),
    })
