import React from 'react'
import { Table } from 'semantic-ui-react'

import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'

const DefaultRowComponent = ({ data, columns }) => (
    <Table.Row>
        {columns.map(col => {
            const style = {}
            let display = data[col.name]
            if (col.width) {
                style.width = col.width
            }
            if (col.align) {
                style.textAlign = col.align
            }
            if (col.formatMoney) {
                display = formatMoney.run(display, true)
            }
            if (col.formatDate) {
                display = formatDate.run(display, col.formatDate)
            }

            let subs =
                col.subs && col.subs.length > 0
                    ? col.subs.map(sub => (
                          <div
                              style={{
                                  color: '#999',
                                  fontStyle: 'italic',
                                  fontSize: '0.9em',
                              }}
                          >
                              {data[sub]}
                          </div>
                      ))
                    : ''

            return col.href && typeof col.href === 'function' ? (
                <Table.Cell
                    key={col.name}
                    style={style}
                    link={typeof col.href === 'function'}
                >
                    <a href={col.href(data)}>
                        {display}
                        {subs}
                    </a>
                </Table.Cell>
            ) : (
                <Table.Cell
                    key={col.name}
                    style={style}
                >
                    {display}
                    {subs}
                </Table.Cell>
            )
        })}
    </Table.Row>
)

export default DefaultRowComponent
