import * as Types from '../actions/types'
import { persist } from './persist'

const currentUser = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist
            return {
                ...state,
                ...(action.payload &&
                    action.payload.token && {
                    token: action.payload.token,
                    loggedin: true,
                }),
                ...(action.payload &&
                    action.payload.livdom && {
                    livdom: action.payload.livdom,
                }),
            }

        case Types.CREATE_CONSUMER_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.CREATE_CONSUMER_SUCCESS:
            return {
                ...state,
                token: action.token,
                loading: false,
            }

        case Types.CREATE_CONSUMER_FAIL:
            return {
                ...state,
                error: action.message,
                loading: false,
            }

        case Types.CLEAR_CURRENT_USER_ERROR:
            return {
                ...state,
                error: null,
            }

        case Types.STORE_LIVDOM_DETAILS:
            return {
                ...state,
                livdom: action.livdom,
            }

        case Types.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                email_verified: true,
                token: action.token,
                orderreservation_id: action.orderreservation_id,
                loggedin: true,
            }

        case Types.RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error || action.message,
            }

        case Types.FETCH_USER_INFO_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.FETCH_USER_INFO_SUCCESS:
            const {
                _id,
                firstname,
                lastname,
                email,
                pro,
                send_closing_email,
                send_launch_email,
                default_locale,
                telephone,
                countryCode,
                address_street,
                address_city,
                address_zip,
                address_code,
                door,
                address_instructions,
                has_valid_delivery_address,
                show_feature_carts,
                show_feature_close_account,
                show_feature_parainnage,
                suppress_cart_quantity_limit,
            } = action

            return {
                ...state,
                loading: false,
                _id,
                firstname,
                lastname,
                email,
                pro,
                send_closing_email,
                send_launch_email,
                default_locale,
                telephone,
                countryCode,
                address_street,
                address_city,
                address_zip,
                address_code,
                door,
                address_instructions,
                has_valid_delivery_address,
                show_feature_carts,
                show_feature_close_account,
                show_feature_parainnage,
                suppress_cart_quantity_limit,
            }

        case Types.FETCH_USER_INFO_FAIL:
            return {
                ...state,
                email: undefined,
                default_locale: undefined,
                token: undefined,
                loading: false,
            }

        case Types.LOGIN_REQUEST:
            return {
                loggedin: false,
                loading: true,
                token: null,
            }

        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                loggedin: true,
                loading: false,
                token: action.token,
            }

        case Types.LOGIN_FAIL:
            return {
                ...state,
                loggedin: false,
                loading: false,
                error: action.message,
            }

        case Types.LOGOUT_SUCCESS:
            return { loggedin: false }

        case Types.TOKEN_EXPIRED:
            return {
                ...state,
                loggedin: false,
                token: null,
            }

        case Types.SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_FAIL:
            return {
                ...state,
                error: action.error,
            }

        case Types.SEND_UPDATE_CONSUMER_EMAIL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                error: null,
            }

        case Types.UPDATE_CONSUMER_EMAIL_FAIL:
            return {
                ...state,
                error: action.message,
            }

        case Types.UPDATE_CONSUMER_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.email,
                error: null,
            }

        case Types.UPDATE_CONSUMER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.UPDATE_CONSUMER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.params,
            }

        case Types.UPDATE_CONSUMER_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case Types.FETCH_CURRENT_CONSUMER_CREDIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.FETCH_CURRENT_CONSUMER_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                current_credit: action.current_credit,
                welcome_credit: action.welcome_credit,
            }

        case Types.FETCH_CURRENT_CONSUMER_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case Types.CONSUMER_HAS_FAILED_PAYMENT_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                failedPaymentOrderWarningSeen: false,
            }

        case Types.CONSUMER_HAS_FAILED_PAYMENT_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFailedPaymentOrder: action.hasFailedPaymentOrder,
            }

        case Types.CONSUMER_HAS_FAILED_PAYMENT_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
                failedPaymentOrderWarningSeen: false,
            }

        case Types.HANDLE_FAILED_PAYMENT_ORDER_WARNING_SEEN:
            return {
                ...state,
                loading: false,
                error: null,
                hasFailedPaymentOrder: false,
                failedPaymentOrderWarningSeen: true,
            }

        case Types.CLOSE_CONSUMER_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.CLOSE_CONSUMER_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case Types.CLOSE_CONSUMER_ACCOUNT_SUCCESS:
            return {
                loggedin: false,
            }

        case Types.FETCH_CONSUMER_PAYMENT_TOKENS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.FETCH_CONSUMER_PAYMENT_TOKENS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case Types.FETCH_CONSUMER_PAYMENT_TOKENS_SUCCESS:
            return {
                ...state,
                loading: false,
                payment_tokens: action.tokens,
            }

        case Types.DELETE_CONSUMER_PAYMENT_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case Types.DELETE_CONSUMER_PAYMENT_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case Types.DELETE_CONSUMER_PAYMENT_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                payment_tokens: action.tokens,
            }

        default:
            return state
    }
}

export default persist(
    'currentUser',
    [
        'token',
        'firstname',
        'lastname',
        'email',
        'default_locale',
        'has_valid_delivery_address',
        'address_zip',
        'pro',
        '_id',
        'show_feature_carts',
        'show_feature_close_account',
        'show_feature_parainnage',
        'suppress_cart_quantity_limit',
        'hasFailedPaymentOrder',
        'livdom'
    ],
    currentUser
)
