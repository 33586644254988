import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

export const AvailableAddButton = ({ isZoom, bumpUp, buttonClassName }) => {
    return isZoom ? (
        <Button
            onClick={bumpUp}
            primary
            size="huge"
            className={buttonClassName}
        >
            Ajouter
        </Button>
    ) : (
        <Fragment>
            <Responsive maxWidth={MAX_WIDTH.XS}>
                <Button
                    onClick={bumpUp}
                    primary
                    size="medium"
                    className={buttonClassName}
                    title="more"
                    icon
                >
                    <Icon name="plus" />
                </Button>
            </Responsive>

            <Responsive
                minWidth={MIN_WIDTH.SM}
                maxWidth={MAX_WIDTH.MD}
            >
                <Button
                    onClick={bumpUp}
                    primary
                    size="mini"
                    className={buttonClassName}
                    title="more"
                >
                    Ajouter
                </Button>
            </Responsive>

            <Responsive minWidth={MIN_WIDTH.LG}>
                <Button
                    onClick={bumpUp}
                    primary
                    size="medium"
                    className={buttonClassName}
                    title="more"
                >
                    Ajouter
                </Button>
            </Responsive>
        </Fragment>
    )
}
