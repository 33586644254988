import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Icon, Header, Segment, Dimmer, Loader, Image } from 'semantic-ui-react'

import { updateConsumerEmail } from '../../../actions'
import { addImgPrefix } from '../../../utils'

class UpdateEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            loading: true,
        }
    }

    UNSAFE_componentWillMount() {
        const tmp =
            this.props.location.search && this.props.location.search.split('=')

        if (!tmp || !tmp.length > 1) {
            return this.setState({
                hasError: true,
                loading: false,
            })
        }

        const update_email_token = tmp[1]

        return this.props
            .dispatch(updateConsumerEmail(update_email_token))
            .catch(err => this.setState({ hasError: true }))
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        if (this.state.loading) {
            return (
                <Segment>
                    <Dimmer
                        active
                        inverted
                        style={{ height: '300px' }}
                    >
                        <Loader
                            inverted
                            content="Chargement"
                        />
                    </Dimmer>
                </Segment>
            )
        }

        if (!this.state.loading && this.state.hasError) {
            return <Redirect to="/500" />
        }

        return (
            <Fragment>
                <div style={{ margin: '50px 0 50px 0' }}>
                    <Image
                        size="medium"
                        src={addImgPrefix('flyingapple.svg')}
                        alt="Logo pomme volante"
                        centered
                    />
                </div>

                <div>
                    <Header
                        as="h3"
                        textAlign="center"
                        icon
                    >
                        <Icon name="thumbs up" />
                        C'est parti !
                        <Header.Subheader>
                            Votre nouvelle adresse mail a bien &eacute;t&eacute;
                            enregistr&eacute;e.
                        </Header.Subheader>
                    </Header>
                </div>
            </Fragment>
        )
    }
}

export default connect()(UpdateEmail)
