import * as Types from '../../types'
import { daysOfDistributionByLocation } from '../../../utils/daysOfDistributionByLocation'

export const successCallback = ({
    dispatch,
    data: { locales = [], activeGlobalOrders = [], nextGlobalOrders = [] },
}) => {
    locales =
        daysOfDistributionByLocation({
            locales,
            activeGlobalOrders,
            nextGlobalOrders,
        }) || []

    dispatch({
        type: Types.FETCH_LOCALES_SUCCESS,
    })

    let localesDict = {}
    locales.forEach(i => (localesDict[i.code] = i))

    dispatch({
        type: Types.LOCALES_UPDATE,
        dict: localesDict,
    })

    dispatch({
        type: Types.LOCALES_LIST_UPDATE,
        list: Object.keys(localesDict),
    })

    dispatch({
        type: Types.ACTIVE_GLOBALORDERS_LIST_UPDATE,
        activeGlobalOrders,
    })

    dispatch({
        type: Types.NEXT_GLOBALORDERS_LIST_UPDATE,
        nextGlobalOrders,
    })

    dispatch({
        type: Types.FETCH_LOCALES_READY,
    })

    return activeGlobalOrders
}
