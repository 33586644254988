import React, {FunctionComponent} from 'react'
import { Table, Icon } from 'semantic-ui-react'
import Responsive  from "../../../../../ui/Responsive"
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

type LivdomFeeProps = {
    amount: number,
    type: 'livdom-fee'
}

const labelsFrom: Record<LivdomFeeProps['type'], string> = {
    "livdom-fee": "Frais de livraison à domicile",
}

type LivdomFeeLineProps = {
    type: LivdomFeeProps["type"]
}

const LivdomFeeLine: FunctionComponent<LivdomFeeLineProps> = ({type}) => (
    <div style={{ float: 'left' }}>
        {labelsFrom[type]}
    </div>
)

export const LivdomFee = ({ amount, type }: LivdomFeeProps) => (
    <Table.Row key="frais-de-livraison" data-testid="frais-de-livraison" className="warning">
        <Table.Cell textAlign="center">
            <Icon name="truck"/>
        </Table.Cell>

        <Responsive as={Table.Cell} maxWidth={MAX_WIDTH.SM} singleLine>
            <LivdomFeeLine type={type}/>
        </Responsive>
        <Responsive as={Table.Cell} minWidth={MIN_WIDTH.MD} singleLine colSpan={3}>
            <LivdomFeeLine type={type}/>
        </Responsive>

        <Table.Cell textAlign="center" singleLine>
            <strong>{formatCurrency(amount)}</strong>
        </Table.Cell>
    </Table.Row>
)
