export const formatErrorText = text => {
    if (text === 'Email already exists') {
        return 'Un autre compte avec cette adresse mail existe déjà !'
    }
    if (text === 'Consumer not found') {
        return 'Consommateur non-trouvé'
    }
    // if(!has_valid_delivery_address){
    //   return 'Adresse invalide';
    // }
}
