import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
import LazyLoad from 'react-lazyload'

import { addImgPrefix } from '../../../../../utils'

import nophoto from '../../../../../assets/panier2.svg'
import Styles from './styles.module.css'

export const ProductImage = ({ toggleZoom, product }) => (
    <div
        className={Styles.ImageContainer}
        onClick={() => toggleZoom()}
        data-testid="product-image-container"
    >
        {product.src ? (
            <LazyLoad offset={3000} style={{ height: "100%" }}>
                <Image
                    src={addImgPrefix(product.src, 'thumbnail')}
                    title="product-image"
                    className={Styles.Image}
                    alt={product.product_name}
                />
            </LazyLoad>
        ) : (
            <Image
                src={nophoto}
                className={Styles.Image}
                alt="Photo absente"
            />
        )}
    </div>
)

ProductImage.propTypes = {
    toggleZoom: PropTypes.func.isRequired,
    product: PropTypes.object,
}
