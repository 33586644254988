import React from 'react'
import { Table, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import buildTableHeader from './buildTableHeader'
import DefaultRowComponent from './DefaultRowComponent'

const EmptyRow = ({ message }) => (
    <Table.Row>
        <Table.Cell
            colSpan={1000}
            style={{ textAlign: 'center', padding: '0.3em' }}
        >
            <i style={{ color: '#999' }}>{message}</i>
        </Table.Cell>
    </Table.Row>
)

const DoneRow = ({ message }) => (
    <Table.Row>
        <Table.Cell
            colSpan={1000}
            style={{ textAlign: 'center', padding: '0.3em' }}
        >
            <Icon
                name="checkmark"
                color="green"
            />{' '}
            {message}
        </Table.Cell>
    </Table.Row>
)

const GenericTable = ({
    RowComponent,
    header,
    items,
    emptyMessage,
    done,
    doneMessage,
}) => {
    if (!header) {
        throw new Error('header data is required')
    }
    if (!items) {
        throw new Error('items list is required')
    }

    const ListRowComponent = RowComponent || DefaultRowComponent
    const Header = buildTableHeader(header)
    let TableBody

    if (done) {
        TableBody = <DoneRow message={doneMessage || 'Fini !'} />
    } else if (items.length === 0) {
        TableBody = <EmptyRow message={emptyMessage || 'Rien à afficher'} />
    } else {
        TableBody = items.map((item, rowInd) => (
            <ListRowComponent
                key={rowInd}
                data={item}
                columns={header.columns}
            />
        ))
    }

    return (
        <Table
            celled
            unstackable
        >
            {Header}
            <Table.Body>{TableBody}</Table.Body>
        </Table>
    )
}

GenericTable.propTypes = {
    RowComponent: PropTypes.func,
    header: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    emptyMessage: PropTypes.string,
    done: PropTypes.bool,
    doneMessage: PropTypes.string,
}

export default GenericTable
