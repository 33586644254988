import PropTypes from 'prop-types'
import Styles from './styles.module.css'
import { Image } from 'semantic-ui-react'

// Importing category logos
import boisson_fonce from '../../../../../assets/boisson_fonce.svg'
import pain_fonce from '../../../../../assets/boulangerie_fonce.svg'
import épicerie_fonce from '../../../../../assets/epicerie_fonce.svg'
import viande_fonce from '../../../../../assets/viande_fonce.svg'
import volaille_fonce from '../../../../../assets/volaille_fonce.svg'
import fruits_fonce from '../../../../../assets/fruits_legumes_fonce.svg'
import laitiers_fonce from '../../../../../assets/laitiers_fonce.svg'
import traiteur_fonce from '../../../../../assets/traiteur_fonce.svg'
import poisson_fonce from '../../../../../assets/poisson_fonce.svg'

import boisson_clair from '../../../../../assets/boisson_clair.svg'
import pain_clair from '../../../../../assets/boulangerie_clair.svg'
import épicerie_clair from '../../../../../assets/epicerie_clair.svg'
import viande_clair from '../../../../../assets/viande_clair.svg'
import volaille_clair from '../../../../../assets/volaille_clair.svg'
import fruits_clair from '../../../../../assets/fruits_legumes_clair.svg'
import laitiers_clair from '../../../../../assets/laitiers_clair.svg'
import traiteur_clair from '../../../../../assets/traiteur_clair.svg'
import poisson_clair from '../../../../../assets/poisson_clair.svg'

const imgsDark = { boisson_fonce, pain_fonce, épicerie_fonce, viande_fonce, volaille_fonce, fruits_fonce, laitiers_fonce, traiteur_fonce, poisson_fonce }
const imgsLight = { boisson_clair, pain_clair, épicerie_clair, viande_clair, volaille_clair, fruits_clair, laitiers_clair, traiteur_clair, poisson_clair }

export const CategoryItem = ({
    imgDark,
    imgLight,
    scrollerItem,
    handleClick,
    selectedCategory,
    selectedFamily
}) => {
    return (
        <div
            id={`${scrollerItem._id}-button`}
            className={
                typeof selectedCategory !== 'undefined'
                    &&
                    (scrollerItem._id === selectedCategory || scrollerItem._id === selectedFamily)
                    ?
                    Styles.SelectedScrollerItem
                    :
                    Styles.ScrollerItem
            }
            data-testid="category-item"
            onClick={() => {
                handleClick({
                    category: scrollerItem.family ? 'promotions' : scrollerItem._id,
                    family: scrollerItem.family ? scrollerItem._id : null,
                    selectedCategory: scrollerItem.family ? 'promotions' : scrollerItem._id,
                    selectedFamily: scrollerItem.family ? scrollerItem._id : null,
                })
            }
            }
        >
            {!imgDark.includes('undefined') && !imgLight.includes('undefined') &&
                <Image
                    src={(scrollerItem._id === selectedCategory || scrollerItem._id === selectedFamily) ?
                        imgsLight[imgLight]
                        :
                        imgsDark[imgDark]
                    }
                    alt=""
                    className={Styles.categoryLogo}
                />
            }
            <p className={Styles.categoryTitle}>{scrollerItem.name.toUpperCase()}</p>
        </div>
    )
}

CategoryItem.propTypes = {
    categoryItem: PropTypes.object,
    sessionCategory: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
}
