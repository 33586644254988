import { wrapClientApiCall } from '../../../utils'

export const fetchConsumerFeedback = (page = 1) =>
    wrapClientApiCall({
        actionRoot: 'FETCH_CONSUMER_FEEDBACK',
        url: `api/producerproductfeedback/fetch-for-consumer?page=${page}`,
        successPayload: data => ({
            feedback: data.items,
            count: data.count,
        }),
    })
