import React from 'react'
import { Link } from 'react-router-dom'
import { Step } from 'semantic-ui-react'

export const StepGroupSmall = () => (
    <Step.Group
        ordered
        size="mini"
        unstackable
    >
        <Step completed>
            <Step.Content>
                <Step.Title>
                    <Link to="/checkout">Confirmation</Link>
                </Step.Title>
                <Step.Description>
                    Valider vos produits / retrait
                </Step.Description>
            </Step.Content>
        </Step>
        <Step active>
            <Step.Content>
                <Step.Title>Règlement</Step.Title>
                <Step.Description>
                    Choisir votre mode de règlement
                </Step.Description>
            </Step.Content>
        </Step>
    </Step.Group>
)
