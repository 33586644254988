import React from 'react'
import { Segment, Grid, Header } from 'semantic-ui-react'
import  Responsive  from '../../../../../ui/Responsive'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'

export const ItemsListHeader = ({ articlesCount, total }) => {
    return (
        <Segment>
            <Grid columns={3}>
                <Grid.Column>
                    <Responsive maxWidth={MAX_WIDTH.XS}>
                        <Header as="h4">D&eacute;tail</Header>
                    </Responsive>
                    <Responsive minWidth={MIN_WIDTH.SM}>
                        <Header as="h3">D&eacute;tail</Header>
                    </Responsive>
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Responsive maxWidth={MAX_WIDTH.XS}>
                        <Header as="h4">{articlesCount}</Header>
                    </Responsive>
                    <Responsive minWidth={MIN_WIDTH.SM}>
                        <Header as="h3">{articlesCount}</Header>
                    </Responsive>
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Responsive maxWidth={MAX_WIDTH.XS}>
                        <Header as="h4">{formatMoney.run(total, true)}</Header>
                    </Responsive>
                    <Responsive minWidth={MIN_WIDTH.SM}>
                        <Header as="h3">{formatMoney.run(total, true)}</Header>
                    </Responsive>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}
