import React from 'react'
import { Feed, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import formatDate from '@kelbongoo/shared-methods/utils/formatDate'

export const formatEvent = event => {
    return event // this would be the case where all events follow the same format
}

export const Event = ({ eventData, ind }) => {
    let formattedEvent = formatEvent(eventData)
    return (
        <Feed.Event
            key={ind}
            data-testid="event-item"
        >
            <Feed.Label>
                <Icon name={formattedEvent.icon} />
            </Feed.Label>
            <Feed.Content>
                <Feed.Date>
                    {formatDate.run(formattedEvent.date, 'YYYY-MM-DD HH:mm')}
                </Feed.Date>
                <Feed.Summary>{formattedEvent.title}</Feed.Summary>
            </Feed.Content>
        </Feed.Event>
    )
}

export const EventsList = ({ events, style, size }) => {
    return (
        <Feed
            size={size}
            style={style}
        >
            {events.map((eventData, ind) => Event({ eventData, ind }))}
        </Feed>
    )
}

EventsList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object),
}
