import { wrapClientApiCall } from '../../../utils'

export const consumerHasFailedPaymentOrder = globalorder_id =>
    wrapClientApiCall({
        actionRoot: 'CONSUMER_HAS_FAILED_PAYMENT_ORDER',
        url:
            typeof globalorder_id !== undefined
                ? `api/consumers/has-failed-payment-order?globalorder_id=${globalorder_id}`
                : `api/consumers/has-failed-payment-order`,
        successPayload: data => ({
            hasFailedPaymentOrder: data.hasFailedPaymentOrder,
        }),
    })
