export const buildProducerData =
    ({ producersContent, cart, availableProducts, products }) =>
    p => {
        const producerContent =
            producersContent.content &&
            producersContent.content.find(
                pContent =>
                    pContent &&
                    pContent.producerid &&
                    pContent.producerid === p._id
            )

        return {
            ...p,
            image: producerContent && producerContent.image,
            chapeau: producerContent && producerContent.chapeau,
            products: p.products
                .map(pp => products[pp])
                .map(pp => ({
                    ...pp,
                    producer: p,
                    quantity: cart.items[pp.producerproduct_id] || 0,
                    available: availableProducts[pp.producerproduct_id] || 0,
                })),
        }
    }
