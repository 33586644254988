import React from 'react'
import { Table } from 'semantic-ui-react'
import Responsive  from "../../ui/Responsive"

const buildTableHeader = header => (
    <Table.Header>
        <Table.Row>
            {header.columns.map((data, ind) => {
                let style = {}
                if (data.width) style.width = `${data.width}px`
                if (data.align) style.textAlign = data.align
                if (data.hidden) {
                    return (
                        <Responsive
                            as={Table.HeaderCell}
                            minWidth={data.hidden}
                            onClick={data.onClick}
                            key={ind}
                            style={style}
                        >
                            {data.title}
                        </Responsive>
                    )
                }
                return (
                    <Table.HeaderCell
                        onClick={data.onClick}
                        key={ind}
                        style={style}
                    >
                        {data.Component || data.title}
                    </Table.HeaderCell>
                )
            })}
            {header.emptyCells &&
                [...Array(header.emptyCells).keys()].map((i, ind) => (
                    <Table.HeaderCell key={ind + 10} />
                ))}
        </Table.Row>
    </Table.Header>
)

export default buildTableHeader
