import * as Sentry from '@sentry/browser'
import { trackConnected } from './trackConnected'

export const trackEvent = ({ _id, event, data }) => {
    var _learnq = window._learnq || [] // eslint-disable-line

    trackConnected({ _id })

    try {
        _learnq.push(['track', event, data])
    } catch (err) {
        Sentry.captureException(
            new Error(
                'Klaviyo trackEvent failed - ' +
                    JSON.stringify({ currentUserId: _id, event, data, err })
            )
        )
    }
}
