export const prerequestHook =
    currentGlobalOrder =>
    ({ getState }) => {
        const {
            currentLocale: { code: localeCode },
        } = getState()

        return {
            url: `available?locale=${localeCode}${
                currentGlobalOrder
                    ? '&globalorder_id=' + currentGlobalOrder._id
                    : ''
            }`,
        }
    }
