import React from 'react'
import { Image } from 'semantic-ui-react'
import { addImgPrefix } from '../../../../../utils'

import nophoto from '../../../../../assets/panier2.svg'

export const ProductImage = ({ product }) => {
    return product.src ? (
        <Image
            size="medium"
            src={addImgPrefix(product.src, 'thumbnail')}
            wrapped
            alt={product.product_name}
        />
    ) : (
        <Image
            size="medium"
            src={nophoto}
            wrapped
            alt="Photo absente"
        />
    )
}
