import * as Types from '../actions/types'

const session = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.ORDER_CONTEXT_UPDATE:
            return {
                ...state,
                family: action.family,
                category: action.category,
                selectedCategory: action.selectedCategory,
                selectedFamily: action.selectedFamily
            }

        case Types.SIDE_MENU_VISIBILITY_UPDATE:
            return {
                ...state,
                sideMenuVisible: action.visible,
            }

        case Types.OPEN_ZOOM:
            return {
                ...state,
                zoomProduct: action.product,
            }

        case Types.CLOSE_ZOOM:
            return {
                ...state,
                zoomProduct: false,
            }

        case Types.SHOW_CART_EXPIRED_MESSAGE:
            return {
                ...state,
                currentCartExpirationMessage: 0,
            }

        case Types.SHOW_CART_EXPIRATION_ONE_MESSAGE:
            return {
                ...state,
                currentCartExpirationMessage: 1,
            }

        case Types.SHOW_CART_EXPIRATION_FIVE_MESSAGE:
            return {
                ...state,
                currentCartExpirationMessage: 5,
            }

        case Types.CLEAR_CURRENT_CART_EXPIRATION_MESSAGE:
            return {
                ...state,
                currentCartExpirationMessage: undefined,
            }

        case Types.UPDATE_CART_ITEM:
            return {
                ...state,
                currentCartExpirationMessage: undefined,
            }

        case Types.EMPTY_CART:
            return {
                ...state,
                currentCartExpirationMessage: undefined,
            }

        case Types.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                forgotPasswordError: action.message,
            }

        case Types.CLEAR_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordError: undefined,
            }

        case Types.HOME_DELIVERY_WARNING_SEEN:
            return {
                ...state,
                homedeliveryWarningSeen: true,
            }

        case Types.LOGOUT_SUCCESS:
            return {
                ...state,
                homedeliveryWarningSeen: undefined,
            }

        case Types.REFERRAL_SENT:
            return {
                ...state,
                referralSent: true,
            }

        default:
            return state
    }
}

export default session
