/* istanbul ignore file */

import * as Types from '../actions/types'

const currentFeedback = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        /* GET FEEDBACK DETAIL */

        case Types.FETCH_CONSUMER_FEEDBACK_DETAIL_REQUEST:
            return {
                loading: true,
                error: false,
                feedback: null,
            }
        case Types.FETCH_CONSUMER_FEEDBACK_DETAIL_FAIL:
            return {
                loading: false,
                error: action.error,
                feedback: null,
            }
        case Types.FETCH_CONSUMER_FEEDBACK_DETAIL_SUCCESS:
            return {
                loading: false,
                error: null,
                feedback: action.feedback,
            }
        case Types.CLEAR_FETCH_CONSUMER_FEEDBACK_DETAIL_ERROR:
            return {
                ...state,
                error: undefined,
            }

        /* CREATE FEEDBACK */

        case Types.CREATE_FEEDBACK_REQUEST:
            return {
                loading: true,
                error: false,
            }
        case Types.CREATE_FEEDBACK_FAIL:
            return {
                loading: false,
                error: action.error,
            }
        case Types.CREATE_FEEDBACK_SUCCESS:
            return {
                loading: false,
                error: null,
                feedback: action.feedback,
            }

        case Types.CLEAR_CREATE_FEEDBACK_ERROR:
            return {
                ...state,
                error: undefined,
            }

        default:
            return state
    }
}

export default currentFeedback
