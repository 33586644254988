import React, { Fragment } from 'react'
import EmptyFooter from '../../../../ui/EmptyFooter'

export const AccountPageCreditsEmpty = () => (
    <Fragment>
        <EmptyFooter
            text="Aucun crédit trouvé"
            icon="euro sign"
        />
        <div
            style={{
                color: '#999',
                fontStyle: 'italic',
                fontSize: '0.9em',
                textAlign: 'center',
            }}
        >
            <p>
                Pour toute question relative à votre commande, n'hésitez pas à{' '}
                <a
                    href="https://www.kelbongoo.com/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    visiter notre FAQ
                </a>{' '}
                ou à nous contacter :
            </p>
            <p>
                Pour la boutique Bichat :{' '}
                <a href="mailto:contact.bichat@kelbongoo.com">
                    contact.bichat@kelbongoo.com
                </a>
            </p>
            <p>
                Pour la boutique Saint-Blaise :{' '}
                <a href="mailto:contact.saintblaise@kelbongoo.com">
                    contact.saintblaise@kelbongoo.com
                </a>
            </p>
            <p>
                Pour la boutique Borrégo :{' '}
                <a href="mailto:contact.borrego@kelbongoo.com">
                    contact.borrego@kelbongoo.com
                </a>
            </p>
        </div>
    </Fragment>
)
