import { buildProductTreeProductsFilter } from './buildProductTreeProductsFilter'
import { buildProductTreeProductsMap } from './buildProductTreeProductsMap'

export const buildProductTreeProducts = ({
    availableProducts,
    cart,
    category,
    closed_tags,
    consumercarts,
    family,
    producers,
    products,
}) => {
    if (category._id === 'carts') {
        return consumercarts || []
    }

    return family.products
        .map(pp => products[pp])
        .filter(
            buildProductTreeProductsFilter({
                availableProducts,
                closed_tags,
            })
        )
        .map(
            buildProductTreeProductsMap({
                producers,
                cart,
                availableProducts,
            })
        )
}
