import { Icon } from 'semantic-ui-react'
import Styles from './styles.module.css'

const Paymentmethod = ({
    isHomeDelivery = false,
    config,
    handlePaymentTypeChange,
    paymentType,
    title,
}) => {
    return (
        <div
            data-testid={config.value}
            title={title}
            onClick={() => handlePaymentTypeChange(config.value)}
            id={config.value}
            className={`animate ${Styles.PaymentMethod} ${paymentType === config.value
                ? Styles.PaymentMethodSelected
                : Styles.PaymentMethodNotSelected
                }`}
        >
            {paymentType === config.value && (
                <div
                    data-testid="checked"
                    className={Styles.ContainerIconPaymentSelected}
                >
                    <Icon
                        name="check"
                        className={Styles.IconPaymentSelected}
                        size="small"
                    />
                </div>
            )}

            <div className={Styles.PaymentMethodHeader}>
                <div className={Styles.PaymentMethodTitle}>
                    {config.icon}
                    <div label="Règlement en ligne">{config.title}</div>
                </div>
                <p className={Styles.PaymentMethodBody}>{isHomeDelivery ? config.body.livdom : config.body.retrait}</p>
            </div>
            <p className={Styles.Notice}>{isHomeDelivery ? config.notice.livdom : config.notice.retrait}</p>
        </div>
    )
}

export default Paymentmethod
