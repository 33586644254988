import { wrapClientApiCall } from '../../../utils'

export const fetchCurrentConsumerCredit = (globalorder_id, orderreservation_id) => {
    let url = `api/consumercredits/get-current-consumer-credit`;
    if (globalorder_id && orderreservation_id) {
        const queryParams = new URLSearchParams({ globalorder_id, orderreservation_id });
        url += `?${queryParams.toString()}`
    }

    return wrapClientApiCall({
        actionRoot: 'FETCH_CURRENT_CONSUMER_CREDIT',
        url,
        successPayload: data => ({
            current_credit: data.currentConsumerCredit,
            welcome_credit: data.welcomeCredit,
        }),
    });
}
