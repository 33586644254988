import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
    Modal,
    Header,
    Image,
    Button,
    Grid,
} from 'semantic-ui-react'
import Responsive  from "../../../ui/Responsive"

import Navet from '../../../../assets/navet.svg'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../app-constants'

export class CallToActionModalView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // showModal: this.props.showModal,
            showModal: false,
        }
        this.onClose = this.onClose.bind(this)
    }

    static propTypes = {
        showModal: PropTypes.bool.isRequired,
    }

    onClose() {
        this.setState({ showModal: false })
    }

    render() {
        return (
            <Modal
                closeIcon={{ color: 'red', name: 'close' }}
                open={this.state.showModal}
                onClose={this.onClose}
                size="tiny"
                style={{ textAlign: 'center' }}
                data-testid="call-to-action-modal"
            >
                <Modal.Content
                    image
                    scrolling
                >
                    <Modal.Description>
                        <Header as="h2">Nouveau par ici ?</Header>
                        <Image
                            style={{ width: 'auto' }}
                            centered={true}
                            size="medium"
                            src={Navet}
                            wrapped
                        />
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Link to="/login">
                                    <Button
                                        icon
                                        labelPosition="right"
                                        basic
                                        fluid
                                    >
                                        <Responsive
                                            as="span"
                                            maxWidth={MAX_WIDTH.XS}
                                            style={{ fontSize: '0.85em' }}
                                        >
                                            Je me connecte
                                        </Responsive>
                                        <Responsive
                                            as="span"
                                            minWidth={MIN_WIDTH.SM}
                                        >
                                            Je me connecte
                                        </Responsive>
                                    </Button>
                                </Link>
                            </Grid.Column>

                            <Grid.Column>
                                <Link to="/creer-un-compte">
                                    <Button
                                        icon
                                        labelPosition="right"
                                        primary
                                        fluid
                                    >
                                        <Responsive
                                            as="span"
                                            maxWidth={MAX_WIDTH.XS}
                                            style={{ fontSize: '0.85em' }}
                                        >
                                            Je m'inscris
                                        </Responsive>
                                        <Responsive
                                            as="span"
                                            minWidth={MIN_WIDTH.SM}
                                        >
                                            Je m'inscris
                                        </Responsive>
                                    </Button>
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Actions>
            </Modal>
        )
    }
}
