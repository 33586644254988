import React from 'react'
import { Card } from 'semantic-ui-react'
import Styles from './styles.module.css'

import { AvailableAddButton } from './AvailableAddButton'
import { UnavailableButton } from './UnavailableButton'
import { AvailableAddRemoveButton } from './AvailableAddRemoveButton'

export const ProductQuantityEdit = ({
    bumpUp,
    bumpDown,
    quantityAvailable,
    cartQuantity,
    isFull,
    isZoom,
}) => (
    <Card.Content
        className={!isZoom ? Styles.QuantityEdit : ''}
        title="product-quantity"
    >
        {cartQuantity === 0 ? (
            quantityAvailable > 0 && !isFull ? (
                <AvailableAddButton
                    isZoom={isZoom}
                    bumpUp={bumpUp}
                    buttonClassName={!isZoom ? Styles.QuantityEditButton : ''}
                />
            ) : (
                <UnavailableButton
                    isZoom={isZoom}
                    buttonClassName={!isZoom ? Styles.QuantityEditButton : ''}
                />
            )
        ) : (
            <AvailableAddRemoveButton
                bumpUp={bumpUp}
                bumpDown={bumpDown}
                buttonClassName={!isZoom ? Styles.QuantityEditButton : ''}
                buttonOrClassName={!isZoom ? Styles.ButtonOr : ''}
                cartQuantity={cartQuantity}
                quantityAvailable={quantityAvailable}
                isZoom={isZoom}
            />
        )}
    </Card.Content>
)
