import React from 'react'
import { Redirect } from 'react-router-dom'
import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import { OrderLoadingView } from './OrderLoadingView'
import AllLocalesFullView from './AllLocalesFullView'

export const handleAllLocalesFull = function () {
    let locale = this.props.locale

    if (!locale) {
        return <Redirect to="/choisir-un-magasin" />
    }

    if (!this.props.localesInitialized) {
        return <OrderLoadingView />
    }

    const nextGlobalOrder = this.props.nextGlobalOrders.find(g =>
        g.locales.includes(locale.code)
    )

    const nextGlobalOrderStartDays =
        nextGlobalOrder &&
        nextGlobalOrder.order_start &&
        formatDate.run(
            new Date(nextGlobalOrder.order_start.replace(/-/g, '/')),
            'dddd DD MMMM'
        )

    const nextGlobalOrderStartHours =
        nextGlobalOrder &&
        nextGlobalOrder.order_start &&
        formatDate.run(
            new Date(nextGlobalOrder.order_start.replace(/-/g, '/')),
            'HH:mm'
        )

    return (
        <AllLocalesFullView
            currentUser={this.props.currentUser}
            prochaineVenteStartDays={nextGlobalOrderStartDays}
            prochaineVenteStartHours={nextGlobalOrderStartHours}
        />
    )
}
