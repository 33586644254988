import React from 'react'
import PropTypes from 'prop-types'
import { Header, Divider } from 'semantic-ui-react'
import { FamilyView } from './FamilyView'

export const CategoryView = ({
    category,
    onPassNewSection,
    onChangeValue,
    selectedCategory,
    onOpenZoom,
    isFull,
}) => (
    <div
        key={category._id}
        id={`key-${category._id}`}
    >
        <Divider />

        <Header
            as="h2"
            color="green"
        >
            {category.name && category.name.toUpperCase()}
            {category.subtitle && (
                <Header.Subheader style={{ fontStyle: 'italic' }}>
                    {category.subtitle}
                </Header.Subheader>
            )}
        </Header>

        {category.families.map((family, index) => (
            <FamilyView
                key={family._id}
                category={category}
                family={family}
                onPassNewSection={onPassNewSection}
                selectedCategory={selectedCategory}
                onChangeValue={onChangeValue}
                onOpenZoom={onOpenZoom}
                isFull={isFull}
                index={index}
            />
        ))}
        <br />
    </div>
)

CategoryView.propTypes = {
    category: PropTypes.object,
    onPassNewSection: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onOpenZoom: PropTypes.func.isRequired,
    isFull: PropTypes.bool,
}
