import React from 'react'
import { Table } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

export const AddedChargeItem = ({ item }) => (
    <Table.Row key="added_charge">
        <Table.Cell
            width={16}
            colSpan={2}
        >
            {item.title}
        </Table.Cell>

        <Table.Cell
            width={6}
            textAlign="right"
        >
            {formatMoney.run(item.amount, true)}
        </Table.Cell>
    </Table.Row>
)
