import { wrapClientApiCall } from '../../../utils'

export const retryOnlinePayment = ({ order_id, paymentToken }) => {
    return wrapClientApiCall({
        actionRoot: 'RETRY_ONLINE_PAYMENT',
        url: `api/retry-online-payment`,
        method: 'POST',
        body: { order_id, paymentToken },
        hasJsonResponse: true,
    })
}
