import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { Form, Icon, Header, Message } from 'semantic-ui-react'

import InfoModal from '../../ui/InfoModal'
import { resetPassword, clearError } from '../../../actions'
import ErrorBoundary from '../../layout/ErrorBoundary'
import { handleSubmit } from './handleSubmit'

import Styles from './styles.module.css'

export class ResetPasswordBase extends Component {
    constructor(props) {
        super(props)

        this.state = { error: false, serverError: false }

        this.handleSubmit = handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, error: false })
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    render() {
        const { currentUser, serverError } = this.props

        if (currentUser.loggedin && currentUser.orderreservation_id) {
            return <Redirect to="/checkout" />
        }

        const isCreation = this.props.location.search.indexOf('creation') > -1

        return (
            <Fragment>
                <InfoModal
                    show={!!serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />
                <div className={Styles.Container}>
                    <Form onSubmit={this.handleSubmit}>
                        <Header
                            as="h3"
                            textAlign="center"
                            icon
                            data-testid="text-header"
                        >
                            <Icon name="user plus" />
                            {isCreation ? 'Créer un' : 'Changez mon'} mot de
                            passe
                            <Header.Subheader>
                                Merci de renseigner votre{' '}
                                {isCreation ? '' : 'nouveau'} mot de passe dans
                                les deux champs prévus à cet effet
                            </Header.Subheader>
                        </Header>

                        <Form.Input
                            label={`${
                                isCreation ? 'Mot' : 'Nouveau mot'
                            } de passe`}
                            placeholder="mot de passe"
                            id="mot_de_passe"
                            name="mot_de_passe"
                            required
                            type="password"
                            onChange={this.handleChange}
                            error={this.state.error}
                        />

                        <Form.Input
                            label="Confirmation du mot de passe"
                            placeholder="encore mot de passe"
                            id="confirmation_mot_de_passe"
                            name="confirmation_mot_de_passe"
                            required
                            type="password"
                            onChange={this.handleChange}
                            error={this.state.error}
                        />

                        {this.state.error ? (
                            <Message negative>
                                Les mots de passe doivent concorder!
                            </Message>
                        ) : (
                            ''
                        )}

                        <Form.Button
                            icon
                            primary
                            fluid
                            labelPosition="right"
                            loading={
                                currentUser && currentUser.loading
                                    ? true
                                    : false
                            }
                            size="huge"
                        >
                            Valider ce mot de passe
                            <Icon name="right arrow" />
                        </Form.Button>
                    </Form>
                </div>
            </Fragment>
        )
    }
}

const ResetPassword = props => (
    <ErrorBoundary page="reset-password">
        <ResetPasswordBase {...props} />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser }) => ({
    currentUser,
    serverError: !!currentUser.error,
})

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: payload => dispatch(resetPassword(payload)),
        clearServerError: () => dispatch(clearError('CURRENT_USER')),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
)
