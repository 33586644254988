import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'
import { buildProductTreeProducts } from './buildProductTreeProducts'

export const buildProductTree = ({
    availableProducts,
    cart,
    categories,
    closed_tags,
    clicked = false,
    consumercarts,
    families,
    selectedFamily,
    initRequest,
    producers,
    products,
}) => {
    if (!initRequest.ready) {
        return []
    }
    let selectedCategories = clicked ? [categories] : Object.values(categories)

    return (selectedCategories
        .map(category => ({
            ...category,
            families: (selectedFamily ? [selectedFamily] : category.families)
                .map(family => families[family])
                .map(family => ({
                    ...family,
                    products: buildProductTreeProducts({
                        availableProducts,
                        cart,
                        category,
                        closed_tags,
                        consumercarts,
                        family,
                        producers,
                        products,
                    }),
                }))
                /* ensure non-empty families */
                .filter(f => f.products.length > 0)
                .slice(0)
                .sort(sortByProps(['name'])),
        }))
        /* ensure non-empty categories */
        .filter(c => c.families.some(f => f.products.length > 0))
    )
}
