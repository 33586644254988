import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Icon, Header, Grid } from 'semantic-ui-react'
import { getErrorMessage } from '../getErrorMessage'

export const CreateAccountFooter = ({
    currentLocale,
    currentUser,
    error,
    showAddressForm,
}) => {
    if (showAddressForm) {
        return (
            <div className="computer reversed tablet reversed two column row button-container">
                <Grid.Column>
                    {error
                        ? getErrorMessage(error, currentLocale.home_delivery)
                        : ''}
                    <Form.Button
                        icon
                        primary
                        fluid
                        labelPosition="right"
                        loading={
                            currentUser && currentUser.loading ? true : false
                        }
                        size="huge"
                        name="create-account"
                    >
                        Créer un compte
                        <Icon name="right arrow" />
                    </Form.Button>
                </Grid.Column>

                <Grid.Column>
                    <div className="login-container">
                        <Header
                            as="h3"
                            textAlign="center"
                        >
                            Déjà un compte?
                            <Header.Subheader>
                                Connectez vous...
                            </Header.Subheader>
                        </Header>

                        <Link to="/login">
                            <Form.Button
                                icon
                                fluid
                                labelPosition="right"
                                size="large"
                                name="signin"
                            >
                                Connexion
                                <Icon name="right arrow" />
                            </Form.Button>
                        </Link>
                    </div>
                </Grid.Column>
            </div>
        )
    } else {
        return (
            <Grid.Row
                columns={1}
            >
                <Grid.Column>
                    {error ? getErrorMessage(error) : ''}
                    <Form.Button
                        icon
                        primary
                        fluid
                        labelPosition="right"
                        loading={
                            currentUser && currentUser.loading ? true : false
                        }
                        size="huge"
                    >
                        Créer un compte
                        <Icon name="right arrow" />
                    </Form.Button>

                    <Header
                        as="h3"
                        textAlign="center"
                    >
                        Déjà un compte?
                        <Header.Subheader>Connectez vous...</Header.Subheader>
                    </Header>

                    <Link to="/login">
                        <Form.Button
                            icon
                            fluid
                            labelPosition="right"
                            size="large"
                        >
                            Connexion
                            <Icon name="right arrow" />
                        </Form.Button>
                    </Link>
                </Grid.Column>
            </Grid.Row>
        )
    }
}
