import * as Types from '../../types'
import { sortByName } from './sortByName'

export const handleProducers = ({
    producers,
    productsByProducer,
    dispatch,
}) => {
    let producersDict = {}

    producers.forEach(i => {
        // don't include a producer that has no products
        if (!productsByProducer[i._id]) {
            return
        }

        producersDict[i._id] = {
            ...i,
            // product_families,
            products: productsByProducer[i._id] || [],
        }
    })

    dispatch({
        type: Types.PRODUCERS_UPDATE,
        dict: producersDict,
    })
    dispatch({
        type: Types.PRODUCERS_LIST_UPDATE,
        list: Object.values(producersDict)
            .sort(sortByName)
            .map(p => p._id),
    })
}
