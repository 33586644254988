import React from 'react'
import PropTypes from 'prop-types'
import { Header, Segment } from 'semantic-ui-react'

import SearchFilterContainer from './SearchFilterContainer'
import SelectFilters from './SelectFilters'
import ListContainer from './ListContainer'

const showFilters = props => {
    return (
        props.searchFilterParams ||
        props.quickFilterParams ||
        props.daterangeFilterParams ||
        props.selectFiltersParams
    )
}

export const FilterSegment = ({
    searchFilterParams,
    quickFilterParams,
    daterangeFilterParams,
    selectFiltersParams,
}) => {
    const showSearchContainer =
        searchFilterParams || quickFilterParams || daterangeFilterParams

    return (
        <Segment style={{ marginBottom: 0 }}>
            {showSearchContainer && (
                <SearchFilterContainer
                    searchFilterParams={searchFilterParams}
                    quickFilterParams={quickFilterParams}
                    daterangeFilterParams={daterangeFilterParams}
                />
            )}
            {selectFiltersParams && <SelectFilters {...selectFiltersParams} />}
        </Segment>
    )
}

const Datatable = props => {
    const {
        items,
        listParams,
        title,
        hasMore,
        fetchMore,
        refreshData,
        loading,
    } = props

    // const GenericContainer = <div/> //getGenericContainer();
    // const SegmentContainer = getSegmentContainer();

    return (
        <div>
            {' '}
            {/* div or View */}
            {title && <Header as="h2">{title}</Header>}
            {showFilters(props) && (
                <FilterSegment
                    searchFilterParams={props.searchFilterParams}
                    quickFilterParams={props.quickFilterParams}
                    daterangeFilterParams={props.daterangeFilterParams}
                    selectFiltersParams={props.selectFiltersParams}
                    fetchMore={fetchMore}
                    refreshData={refreshData}
                />
            )}
            <ListContainer
                items={items}
                params={listParams}
                hasMore={hasMore}
                loading={loading}
            />
        </div>
    )
}

Datatable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    listParams: PropTypes.object.isRequired,
    selectFiltersParams: PropTypes.arrayOf(PropTypes.object),
    searchFilterParams: PropTypes.object,
    dateRangeFilterParams: PropTypes.object,
    quickFilterParams: PropTypes.object,
    title: PropTypes.string,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool,
};

export default Datatable;
