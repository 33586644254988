import 'whatwg-fetch'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash.clonedeep'

import { Order } from '../../../../models'
import {
    fetchConsumerOrderDetail,
    editOrderItem,
    removeOrder,
    clearError,
    fetchLocales,
} from '../../../../actions'
import { handleServerError } from '../../../../utils'
import { GlobalOrder } from '../../../../models/globalorder'
import OrderView from './OrderView'
import InfoModal from '../../../ui/InfoModal'
import ErrorBoundary from '../../../layout/ErrorBoundary'
import { handlePrintRequest } from './handlePrintRequest'

import Styles from './styles.module.css'

const keysToCopy = [
    'product_name',
    'producer_name',
    'unit_display',
    'quantity',
    'consumer_price',
    'producerproduct_id',
]

export class OrdersDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            init:
                typeof props.initialized !== 'undefined'
                    ? !props.initialized
                    : true,
            errorRedirect: props.errorRedirect || false,
            retryPayment: props.retryPayment || false,
        }

        this.handleRetryPayment = this.handleRetryPayment.bind(this)
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    UNSAFE_componentWillMount() {
        const path = window.location.href.split('/')
        const order_id = path[path.length - 1]
        const hasActiveGlobalOrders =
            this.props.activeGlobalOrders &&
            this.props.activeGlobalOrders.length > 0

        let ensureActiveGlobalOrders = hasActiveGlobalOrders
            ? () => Promise.resolve()
            : this.props.fetchLocales

        return Promise.all([
            ensureActiveGlobalOrders(),
            this.props.fetchConsumerOrderDetail(order_id),
        ])
            .then(() => this.setState({ init: false }))
            .catch(handleServerError.bind(this, true))
    }

    onRemove() {
        const { order } = this.props
        return this.props
            .removeOrder({ order_id: order._id })
            .catch(handleServerError.bind(this, true))
    }

    onEditItem(item, params) {
        const { order } = this.props
        return this.props
            .editOrderItem({
                order_id: order._id,
                producerproduct_id: item.producerproduct_id,
                ...params,
            })
            .catch(handleServerError.bind(this))
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    handleRetryPayment() {
        this.setState({
            retryPayment: true,
        })
    }

    render() {
        if (this.state.init || this.props.reloading) {
            return (
                <Segment>
                    <Dimmer
                        active
                        inverted
                        style={{ height: '300px' }}
                    >
                        <Loader
                            inverted
                            content="Chargement"
                        />
                    </Dimmer>
                </Segment>
            )
        }

        if (!this.props.order && !this.props.reloading) {
            return <Redirect to="/404" />
        }

        if (this.state.errorRedirect && !this.props.reloading) {
            return <Redirect to="/error" />
        }

        if (this.state.retryPayment) {
            return (
                <Redirect
                    to={`/checkout/retry-payment?order_id=${this.props.order._id}&locale=${this.props.order.locale}`}
                />
            )
        }

        const {
            order: _order,
            currentUser,
            locales,
            serverError,
            activeGlobalOrders = [],
        } = this.props

        const order = cloneDeep(_order)

        order.consumer = currentUser
        order.events = order.getEventsList({
            verifiedAt: { icon: 'check' },
            tabledAt: { icon: 'sign-in' },
            tableDepartedAt: { icon: 'sign-out' },
        })

        if (locales && Object.keys(locales).length > 0) {
            order.locale_code = order.locale
            order.locale = order.getLocaleName(locales)
        }

        let activeGlobalOrder = activeGlobalOrders.find(
            g => g._id === order.globalorder_id
        )

        let allowChanges =
            !!activeGlobalOrder && !activeGlobalOrder.block_order_changes

        return (
            <div className={Styles.Container}>
                <InfoModal
                    show={!!serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />

                <OrderView
                    order={order}
                    items={order.formatItems({
                        references: order.producerproducts,
                        keysToCopy,
                        strictEqual: true,
                        refMatchKey: '_id',
                    })}
                    reduced={order.formatReducedItems({
                        references: order.producerproducts,
                        keysToCopy,
                        strictEqual: true,
                        refMatchKey: '_id',
                    })}
                    removed={order.formatRemovedItems({
                        references: order.producerproducts,
                        keysToCopy,
                        strictEqual: true,
                        refMatchKey: '_id',
                    })}
                    addedCharges={order.added_charges}
                    creditUsed={order.getCreditUsed()}
                    allowPrint={order.status > 0}
                    allowEditItem={
                        allowChanges &&
                        (order.isCurrentStatus('verified') ||
                            order.isCurrentStatus('created')) &&
                        !order.expired
                    }
                    allowRemove={
                        allowChanges &&
                        (order.isCurrentStatus('verified') ||
                            order.isCurrentStatus('created')) &&
                        !order.expired
                    }
                    onRemove={
                        !allowChanges
                            ? () => Promise.reject()
                            : () => this.onRemove()
                    }
                    onEditItem={
                        !allowChanges
                            ? () => () => Promise.reject()
                            : item => params => this.onEditItem(item, params)
                    }
                    handlePrintRequest={handlePrintRequest(
                        order._id,
                        currentUser.token
                    )}
                    allowRetryPayment={
                        !!activeGlobalOrder && order.status === 0
                    }
                    handleRetryPayment={this.handleRetryPayment}
                />
            </div>
        )
    }
}

OrdersDetail.propTypes = {
    activeGlobalOrders: PropTypes.array,
    clearServerError: PropTypes.func.isRequired,
    currentGlobalOrder: PropTypes.object,
    currentLocale: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    editOrderItem: PropTypes.func.isRequired,
    fetchConsumerOrderDetail: PropTypes.func.isRequired,
    fetchLocales: PropTypes.func.isRequired,
    initialized: PropTypes.bool,
    locales: PropTypes.object.isRequired,
    order: PropTypes.object,
    reloading: PropTypes.bool,
    removeOrder: PropTypes.func.isRequired,
    serverError: PropTypes.bool,
}

const AccountPageOrdersDetail = props => (
    <ErrorBoundary page="account-page-orders-detail">
        <OrdersDetail {...props} />
    </ErrorBoundary>
)

const mapStateToProps = ({
    currentOrder,
    currentUser,
    currentLocale,
    activeGlobalOrdersList = [],
    locales,
    initialized,
}) => {
    return {
        order:
            currentOrder && currentOrder.order && new Order(currentOrder.order),
        currentUser: currentUser,
        currentLocale,
        activeGlobalOrders: activeGlobalOrdersList.map(g => new GlobalOrder(g)),
        locales,
        initialized,
        reloading: currentOrder.loading,
        serverError: !!currentOrder.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearServerError: () =>
            dispatch(clearError('FETCH_CONSUMER_ORDER_DETAIL')),
        fetchConsumerOrderDetail: order_id =>
            dispatch(fetchConsumerOrderDetail(order_id)),
        removeOrder: params => dispatch(removeOrder(params)),
        editOrderItem: params => dispatch(editOrderItem(params)),
        fetchLocales: () => dispatch(fetchLocales()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageOrdersDetail)
