import * as Types from '../actions/types'

const creditsHistory = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.FETCH_CONSUMER_CREDITS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case Types.FETCH_CONSUMER_CREDITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error ? action.error.message : action.message,
                credits: [],
                count: 0,
                totalValue: 0,
                hasMore: false,
            }

        case Types.FETCH_CONSUMER_CREDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                credits: action.credits,
                count: action.count,
                totalValue: action.totalValue,
                hasMore: action.count > 10,
            }

        default:
            return state
    }
}

export default creditsHistory
