// import { getAddedChargeDefinition } from "@kelbongoo/shared-methods/orders/collection";

export class Consumer {
    constructor(data) {
        Object.assign(this, data)
    }

    hasValidDeliveryAddress() {
        return this.has_valid_delivery_address
    }
}
