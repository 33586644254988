import * as Types from '../actions/types'

const currentOrder = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        /* GET ORDER DETAIL */

        case Types.FETCH_CONSUMER_ORDER_DETAIL_REQUEST:
            return {
                loading: true,
                error: false,
                order: null,
            }

        case Types.FETCH_CONSUMER_ORDER_DETAIL_FAIL:
            return {
                loading: false,
                error: action.error,
                order: null,
            }

        case Types.FETCH_CONSUMER_ORDER_DETAIL_SUCCESS:
            return {
                loading: false,
                error: null,
                order: action.order,
            }

        case Types.CLEAR_FETCH_CONSUMER_ORDER_DETAIL_ERROR:
            return {
                ...state,
                error: undefined,
            }

        /* EDIT ORDER ITEM */

        case Types.EDIT_ORDER_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case Types.EDIT_ORDER_ITEM_FAIL:
            return {
                ...state, // don't erase the order, just add the error...
                loading: false,
                error: true,
            }

        case Types.EDIT_ORDER_ITEM_SUCCESS:
            return {
                loading: false,
                error: null,
                order: action.order,
            }

        case Types.CLEAR_EDIT_ORDER_ITEM_ERROR:
            return {
                ...state,
                error: undefined,
            }

        /* REMOVE ORDER */

        case Types.REMOVE_ORDER_REQUEST:
            return {
                loading: true,
                error: false,
            }

        case Types.REMOVE_ORDER_FAIL:
            return {
                loading: false,
                error: action.error,
            }

        case Types.REMOVE_ORDER_SUCCESS:
            return {
                loading: false,
                error: null,
                order: action.order,
            }

        case Types.CLEAR_REMOVE_ORDER_ERROR:
            return {
                ...state,
                error: undefined,
            }

        default:
            return state
    }
}

export default currentOrder
