import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Icon, Header, Message } from 'semantic-ui-react'

import { forgotPassword, clearError } from '../../../actions'
import ErrorBoundary from '../../layout/ErrorBoundary'
import { ForgotPasswordValidationNotification } from './ForgotPasswordValidationNotification'

import Styles from './styles.module.css'

export class ForgotPasswordBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: false,
            validationRequired: props.validationRequired || false,
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
        if (this.props.error) {
            this.props.clearForgotPasswordError()
        }
    }

    handleSubmit(event) {
        event.preventDefault()

        if (this.state.loading) {
            return
        }

        const { email } = this.state

        this.setState({ loading: true })

        this.props
            .forgotPassword({ email })
            .then(() => {
                this.setState({
                    validationRequired: true,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        if (this.state.validationRequired) {
            return <ForgotPasswordValidationNotification />
        }

        return (
            <Fragment>
                <div className={Styles.Container}>
                    <Form onSubmit={this.handleSubmit}>
                        <Header
                            as="h3"
                            textAlign="center"
                            icon
                        >
                            <Icon name="user plus" /> Réinitialiser mon mot de
                            passe
                            <Header.Subheader>
                                Un lien sera envoyé sur votre boîte email afin
                                d'achever la procédure de validation
                            </Header.Subheader>
                        </Header>

                        <Form.Input
                            label="Email associée au compte"
                            placeholder="jean@dujardin.fr"
                            id="email"
                            name="email"
                            required
                            type="email"
                            onChange={this.handleChange}
                            error={this.state.error}
                        />

                        {this.props.error ? (
                            this.props.error === 'consumer not found' ? (
                                <Message negative>
                                    Aucun compte n'est li&eacute; &agrave; cette
                                    adresse mail.
                                    <br />
                                    <Link to="/creer-un-compte">
                                        <b>
                                            Cliquez ici pour cr&eacute;er un
                                            compte !
                                        </b>
                                    </Link>
                                </Message>
                            ) : (
                                <Message negative>
                                    Une erreur s'est produite ! Impossible de
                                    réinitialiser le mot de passe pour le
                                    moment.
                                </Message>
                            )
                        ) : (
                            ''
                        )}

                        <Form.Button
                            icon
                            primary
                            fluid
                            labelPosition="right"
                            size="huge"
                            loading={this.state.loading}
                        >
                            Confirmer <Icon name="right arrow" />
                        </Form.Button>

                        <Header
                            as="h3"
                            textAlign="center"
                        >
                            Accéder à l'écran de connexion
                            <Header.Subheader>
                                Vous pourrez vous connecter à l'aide de votre
                                login et de votre mot de passe.
                            </Header.Subheader>
                        </Header>

                        <Link to="/login">
                            <Form.Button
                                icon
                                fluid
                                labelPosition="right"
                                size="large"
                            >
                                Connexion
                                <Icon name="right arrow" />
                            </Form.Button>
                        </Link>
                    </Form>
                </div>
            </Fragment>
        )
    }
}

const ForgotPassword = props => (
    <ErrorBoundary page="forgot-password">
        <ForgotPasswordBase {...props} />
    </ErrorBoundary>
)

const mapStateToProps = ({ session }) => ({
    error: session.forgotPasswordError,
})

const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: data => dispatch(forgotPassword(data)),
        clearForgotPasswordError: () => dispatch(clearError('FORGOT_PASSWORD')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
