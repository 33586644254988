import React, { Fragment } from 'react'
import { Icon, Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

export const AccountPageOrdersPaymentFailedActive = ({ handleRetry }) => (
    <Fragment>
        <div className={Styles.Header}>Paiement échoué</div>
        <div>
            <Button
                icon
                size="mini"
                negative
                labelPosition="left"
                onClick={handleRetry}
            >
                <Icon name="exclamation" />
                Réessayer le paiement
            </Button>
        </div>
        {/* <div>
      <i style={{color: '#d3d3d3', fontSize: '0.5em'}}>
        Valable pendant 4 heures
      </i>
    </div> */}
    </Fragment>
)
