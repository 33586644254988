import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu, Image } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { CategoryItem } from './CategoryItem'
import { mapStateToProps } from './mapStateToProps'

import Styles from './styles.module.css'
import { SubscribeButton } from './SubscribeButton'
import { HeaderLogoDisplay } from '../../../layout/Header/HeaderLogoDisplay'

export class MenuCategories extends Component {
    static propTypes = {
        family: PropTypes.string,
        category: PropTypes.string,
        onMenuClick: PropTypes.func,
        categories: PropTypes.array.isRequired,
        families: PropTypes.array.isRequired,
        scrollTo: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick({ family, category }) {
        this.props.handleMenuClick({ family, category })

        window.dataLayer && category &&
            window.dataLayer.push({
                event: 'select_category',
                eventProps: {
                    category: this.props.categories.find(c => c._id === category).name.toUpperCase(),
                },
            })
    }

    render() {
        const { categories, family, category, currentUser, className } =
            this.props

        return (
            <>
                {window.innerWidth > 1024 && <div className={Styles.logoBox}><HeaderLogoDisplay /></div>}
                <div
                    className={`${className} ${Styles.MenuContainer}`}
                    data-testid="menu-categories"
                    id="menu-mobile"
                >
                    <Menu
                        vertical
                        disableScrollLock={true}
                        style={{ marginBottom: '0px !important' }}
                    >
                        {categories.map(categoryItem => (
                            <CategoryItem
                                categoryItem={categoryItem}
                                family={family}
                                category={category}
                                handleClick={this.handleClick}
                                key={categoryItem._id}
                            />
                        ))}
                    </Menu>

                    {(!currentUser || !currentUser.loggedin) && <SubscribeButton />}
                </div>
            </>
        )
    }
}

MenuCategories.propTypes = {
    categories: PropTypes.array,
    family: PropTypes.string,
    category: PropTypes.string,
    hasMultipleActiveGlobalOrders: PropTypes.bool,
    currentUser: PropTypes.object,
    className: PropTypes.string,
    handleMenuClick: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(MenuCategories)
