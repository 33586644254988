import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import Styles from './styles.module.css'

export const PriceLabel = ({ product }) => {
    return (
        <div
            className={Styles.PriceLabelContainer}
            title="product-price"
        >
            <Label
                tag
                className={Styles.PriceLabel}
            >
                {formatMoney.run(product.consumer_price)}
                <br />
            </Label>
        </div>
    )
}

PriceLabel.propTypes = {
    product: PropTypes.object,
}
