import * as Types from '../actions/types'

const available = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.QUANTITIES_UPDATE:
            return { products: action.dict }

        case Types.QUANTITIES_LIST_UPDATE:
            return state // do nothing for now, use this to build the update hash later

        case Types.QUANTITIES_TIMEOUT_UPDATE:
            return {
                ...state,
                timeoutId: action.timeoutId,
            }

        default:
            return state
    }
}

export default available
