import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import Styles from './styles.module.css'

const NotFound = () => (
    <div className={Styles.container}>
        <Header
            as="h2"
            icon
        >
            <Icon name="question circle outline" />
            Page non trouv&eacute;e
            <Header.Subheader>
                Nous n'avons pas trouv&eacute; la page que vous cherchiez.
            </Header.Subheader>
        </Header>
    </div>
)

export default NotFound
