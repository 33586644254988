import * as Types from '../actions/types'

const activeGlobalOrdersList = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.ACTIVE_GLOBALORDERS_LIST_UPDATE:
            return action.activeGlobalOrders

        default:
            return state
    }
}

export default activeGlobalOrdersList
