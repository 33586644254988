import { wrapClientApiCall } from '../../../utils'
import { prerequestHook } from './prerequestHook'
import { successCallback } from './successCallback'

/**
 * fetch from the api a list of all currently available quantities for all products
 * available in the current globalorder
 * this method is called every X seconds while a user is connected, to minimise the
 * possibility of over-ordering products
 * @param {object} currentGlobalOrder - globalorder object for the current sale
 * @param {date} currentDate - optional peg for current date, testing only
 * @returns {function} - a function to be dispatched
 */

export const fetchAvailable = ({ currentGlobalOrder, currentDate }) =>
    wrapClientApiCall({
        actionRoot: 'FETCH_AVAILABLE',
        prerequestHook: prerequestHook(currentGlobalOrder),
        successCallback: successCallback(currentGlobalOrder, currentDate),
    })
