import { userKeys } from './userKeys'

export const diff = (currentUser, state) => {
    const output = {}

    for (let key of userKeys) {
        if (currentUser[key] !== state[key]) {
            output[key] = state[key]
        }
    }

    return output
}
