/**
 * from the list of all productsheader objects retrieved from contentful,
 * select the "best" fit by priority
 * 1. globalorder_id match is priority
 * 2. if no defaultItem, show active item or else the first item in the list
 * 3. if there is a default item, choose between active item and default item
 *  a. if locale is PP AND active item should NOT show if PP, show default item or else first item in the list
 *  b. if locale is NOT PP, OR active item can show if PP, show active item or else first item in the list
 * @param {object[]} items - array of potential productsheader items
 * @param {object} locale - currentLocale object
 * @param {string} globalorder_id - currentGlobalOrder _id string
 * @returns {object} selected item
 */

function checkDate(d1, d2) {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    const time1 = date1.getTime()
    const time2 = date2.getTime()
    return time1 === time2
}

function checkNow(d1) {
    const date1 = new Date(d1)
    const now = new Date()
    date1.setHours(0, 0, 0, 0)
    now.setHours(0, 0, 0, 0)
    const time1 = date1.getTime()
    const timeNow = now.getTime()
    return time1 >= timeNow
}

export const getProductsHeader = ({ items = [], locale, globalOrder }) => {

    let defaultItem = items.find(i => !!i.default)

    let globalorderItem = items.find(
        i => globalOrder && i.globalorder_id.includes(globalOrder._id)
    )

    let localeDateItem = items.find(
        i => (typeof locale.code !== 'undefined' && checkNow(i.salesDate)) && (i.locales.includes(locale.code) && checkDate(i.salesDate, globalOrder.distribution_date))
    )

    let localeItem = items.find(
        i => (typeof locale.code !== 'undefined' && !checkNow(i.salesDate)) && (i.locales.includes(locale.code) && !checkDate(i.salesDate, globalOrder.distribution_date))
    )

    if (globalorderItem) {
        return globalorderItem
    }

    if (localeDateItem) {
        return localeDateItem
    }

    if (localeItem) {
        return localeItem
    }

    return defaultItem || items[0]
}
