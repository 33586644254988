import { wrapClientApiCall } from '../../../utils/wrapClientApiCall'

export const createFeedback = ({
    feedback_id,
    feedback_quality,
    feedback_quality_price,
    feedback_message,
}) =>
    wrapClientApiCall({
        actionRoot: 'CREATE_FEEDBACK',
        url: `api/producerproductfeedback/validate?\
    feedback_id=${feedback_id}\
    &feedback_quality=${feedback_quality}\
    &feedback_quality_price=${feedback_quality_price}\
    &feedback_message=${feedback_message}`,
        successPayload: data => ({ feedback: data.updatedDoc }),
    })
