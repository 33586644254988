import * as Types from '../../types'
import { wrapClientApiCall } from '../../../utils'
import { fetchUserInfo } from '../fetchUserInfo'

export const resetPassword = ({ reset_password_token, password }) => {
    return wrapClientApiCall({
        actionRoot: 'RESET_PASSWORD',
        url: `reset_password`,
        method: 'POST',
        hasJsonResponse: true,
        body: { reset_password_token, password },
        successCallback({ dispatch, data: { token, orderreservation_id } }) {
            if (token) {
                return dispatch(fetchUserInfo(token)).then(() => {
                    dispatch({
                        type: Types.RESET_PASSWORD_SUCCESS,
                        token,
                        orderreservation_id,
                    })

                    return orderreservation_id
                })
            }

            return false
        },
    })
}
