import * as Types from '../types'
import { getContentfulClient } from '../../utils/getContentfulClient'

const contentfulClient = getContentfulClient()

/**
 * fetch all active producers' data from contentful, and then format into useable data
 * @returns {function} - dispatchable function to fetch producers content
 */

export const fetchProducersContent = () => {
    return dispatch => {
        dispatch({
            type: Types.FETCH_PRODUCERS_CONTENT_REQUEST,
            startDate: new Date(),
        })

        return contentfulClient
            .getEntries({
                content_type: 'producer',
                // 'fields.images': '[exists]'
                select: 'fields.producerid,fields.images,fields.chapeau', // must not put a space between elements !!!
            })
            .then(function (result) {
                let items =
                    result &&
                    result.items &&
                    result.items.map(p => {
                        const output = {}
                        if (!p.fields) {
                            return output
                        }
                        if (p.fields.producerid) {
                            output.producerid = p.fields.producerid
                        }
                        if (p.fields.images) {
                            output.image = p.fields.images[0].fields.file.url
                        }
                        if (p.fields.chapeau) {
                            output.chapeau = p.fields.chapeau
                        }
                        return output
                    })

                dispatch({ type: Types.FETCH_PRODUCERS_CONTENT_SUCCESS, items })
            })
            .catch(error =>
                dispatch({
                    type: Types.FETCH_PRODUCERS_CONTENT_FAIL,
                    error,
                })
            )
    }
}
