import * as Types from '../../types'
import { updateCartItem } from '../../checkout/updateCartItem'
import { fetchInit } from '../../distribution/fetchInit'
import { selectGlobalOrder } from '../../distribution/selectGlobalOrder'
import { selectLocale } from '../../distribution/selectLocale'
import { syncOrder } from '../../checkout/syncOrder'
import history from '../../../history'

/**
 * auto handles the case of a single available globalorder,
 * not auto is the user changing the globalorder manually...
 */

export const confirmSwitchTo = (mode) => {
    return (dispatch, getState) => {
        const { switchTo } = getState()

        dispatch({
            type: Types.SWITCH_TO_CONFIRM_INIT,
        })

        dispatch({
            type: Types.EMPTY_CART,
        })

        dispatch(selectLocale({ locale: switchTo.locale }))
        dispatch(selectGlobalOrder(switchTo.schedule.globalOrder._id))

        return dispatch(fetchInit())
            .then(_ =>
                Promise.all(
                    switchTo.cart &&
                    switchTo.cart.list.map(producerproduct_id => {
                        if (
                            switchTo.availableProducts.find(
                                i =>
                                    i.producerproduct_id ===
                                    producerproduct_id
                            )
                        ) {
                            // add availlable products
                            return dispatch(
                                updateCartItem({
                                    productId: producerproduct_id,
                                    quantity:
                                        switchTo.cart.items[
                                        producerproduct_id
                                        ],
                                    lastAction: null,
                                    showCartNotification: false,
                                })
                            )
                        }
                        const substituteInfo =
                            switchTo.substituteProducts.find(
                                i =>
                                    i.original_producerproduct_id ===
                                    producerproduct_id
                            )
                        if (substituteInfo) {
                            return dispatch(
                                updateCartItem({
                                    productId:
                                        substituteInfo.producerproduct_id,
                                    quantity: substituteInfo.quantity,
                                    lastAction: null,
                                    showCartNotification: false,
                                })
                            )
                        }
                        return true
                    })
                )
            )
            .then(updates => {
                let timestamp = new Date()
                dispatch({
                    type: Types.ORDER_SYNC_TIME_UPDATE,
                    timestamp: timestamp.toISOString(),
                })
                return dispatch(syncOrder())
            })
            .then(result => {
                return dispatch({
                    type: Types.SWITCH_TO_CONFIRM_COMPLETE,
                })
            })
            .then(result => {
                if (mode !== 'check') {
                    history.push('/commande?switch_complete')
                    setTimeout(() => {
                        window.location.reload()
                        window.scrollTo(0, 0)
                    }, 250)
                }
                else {
                    history.push('/cart')
                    setTimeout(() => {
                        window.location.reload()
                        window.scrollTo(0, 0)
                    }, 250)
                }
            })
            .catch(e => {
                dispatch({
                    type: Types.SWITCH_TO_CONFIRM_COMPLETE,
                })
            })
    }
}
