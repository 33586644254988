import * as Types from '../actions/types'

import { GlobalOrder } from '../models/globalorder'

const currentGlobalOrder = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.CURRENT_GLOBAL_ORDER_UPDATE:
            return {
                ...state,
                ...action.currentGlobalOrder,
            }

        case Types.QUANTITIES_UPDATE:
            const data = GlobalOrder.getQuantityUpdateData(action)

            return {
                ...state,
                ...data,
            }

        case Types.RESET_INIT:
            return {}

        default:
            return state
    }
}

export default currentGlobalOrder
