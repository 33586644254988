import React from 'react'
import { Form, Icon, Header } from 'semantic-ui-react'
import Styles from '../styles.module.css'

export const ForgotPasswordValidationNotification = () => (
    <div className={Styles.Container}>
        <Form>
            <Header
                as="h3"
                textAlign="center"
                icon
            >
                <Icon name="user plus" />
                Validation email requise!
            </Header>
            <Header.Subheader>
                Vérifiez votre boîte de messagerie, un email de notre part vous
                permettra de vérifier votre compte.
            </Header.Subheader>
        </Form>
    </div>
)
