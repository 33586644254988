import { Icon, Menu } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React, { Fragment } from 'react'

import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

const AccountPageMenu = ({
    showFeedback,
    showReferral: showReferralExternal,
    showCarts,
}) => {
    let widths = 2
    // if(showFeedback) widths++;
    if (showCarts) widths++
    if (showReferralExternal) widths++

    let showReferral = false

    return (
        <Fragment>
            <div
                className="Container"
                style={{ textAlign: 'left', marginBottom: '20px' }}
            >
                <Button
                    secondary
                    content={'Retour à la vente'}
                    icon="arrow left"
                    href="/commande"
                />
            </div>
            <Responsive maxWidth={MAX_WIDTH.XS}>
                <Menu
                    widths={widths}
                    icon="labeled"
                    size="mini"
                    style={{ fontSize: '0.7em' }}
                >
                    <Menu.Item
                        name="details"
                        as={NavLink}
                        to="/account/details"
                    >
                        <Icon name="user" />
                        Compte
                    </Menu.Item>
                    <Menu.Item
                        name="orders"
                        as={NavLink}
                        to="/account/orders"
                    >
                        <Icon name="list" />
                        Commandes
                    </Menu.Item>

                    {/* {showFeedback ? (
            <Menu.Item
              name='feedback'
              as={NavLink}
              to='/account/feedback'
            >
              <Icon name='thumbs up' />
              Retours
            </Menu.Item>
          ) : ''} */}

                    {showReferral ? (
                        <Menu.Item
                            name="referral"
                            as={NavLink}
                            to="/account/referral"
                        >
                            <Icon name="linkify" />
                            Parrainage
                        </Menu.Item>
                    ) : (
                        ''
                    )}

                    {showCarts ? (
                        <Menu.Item
                            name="carts"
                            as={NavLink}
                            to="/account/carts"
                        >
                            <Icon name="cart" />
                            Paniers
                        </Menu.Item>
                    ) : (
                        ''
                    )}
                </Menu>
            </Responsive>
            <Responsive
                minWidth={MIN_WIDTH.SM}
                maxWidth={MAX_WIDTH.SM}
            >
                <Menu
                    widths={widths}
                    icon="labeled"
                    size="mini"
                    style={{ fontSize: '0.7em' }}
                >
                    <Menu.Item
                        name="details"
                        as={NavLink}
                        to="/account/details"
                    >
                        <Icon name="user" />
                        Compte
                    </Menu.Item>
                    <Menu.Item
                        name="orders"
                        as={NavLink}
                        to="/account/orders"
                    >
                        <Icon name="list" />
                        Commandes
                    </Menu.Item>

                    {/* {showFeedback ? (
            <Menu.Item
              name='feedback'
              as={NavLink}
              to='/account/feedback'
            >
              <Icon name='thumbs up' />
              Retours
            </Menu.Item>
          ) : ''} */}

                    {showReferral ? (
                        <Menu.Item
                            name="referral"
                            as={NavLink}
                            to="/account/referral"
                        >
                            <Icon name="linkify" />
                            Parrainage
                        </Menu.Item>
                    ) : (
                        ''
                    )}

                    {showCarts ? (
                        <Menu.Item
                            name="carts"
                            as={NavLink}
                            to="/account/carts"
                        >
                            <Icon name="cart" />
                            Paniers
                        </Menu.Item>
                    ) : (
                        ''
                    )}
                </Menu>
            </Responsive>
            <Responsive minWidth={MIN_WIDTH.MD}>
                <Menu>
                    <Menu.Item
                        name="details"
                        as={NavLink}
                        to="/account/details"
                    >
                        <Icon name="user" />
                        Compte
                    </Menu.Item>
                    <Menu.Item
                        name="orders"
                        as={NavLink}
                        to="/account/orders"
                    >
                        <Icon name="list" />
                        Commandes
                    </Menu.Item>

                    {/* {showFeedback ? (
            <Menu.Item
              name='feedback'
              as={NavLink}
              to='/account/feedback'
            >
              <Icon name='thumbs up' />
              Retours
            </Menu.Item>
          ) : ''} */}

                    {showReferral ? (
                        <Menu.Item
                            name="referral"
                            as={NavLink}
                            to="/account/referral"
                        >
                            <Icon name="linkify" />
                            Parrainage
                        </Menu.Item>
                    ) : (
                        ''
                    )}

                    {showCarts ? (
                        <Menu.Item
                            name="carts"
                            as={NavLink}
                            to="/account/carts"
                        >
                            <Icon name="cart" />
                            Paniers
                        </Menu.Item>
                    ) : (
                        ''
                    )}
                </Menu>
            </Responsive>
        </Fragment>
    )
}

export default AccountPageMenu
