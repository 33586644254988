import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import history from '../../../../history'

import { Loader, Dimmer } from 'semantic-ui-react'

import InfoModal from '../../../ui/InfoModal'

import CheckoutPaymentConfirmationSelector from './CheckoutPaymentConfirmationSelector'
import CheckoutPaymentConfirmationButton from './CheckoutPaymentConfirmationButton'
import { CheckoutPaymentTokenList } from './CheckoutPaymentTokenList'
import { CheckoutPaymentCB } from './CheckoutPaymentCB'

import Styles from './styles.module.css'
import { formatDate, formatDateTime } from "../../../../utils/formatDate";

export class CheckoutPaymentConfirmationInner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentType: this.props.allowCreditPayment ? 'Credit' : 'CB',
            showPaymentTokenSelect: false,
        }

        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this)
        this.handlePaymentTokenSelection =
            this.handlePaymentTokenSelection.bind(this)
        this.handlePaymentConfirmationButtonClick =
            this.handlePaymentConfirmationButtonClick.bind(this)
        this.returnHome = this.returnHome.bind(this)
    }

    // Cancel all if globalOrder closed
    returnHome = () => {
        history.push('/choisir-un-magasin')
        window.location.reload()
    }

    handlePaymentTypeChange = value => {
        this.setState({ paymentType: value })
    }

    /**
     * if the user selects payment by CB and has now selected a payment token,
     * either the token is empty (he selected "create a new payment CB"), or the token is
     * the one he selected
     * in either case we now confirm the order via API, including the selected token if it exists
     */

    handlePaymentTokenSelection = paymentToken => {
        return this.props.handleConfirmation({
            paymentType: this.state.paymentType,
            paymentToken,
        })
    }

    /**
     * if the user selects payment by CB and the user has payment tokens,
     * show the select payment token view
     * if not, confirm the order via API
     */
    handlePaymentConfirmationButtonClick = () => {
        if (
            this.props.paymentTokens &&
            this.props.paymentTokens.length > 0 &&
            this.state.paymentType === 'CB'
        ) {
            return this.setState({
                showPaymentTokenSelect: true,
            })
        }

        return this.props.handleConfirmation({
            paymentType: this.state.paymentType === 'Credit' ? 'CB' : this.state.paymentType,
        })
    }

    render() {
        const {
            allowCreditPayment,
            blockInStorePayment,
            blockOrderChanges,
            children,
            clearServerError,
            globalOrderAlreadyClosed,
            isHomeDelivery,
            isOrderLocalePrivate,
            handleDeleteToken,
            order_id,
            serverError,
            confirming,
            confirmed,
            paymentFormData,
            paymentTokens = [],
            showOfflinePayment,
        } = this.props

        const { showPaymentTokenSelect } = this.state

        if (confirming) {
            return (
                <Dimmer active inverted>
                    <Loader active>Commande en cours de traitement</Loader>
                </Dimmer>
            )
        }

        if (confirmed === true) {
            return this.state.paymentType === 'CB' && paymentFormData ? (
                <CheckoutPaymentCB paymentFormData={paymentFormData} />
            ) : (
                <>
                    {/* if in-store payment was selected, go directly to confirmation view */}
                    <Redirect
                        to={`/checkout/commande-confirmee?order_id=${order_id}`}
                    />
                </>
            )
        }

        if (showPaymentTokenSelect && paymentTokens.length > 0) {
            return (
                <CheckoutPaymentTokenList
                    handleDeleteToken={handleDeleteToken}
                    tokens={paymentTokens}
                    handleSelection={this.handlePaymentTokenSelection}
                />
            )
        }

        return (
            <div data-testid="payment-confirmation-container">
                <InfoModal
                    show={!!serverError}
                    header="Un problème technique est survenu"
                    body="Nous sommes désolés pour le dérangement - merci de réessayer dans un instant"
                    onClose={clearServerError}
                    isError={true}
                />

                {globalOrderAlreadyClosed &&
                    <InfoModal
                        show={globalOrderAlreadyClosed.status}
                        icon="clock outline icon"
                        header={`La vente du ${formatDate(globalOrderAlreadyClosed.distributionDate)} est close !`}
                        body={`Les commandes devaient être passées avant le ${formatDateTime(globalOrderAlreadyClosed.ordersClosingDate)}. 
                    Nous vous invitons à passer commande sur une prochaine vente.`}
                        onClose={this.returnHome}
                        isInfo={true}
                    />
                }

                <div
                    className={`${Styles.Container} ${!!this.state.paymentType
                        ? Styles.paymentTypeSelected
                        : ''
                        }`}
                >
                    {children}

                    <div className={Styles.ChoiceContainer}>
                        <CheckoutPaymentConfirmationSelector
                            allowCreditPayment={allowCreditPayment}
                            blockInStorePayment={blockInStorePayment}
                            blockOrderChanges={blockOrderChanges}
                            confirming={confirming}
                            handlePaymentTypeChange={
                                this.handlePaymentTypeChange
                            }
                            isHomeDelivery={isHomeDelivery}
                            isOrderLocalePrivate={isOrderLocalePrivate}
                            paymentType={this.state.paymentType}
                            showOfflinePayment={showOfflinePayment}
                        />
                    </div>
                </div>

                {!!this.state.paymentType ? (
                    <CheckoutPaymentConfirmationButton
                        paymentType={this.state.paymentType}
                        confirming={confirming}
                        handleConfirmation={
                            this.handlePaymentConfirmationButtonClick
                        }
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }
}

CheckoutPaymentConfirmationInner.propTypes = {
    allowCreditPayment: PropTypes.bool,
    blockInStorePayment: PropTypes.bool,
    clearServerError: PropTypes.func.isRequired,
    confirmed: PropTypes.bool,
    confirming: PropTypes.bool,
    globalOrderAlreadyClosed: PropTypes.shape({
        distributionDate: PropTypes.instanceOf(Date),
        ordersClosingDate: PropTypes.instanceOf(Date),
        status: PropTypes.bool,
    }),
    handleConfirmation: PropTypes.func.isRequired,
    isOrderLocalePrivate: PropTypes.bool,
    order_id: PropTypes.string,
    paymentFormData: PropTypes.object,
    paymentTokens: PropTypes.array,
    serverError: PropTypes.bool,
}
