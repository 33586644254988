import * as Types from '../actions/types'

const products = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist

            return {
                ...state,
                ...(action.payload && action.payload.products
                    ? action.payload.products
                    : {}),
            }

        case Types.PRODUCTS_UPDATE:
            return action.dict

        case Types.RESET_INIT:
            return {}

        default:
            return state
    }
}

export default products
