import * as Sentry from '@sentry/browser'
import ReactPixel from 'react-facebook-pixel';

export const handleOrderConfirmation = function ({
    paymentType,
    paymentToken,
}) {
    this.setState({
        confirming: true,
        confirmed: false,
    })

    try {
        let cartItems = this.props.cartItems.map(item => {
            return {
                item_name: item.product_name,
                item_id: item.producerproduct_id,
                item_price: item.consumer_price / 100,
                item_quantity: item.quantity,
            }
        })

        // gtm purchase (order button clicked)
        window.dataLayer &&
            window.dataLayer.push({
                event: 'purchase',
                eventProps: {
                    currency: 'EUR',
                    value: cartItems.reduce((a, c) => a + (c.item_price * c.item_quantity), 0),
                    items: cartItems,
                },
            })
        ReactPixel.track('Purchase', {
            currency: 'EUR',
            value: cartItems.reduce((a, c) => a + (c.item_price * c.item_quantity), 0),
            contents: cartItems,
        })
    } catch (err) {
        Sentry.captureException(
            new Error('GTM failed on purchase push, ' + JSON.stringify(err))
        )
    }

    return this.props
        .confirmOrder({
            paymentType,
            paymentToken,
            // iframe: true
        })
        .then(result => {
            // if its true we cool
            if (!!result) {
                return this.setState({
                    confirming: false,
                    confirmed: true,
                    paymentFormData:
                        paymentType === 'CB'
                            ? result.paymentFormData ?
                                result.paymentFormData :
                                undefined
                            : undefined,
                    confirmed_order_id: result.order_id,
                })
            } else {
                try {
                    // gtm order confirmed failed
                    window.dataLayer &&
                        window.dataLayer.push({
                            event: 'purchase_error',
                            eventProps: {
                                payment_type: paymentType,
                            },
                        })
                } catch (err) {
                    Sentry.captureException(
                        new Error(
                            'GTM failed on purchase push, ' +
                            JSON.stringify(err)
                        )
                    )
                }

                return this.setState({
                    confirming: false,
                    confirmed: false,
                })
            }
        })
}
