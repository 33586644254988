import React from 'react'
import { Table, Button } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

export const CartListFooter = ({ currentCartValue }) => (
    <Table.Footer fullWidth>
        <Table.Row>
            <Table.HeaderCell colSpan="100">
                <Button
                    floated="right"
                    icon
                    secondary
                    label={{
                        as: 'a',
                        basic: true,
                        content: formatMoney.run(currentCartValue, true),
                    }}
                    labelPosition="right"
                    content="Total"
                    size="large"
                />
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
)
