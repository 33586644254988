import * as Types from '../../types'

/**
 * saves recovery information
 */

export const saveOrderRecovery = ({ locale, schedule, cart }) => {
    return (dispatch, getState) => {
        dispatch({
            type: Types.ORDER_RECOVERY_SAVE,
            cart,
            locale,
            schedule,
        })
    }
}
