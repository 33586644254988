export const componentWillMount = function () {
    /* set up eventEmitter listener */
    this.props.eventEmitter &&
        this.props.eventEmitter.on('search-select', data => {
            data['search'] = true
            if (window.innerWidth > 1024) {
                this.handleMenuClick(data)
            }
            else {
                data['selectedCategory'] = data.category
                this.handleMenuClick(data)
            }
        })
}
