import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'semantic-ui-react'

import Styles from './styles.module.css'

import { ProductInfo } from './ProductInfo'
import { PriceLabel } from './PriceLabel'
import { ProductImage } from './ProductImage'
import { ProductQuantityLimited } from './ProductQuantityLimited'
import { ProductBioLabel } from '../ProductBioLabel'
import { ProductQuantityEdit } from '../ProductQuantityEdit'

class ProductCard extends Component {
    static propTypes = {
        onChangeValue: PropTypes.func.isRequired,
        onOpenZoom: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        product: PropTypes.object.isRequired,
        producer: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            value: props.defaultValue || 0,
        }

        this.bumpUp = this.bumpUp.bind(this)
        this.bumpDown = this.bumpDown.bind(this)
        this.toggleZoom = this.toggleZoom.bind(this)
    }

    shouldComponentUpdate({ product: { quantity, available } }) {
        if (quantity !== this.props.product.quantity) {
            return true
        }
        if (available !== this.props.product.available) {
            return true
        }

        return false
    }

    bumpUp(e) {
        if (
            this.props.max !== undefined &&
            this.state.value >= this.props.max
        ) {
            e && e.stopPropagation()
            e && e.preventDefault()
            return
        }
        const quantity = this.props.product.quantity + 1

        this.props.onChangeValue({
            e,
            productId: this.props.product.producerproduct_id,
            quantity,
            lastAction: 'add'
        })
    }

    bumpDown(e) {
        if (
            this.props.min !== undefined &&
            this.state.value <= this.props.min
        ) {
            e && e.stopPropagation()
            e && e.preventDefault()
            return
        }
        const quantity = this.props.product.quantity - 1

        this.props.onChangeValue({
            e,
            productId: this.props.product.producerproduct_id,
            quantity,
            lastAction: 'remove'
        })
    }

    toggleZoom(e) {
        e && e.stopPropagation()
        e && e.preventDefault()
        this.props.onOpenZoom({
            product: this.props.product,
        })
        window.dataLayer &&
            window.dataLayer.push({
                event: 'view_item',
                eventProps: {
                    currency: 'EUR',
                    value: this.props.product.consumer_price / 100,
                    items: [this.props.product],
                },
            })
    }

    render() {
        const { product, producer, isFull } = this.props

        let cardClass = Styles.Card
        let bioClass = Styles.Bio

        if (product.quantity > 0) {
            cardClass += ' active'
        }

        if (product.quantity > 0 && product.quantity < 9) {
            bioClass += ' has-limited-qty'
        }

        const isProductQuantityLimited =
            product.available < 9 && product.available > 0

        return (
            <Card
                className={cardClass}
                data-testid="product-card"
            >
                <ProductImage
                    toggleZoom={this.toggleZoom.bind(this)}
                    product={product}
                />

                <ProductQuantityEdit
                    cartQuantity={product.quantity}
                    quantityAvailable={product.available}
                    isFull={isFull}
                    bumpUp={this.bumpUp}
                    bumpDown={this.bumpDown}
                />

                <PriceLabel product={product} />

                <ProductInfo
                    producer={producer}
                    product={product}
                    toggleZoom={this.toggleZoom}
                />

                {isProductQuantityLimited ? <ProductQuantityLimited /> : ''}

                {product.bio ? <ProductBioLabel bioClass={bioClass} /> : ''}
            </Card>
        )
    }
}

export default ProductCard
