import { wrapClientApiCall } from '../../../utils';

export const deleteConsumerPaymentToken = (token) => {
    return (dispatch) => {
        return dispatch(wrapClientApiCall({
            actionRoot: 'DELETE_CONSUMER_PAYMENT_TOKEN',
            url: `api/consumer/delete-payment-token`,
            body: { token },
            method: 'POST',
            successPayload: data => ({
                tokens: data.tokens,
            })
        }));
    };
};
