/* istanbul ignore file */

import * as Types from '../actions/types'

const consumercarts = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.CONSUMER_CARTS_UPDATE:
            return {
                ...state,
                ...action.dict,
            }

        case Types.RESET_INIT:
            return {}

        case Types.FETCH_CONSUMER_CARTS_SUCCESS:
            return {
                ...action.dict,
                ...state,
            }

        default:
            return state
    }
}

export default consumercarts
