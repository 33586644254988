const iconCredit = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <path fill="none" d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
        <path fill="rgba(40,120,2,1)" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z" clipRule="evenodd" />
        <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z" />
    </svg>
)

const iconCb = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M22 10v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V10h20zm0-2H2V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4zm-7 8v2h4v-2h-4z"
            fill="rgba(40,120,2,1)"
        />
    </svg>
)

const iconStore = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M21 13v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7H2v-2l1-5h18l1 5v2h-1zM5 13v6h14v-6H5zm1 1h8v3H6v-3zM3 3h18v2H3V3z"
            fill="rgba(40,120,2,1)"
        />
    </svg>
)

export const configPaymentMethod = [
    {
        value: 'Credit',
        id: 'pay-credit',
        icon: iconCredit,
        title: 'Règlement en avoirs',
        body: { retrait: 'Félicitations! Vos avoirs sont suffisants pour couvrir le paiement de votre commande en livraison point retrait.', livdom: 'Félicitations! Vos avoirs sont suffisants pour couvrir le paiement de votre commande en livraison à domicile.' },
        notice: { retrait: 'Choisissez cette option pour payer avec vos avoirs et gagnez du temps au moment du retrait.', livdom: 'Choisissez cette option pour payer avec vos avoirs et recevez votre commande directement à votre domicile.' },
    },
    {
        value: 'CB',
        id: 'pay-cb',
        icon: iconCb,
        title: 'Règlement en ligne',
        body: { retrait: 'Gagnez du temps en payant par CB et évitez la queue lors de la distribution !', livdom: 'Payez par CB pour confirmer votre commande! Vous serez livré à votre domicile.' },
        notice: { retrait: 'Choisissez cette option pour payer en ligne et gagnez du temps au moment du retrait.', livdom: 'Toutes les commandes en livraison à domicile doivent être réglées en intégralité via notre prestataire de paiement en ligne.' },
    },
    {
        value: 'Offline',
        id: 'pay-offline',
        icon: iconStore,
        title: 'Règlement sur place',
        body: { retrait: 'Votre commande est réservée, vous payez sur place par chèque, espèces ou CB lors du retrait.', livdom: '' },
        notice: { retrait: 'Choisissez cette option si vous souhaitez payer sur place au moment du retrait.', livdom: '' },
    },
]
