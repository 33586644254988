import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import Styles from './styles.module.css'

export const CreateAccount = () => (
    <div data-testid="create-account">
        <p>
            Pour recevoir nos emails et être prévenu de l'ouverture des
            prochaines ventes, inscrivez-vous ici (inscription gratuite et sans
            engagement) !
        </p>

        <Link to="/creer-un-compte">
            <Button
                className={Styles.SignupButton}
                primary
                size="large"
                fluid
            >
                Je veux m'inscrire !
            </Button>
        </Link>
    </div>
)
