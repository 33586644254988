import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Responsive from "../../ui/Responsive"

import AccountPage from './AccountPage'

import { fetchLocales, fetchUserInfo } from '../../../actions'
import { MIN_WIDTH } from '../../../app-constants'

export class Account extends Component {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.props.fetchLocales()
        this.props.fetchUserInfo()
    }

    render() {
        const { currentUser } = this.props

        if (currentUser.loggedin !== true) {
            return (
                <Redirect
                    from="/account"
                    to="/login"
                />
            )
        }

        return (
            <Fragment>
                <Responsive minWidth={MIN_WIDTH.MD}>
                    <div style={{ paddingTop: '3em' }}></div>
                </Responsive>
                <div
                    className="Account"
                    style={window.innerWidth > 1024 ? { paddingTop: '110px' } : { paddingTop: '30px' }}
                >
                    <AccountPage />
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })
const mapDispatchToProps = dispatch => {
    return {
        fetchUserInfo: () => dispatch(fetchUserInfo()),
        fetchLocales: () => dispatch(fetchLocales()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
