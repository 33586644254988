import * as Types from '../actions/types'

const productsHeaderContent = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.FETCH_PRODUCTS_HEADER_CONTENT_REQUEST:
            return {
                loading: true,
                error: null,
                content: [],
            }

        case Types.FETCH_PRODUCTS_HEADER_CONTENT_SUCCESS:
            return {
                loading: false,
                error: null,
                content: action.items,
            }

        case Types.FETCH_PRODUCTS_HEADER_CONTENT_FAIL:
            return {
                loading: false,
                error: action.error,
                content: [],
            }

        default:
            return state
    }
}

export default productsHeaderContent
