import * as Types from '../../types'

export const successCallback = ({ data: { carts = [] }, dispatch }) => {
    let dict

    if (carts.length > 0) {
        dict = {}

        for (let cart of carts) {
            dict[cart._id] = cart
        }
    }

    dispatch({
        type: Types.FETCH_CONSUMER_CARTS_SUCCESS,
        dict,
    })

    return carts.length > 0
}
