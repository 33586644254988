/* istanbul ignore file */

import React, { Fragment } from 'react'
import { Table, Icon, Rating } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import { Link } from 'react-router-dom'

import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export const AccountPageFeedbackRow = ({ data }) => (
    <Table.Row warning={!data.hasFeedback()}>
        <Table.Cell>
            <div>
                <Link to={`/account/feedback/${data._id}`}>
                    {data.product_name}
                </Link>
            </div>
            <div>{data.producer_name}</div>
            <Responsive
                as="div"
                maxWidth={MAX_WIDTH.XS}
            >
                {formatMoney.run(data.consumer_price)}
            </Responsive>
            <Responsive
                as="div"
                maxWidth={MAX_WIDTH.SM}
            >
                {data.getDistributionDate()}
            </Responsive>
        </Table.Cell>
        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
        >
            {data.getDistributionDate()}
        </Responsive>
        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.SM}
            style={{ textAlign: 'center' }}
        >
            {formatMoney.run(data.consumer_price)}
        </Responsive>
        {data.hasFeedback() ? (
            <Fragment>
                <Table.Cell style={{ textAlign: 'center' }}>
                    <div>
                        <div class="content">
                            <Rating
                                icon="star"
                                defaultRating={data.feedback_quality}
                                size="tiny"
                            />
                        </div>
                        <div class="content">
                            <Rating
                                icon="star"
                                defaultRating={data.feedback_quality_price}
                                size="tiny"
                            />
                        </div>
                    </div>
                    <Responsive
                        as="div"
                        maxWidth={MAX_WIDTH.SM}
                    >
                        {formatDate.run(data.validatedAt, 'ddd DD MMM YYYY')}
                    </Responsive>
                </Table.Cell>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.MD}
                >
                    {formatDate.run(data.validatedAt, 'ddd DD MMM YYYY')}
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    minWidth={MIN_WIDTH.MD}
                >
                    {data.producerRepliedAt && <Icon name="chat" />}
                </Responsive>
            </Fragment>
        ) : (
            <Fragment>
                <Responsive
                    as={Table.Cell}
                    maxWidth={MAX_WIDTH.SM}
                    style={{ textAlign: 'center' }}
                >
                    <Link to={`/account/feedback/${data._id}`}>
                        <Icon name="wait" /> En attente de votre retour !
                    </Link>
                </Responsive>
                <Responsive
                    as={Table.Cell}
                    colSpan={3}
                    minWidth={MIN_WIDTH.MD}
                    style={{ textAlign: 'center' }}
                >
                    <Link to={`/account/feedback/${data._id}`}>
                        <Icon name="wait" /> En attente de votre retour !
                    </Link>
                </Responsive>
            </Fragment>
        )}
    </Table.Row>
)
