import { wrapClientApiCall } from '../../../utils'

export const fetchConsumerCredits = (page = 1) =>
    wrapClientApiCall({
        actionRoot: 'FETCH_CONSUMER_CREDITS',
        url: `api/consumercredits/fetch-for-consumer?page=${page}`,
        successPayload: data => ({
            credits: data.items,
            count: data.count,
            totalValue: data.totalValue,
        }),
    })
