import React from 'react'
import { Step } from 'semantic-ui-react'

export const StepGroupXSmall = () => (
    <Step.Group
        ordered
        size="mini"
    >
        <Step active>
            <Step.Content>
                <Step.Title>Confirmation</Step.Title>
                <Step.Description>
                    Valider vos produits / retrait
                </Step.Description>
            </Step.Content>
        </Step>
        <Step>
            <Step.Content>
                <Step.Title>Règlement</Step.Title>
                <Step.Description>
                    Choisir votre mode de règlement
                </Step.Description>
            </Step.Content>
        </Step>
    </Step.Group>
)
