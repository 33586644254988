import * as Types from '../actions/types'

const switchTo = (state = {}, action = {}) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist
            return {
                ...state,
                ...(action.payload && action.payload.switchTo
                    ? {
                        ...action.payload.switchTo,
                    }
                    : {}),
            }

        case Types.SWITCH_TO_INIT:
            return {
                availableProducts: [],
                cart: action.cart,
                confirmable: null,
                locale: action.locale,
                removedProducts: [],
                globalOrder: action.globalOrder,
                schedule: action.schedule,
                substituteProducts: [],
            }

        case Types.SWITCH_TO_CANCEL_INIT:
            return {
                ...state,
            }

        case Types.SWITCH_TO_CANCEL_COMPLETE:
            return {
                availableProducts: [],
                cart: null,
                locale: null,
                removedProducts: [],
                schedule: null,
                substituteProducts: [],
            }

        case Types.SWITCH_TO_CONFIRM_INIT:
            return {
                ...state,
                confirming: true,
            }

        case Types.SWITCH_TO_CONFIRM_COMPLETE:
            return {
                availableProducts: [],
                cart: null,
                confirming: false,
                locale: null,
                removedProducts: [],
                schedule: null,
                substituteProducts: [],
            }

        case Types.FETCH_SUBSTITUTE_REQUEST:
            return {
                ...state,
                fetchingSubstitutes: 'loading',
            }

        case Types.FETCH_SUBSTITUTE_SUCCESS:
            return {
                ...state,
                availableProducts: action.availableProducts,
                confirmable: action.confirmable,
                fetchingSubstitutes: 'completed',
                removedProducts: action.removedProducts,
                substituteProducts: action.substituteProducts,
            }

        case Types.FETCH_SUBSTITUTE_FAIL: // also dispatches to initRequest to manage the error
            return {
                ...state,
                fetchingSubstitutes: 'failed',
            }

        case Types.SWITCH_TO_UPDATE_SUBSTITUTES:
            return {
                ...state,
                substituteProducts: action.substituteProducts,
            }

        // case Types.SUBSTITUTE_UPDATE:
        //   return {
        //     ...state,
        //     removedProducts: action.removedProducts,
        //     substituteProducts: action.substituteProducts,
        //     availableProducts: action.availableProducts
        //   }

        default:
            return state
    }
}

export default switchTo
