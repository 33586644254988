import { GlobalOrder, Cart } from '../../../models'

export const mapStateToProps = (
    {
        currentUser,
        livdomZipcodes,
        currentGlobalOrder,
        currentLocale,
        cart: { list, items: quantities, timeout, cartChange },
        products,
        available: { products: available },
        activeGlobalOrdersList,
        locales,
        ...other
    },
    { location: { pathname }, eventEmitter }
) => {
    // transforms
    const currentUserName = currentUser.firstname || ''
    const currentLocaleData =
        currentLocale.code && currentLocale.address && currentLocale
    const currentGlobalOrderData =
        currentGlobalOrder._id && new GlobalOrder(currentGlobalOrder)

    const cart = new Cart({
        list,
        cartChange,
        quantities,
        products,
        available,
        isLocalePrivate: currentLocaleData && !!currentLocaleData.private,
        isLocaleHomeDelivery:
            currentLocaleData && !!currentLocaleData.home_delivery,
        currentLocale: currentLocaleData,
    })

    return {
        cartUpdateOpen: timeout ? true : false,
        cart,
        currentUser,
        livdomZipcodes,
        currentUserName,
        currentGlobalOrder: currentGlobalOrderData,
        currentLocale: currentLocaleData,
        showChangeGlobalOrder: pathname !== '/choisir-une-globale',
        hideCurrentGlobalOrder:
            pathname === '/choisir-un-magasin' ||
            pathname.indexOf('/account') > -1,
        showChangeBoutique: ['/commande', '/choisir-une-globale', '/'].includes(
            pathname
        ),
        showSearch: pathname === '/' || pathname.indexOf('/commande') > -1,
        hideCurrentLocale: pathname === '/checkout/reglement',
        // hideCurrentLocale: pathname === '/checkout/reglement' || pathname.indexOf('/account') > -1,
        hideCart: pathname === '/checkout/reglement',
        // hideCart: pathname === '/checkout/reglement' || pathname.indexOf('/account') > -1,
        hasMultipleActiveGlobalOrders: activeGlobalOrdersList.length > 1,
        activeGlobalOrdersList,
        locales,
        eventEmitter,
        showContinueButton: pathname.indexOf('/account') >= 0,
    }
}
