const possibleStatuses = [
    'créée',
    'validée',
    'acheminement',
    'sur place',
    'en attente',
    'abandonnée',
    'retirée',
    'erreur',
    'annulée',
    'fusionnée',
]

export const formatItems = ({
    orders,
    activeGlobalOrdersList,
    handleRetryPayment,
    locales,
}) => {
    return orders.map(order => {
        let globalorder = activeGlobalOrdersList.find(
            g => g._id === order.globalorder_id
        )

        return Object.assign({}, order, {
            isGlobalOrderActive: !!globalorder,
            locale: order.getLocaleName(locales),
            status: possibleStatuses[order.status],
            amount_total: order.getAmountTotal(),
            units_total: order.getUnitsTotal(),
            handleRetryPayment: () => handleRetryPayment(order._id),
        })
    })
}
