import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

import CheckoutOrderConfirmation from './CheckoutOrderConfirmation'
import CheckoutPaymentConfirmation from './CheckoutPaymentConfirmation'
import CheckoutOrderConfirmed from './CheckoutOrderConfirmed'
import CheckoutOrderFailed from './CheckoutOrderFailed'
import CheckoutRetryPayment from './CheckoutRetryPayment'
// import { CheckoutPaymentCBReturnFailure } from './CheckoutPaymentCBReturnFailure';
// import { CheckoutPaymentCBReturnSuccess } from './CheckoutPaymentCBReturnSuccess';

const CheckoutPage = props => (
    <Fragment>
        <Route
            exact
            path="/checkout"
            component={CheckoutOrderConfirmation}
        />
        <Route
            exact
            path="/checkout/reglement"
            component={CheckoutPaymentConfirmation}
        />
        <Route
            exact
            path="/checkout/commande-confirmee"
            component={CheckoutOrderConfirmed}
        />
        <Route
            exact
            path="/checkout/commande-echouee"
            component={CheckoutOrderFailed}
        />
        <Route
            exact
            path="/checkout/retry-payment"
            component={CheckoutRetryPayment}
        />
        {/* <Route exact path="/checkout/payment-return" component={CheckoutPaymentCBReturnSuccess} /> */}
    </Fragment>
)

export default CheckoutPage
