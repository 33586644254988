/* istanbul ignore file */

import * as Types from '../actions/types'

const feedbackHistory = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.FETCH_CONSUMER_FEEDBACK_REQUEST:
            return {
                loading: true,
                error: false,
                feedback: [],
                count: 0,
            }

        case Types.FETCH_CONSUMER_FEEDBACK_FAIL:
            return {
                loading: false,
                error: action.error,
                feedback: [],
                count: 0,
            }

        case Types.FETCH_CONSUMER_FEEDBACK_SUCCESS:
            return {
                loading: false,
                error: null,
                feedback: action.feedback,
                count: action.count,
            }

        case Types.CLEAR_FETCH_CONSUMER_FEEDBACK_ERROR:
            return {
                ...state,
                error: undefined,
            }
        default:
            return state
    }
}

export default feedbackHistory
