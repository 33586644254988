import 'whatwg-fetch'
import * as Types from '../types'
import { getContentfulClient } from '../../utils/getContentfulClient'

const contentfulClient = getContentfulClient()

/**
 * fetch all possible "source" options for the user signup form from contentful, and then format into useable data
 * @returns {function} - dispatchable function to fetch signup options
 */

export const fetchSignupReasons = () => {
    return dispatch => {
        dispatch({
            type: Types.FETCH_SIGNUP_REASONS_REQUEST,
            startDate: new Date(),
        })

        return contentfulClient
            .getEntries({
                content_type: 'signupReason',
                select: 'fields.text,fields.code', // must not put a space between elements !!!
            })
            .then(function (result) {
                let items =
                    result &&
                    result.items &&
                    result.items.map(p => {
                        const output = {}
                        if (!p.fields) {
                            return output
                        }
                        if (p.fields.text) {
                            output.text = p.fields.text
                        }
                        if (p.fields.code) {
                            output.code = p.fields.code
                        }
                        return output
                    })

                dispatch({ type: Types.FETCH_SIGNUP_REASONS_SUCCESS, items })
            })
            .catch(error =>
                dispatch({
                    type: Types.FETCH_SIGNUP_REASONS_FAIL,
                    error,
                })
            )
    }
}
