import * as Types from '../../types'
import { getTimeouts } from './getTimeouts'
import { saveOrderRecovery } from '../../distribution/switchTo/saveOrderRecovery'

export function forceExpiry(dispatch, getState) {
    // gtm cart expired
    window.dataLayer &&
        window.dataLayer.push({
            event: 'cartExpired',
        })

    try {
        const { currentLocale, cart, currentGlobalOrder } = getState()

        if (
            cart &&
            cart.list.length > 0 &&
            currentLocale &&
            currentLocale.currentSchedule
        ) {
            dispatch(
                saveOrderRecovery({
                    cart,
                    locale: currentLocale,
                    schedule: currentLocale.currentSchedule,
                    currentGlobalOrder
                })
            )

            dispatch({ type: Types.EMPTY_CART })
            dispatch({ type: Types.SHOW_CART_EXPIRED_MESSAGE })
        }
    } catch (error) {
        dispatch({ type: Types.EMPTY_CART })
        dispatch({ type: Types.SHOW_CART_EXPIRED_MESSAGE })
    }
}

export const successCallback =
    (currentDate, mode) =>
        ({ data, dispatch, getState }) => {
            if (!data) {
                return Promise.resolve()
            }

            const { expirationDate, orderreservation_id } = data
            const { order, cart } = getState()

            const expiration = mode === 'check' ? new Date(order.cartExpiration) : new Date(expirationDate)
            const now = new Date()

            dispatch({
                type: Types.ORDER_SYNC_SUCCESS,
                orderreservation_id: orderreservation_id,
                cartExpiration: expiration,
            })

            if (order.expirationTimer0) {
                clearTimeout(order.expirationTimer0)
            }
            if (order.expirationTimer1) {
                clearTimeout(order.expirationTimer1)
            }
            if (order.expirationTimer5) {
                clearTimeout(order.expirationTimer5)
            }

            if (cart.list.length > 0) {
                let timeouts = getTimeouts(expiration, currentDate)

                // timeout 5 minute warning
                let expirationTimer5 = setTimeout(() => {
                    dispatch({ type: Types.SHOW_CART_EXPIRATION_FIVE_MESSAGE })
                }, timeouts.five)

                // timeout 1 minute warning
                let expirationTimer1 = setTimeout(() => {
                    dispatch({ type: Types.SHOW_CART_EXPIRATION_ONE_MESSAGE })
                }, timeouts.one)

                // full timeout
                let expirationTimer0 = setTimeout(() => {
                    forceExpiry(dispatch, getState)
                }, timeouts.zero)

                if (mode === 'check') {
                    if (expiration && expiration < now) {
                        forceExpiry()
                    }
                }

                return dispatch({
                    type: Types.ORDER_SYNC_RESET_TIMERS,
                    expirationTimer0,
                    expirationTimer1,
                    expirationTimer5,
                })
            }
            else {
                return
            }
        }
