import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

import Styles from './styles.module.css'

const EmptyFooter = ({ text, icon }) => (
    <Header
        as="h4"
        icon
        className={Styles.Header}
    >
        <Icon name={icon} />
        <Header.Content>{text}</Header.Content>
        <Header.Subheader>
            N'h&eacute;sitez pas &agrave; nous{' '}
            <a
                href="mailto:communication@kelbongoo.com"
                target="_top"
            >
                contacter
            </a>{' '}
            si vous avez des questions !
        </Header.Subheader>
    </Header>
)

export default EmptyFooter
