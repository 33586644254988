import React from 'react'
import { Button } from 'semantic-ui-react'
import Responsive from "../../../ui/Responsive"
import { Link } from 'react-router-dom'
import history from '../../../../history'

import Styles from './styles.module.css'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../app-constants'

export const PersistentCartCorrectionBar = ({ handleRevertSwitch, handleConfirmSwitch, check }) => {
    function returnHome() {
        history.push('/choisir-un-magasin')
        window.location.reload()
        window.scrollTo(0, 0)
    }
    return (
        <div className={Styles.PersistentCartCorrectionBar}>

            {window.innerWidth < 500 &&
                <div style={{ display: 'flex', justifyContent: 'center', width: check ? '90%' : '100%' }}>
                    <Responsive
                        onClick={check ? returnHome : handleRevertSwitch}
                        maxWidth={MAX_WIDTH.SM}
                        as={Button}
                        size="large"
                        content={check ? "Annuler" : "Je reviens à la vente"}
                        primary
                        basic
                        icon="left arrow"
                        labelPosition="left"
                        className={Styles.ExpandingButton}
                    />
                    {check &&
                        <Responsive
                            onClick={() => handleConfirmSwitch('check')}
                            as={Button}
                            maxWidth={MAX_WIDTH.SM}
                            size="large"
                            content={"Panier"}
                            primary
                            basic
                            icon="cart"
                            labelPosition="right"
                            className={Styles.ExpandingButton}
                        />
                    }
                </div>
            }

            <Responsive
                onClick={check ? returnHome : handleRevertSwitch}
                minWidth={MIN_WIDTH.MD}
                as={Button}
                size="huge"
                content={check ? "Je retourne à l'accueil" : "Je reviens à ma commande"}
                primary
                basic
                icon="left arrow"
                labelPosition="left"
                className={Styles.ExpandingButton}
            />

            <Responsive
                onClick={() => handleConfirmSwitch()}
                as={Button}
                maxWidth={MAX_WIDTH.SM}
                size="large"
                content={check ? "Je continue mes achats" : "Je valide mon changement"}
                primary
                icon="right arrow"
                labelPosition="right"
                className={Styles.ExpandingButton}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {check &&
                    <>
                        <Responsive
                            onClick={() => handleConfirmSwitch('check')}
                            as={Button}
                            minWidth={MIN_WIDTH.MD}
                            size="huge"
                            content={"Je valide mon panier"}
                            primary
                            basic
                            icon="cart"
                            labelPosition="right"
                            className={Styles.ExpandingButton}
                        />
                    </>
                }

                <Responsive
                    onClick={() => handleConfirmSwitch()}
                    as={Button}
                    minWidth={MIN_WIDTH.MD}
                    size="huge"
                    content={check ? "Je reprends mes achats" : "Je valide mon changement"}
                    primary
                    icon="right arrow"
                    labelPosition="right"
                    className={Styles.ExpandingButton}
                />
            </div>

        </div>
    )
}
