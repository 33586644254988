import React from 'react'
import { Image } from 'semantic-ui-react'

import { addImgPrefix } from '../../../../utils/addImgPrefix'

import { CreateAccount } from './CreateAccount'

import Styles from './styles.module.css'

export const NoCurrentGlobalOrder = ({
    prochaineVenteDistrib,
    prochaineVenteStart,
    currentUser,
}) => {
    return (
        <div
            className={Styles.Container}
            data-testid="no-current-globalorder-container"
        >
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <Image
                        src={addImgPrefix('choufleur-sad.svg')}
                        alt="Logo chou fleur triste"
                        size="small"
                        centered
                    />
                </div>
                <div>
                    <h4>Il n'y a pas de vente en cours.</h4>
                    {prochaineVenteStart ? (
                        <div style={{ textAlign: 'center' }}>
                            La prochaine vente débutera le&nbsp; <br />
                            <span className={Styles.StartTime}>
                                {prochaineVenteStart}
                            </span>
                            <br />
                            pour une distribution le {prochaineVenteDistrib}.
                            <br />
                            <br />
                            <br />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {(!currentUser || !currentUser.loggedin) && <CreateAccount />}
            </div>
        </div>
    )
}
