// Components
import { NavLink } from 'react-router-dom'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import LogoutConfirm from './LogoutConfirm'

// Styles
import Styles from './styles.module.css'

const HeaderSelectUserAccount = ({ currentUser, handleLogoutClick, error }) => {
    const configUserAcces = [
        {
            title: 'mon compte',
            route: '/account/details',
        },
        {
            title: 'mes commandes',
            route: '/account/orders',
        },
    ]

    const trigger = (
        <>
            <Icon
                name="user"
                size={window.innerWidth < 500 ? "big" : 'large'}
            />
            {window.innerWidth > 500 && <span className={Styles.UserName}>{currentUser.firstname}</span>}
        </>
    )

    return (
        <div className={Styles.ButtonContainer}>
            <Dropdown
                trigger={trigger}
                icon={false}
                className={window.innerWidth > 500 && Styles.ButtonMenu}
                title="logout-modal-trigger"
            >
                <Dropdown.Menu
                    className={Styles.Panel}
                    title="content-logout-dropdown"
                >
                    <div className={Styles.userInformation}>
                        <div
                            className={Styles.UserName}
                            data-testid="user-firstname"
                        >
                            {currentUser.firstname} {currentUser.lastname}
                        </div>
                        <div className={Styles.Truncate}>
                            {currentUser.email}
                        </div>
                    </div>

                    <Dropdown.Menu
                        scrolling
                        className={Styles.DropdownMenu}
                    >
                        <div id="button_menu">
                            {configUserAcces.map((menu, id) => (
                                <NavLink
                                    key={id}
                                    to={menu.route}
                                    className={Styles.MenuButton}
                                    activeStyle={{
                                        background: '#efefef',
                                    }}
                                >
                                    {menu.title}
                                </NavLink>
                            ))}
                        </div>
                    </Dropdown.Menu>

                    <Dropdown.Divider className={Styles.Divider} />

                    <div className={Styles.BtnLogout}>
                        <Button
                            content="Déconnexion"
                            icon="power"
                            fluid
                            basic
                            onClick={handleLogoutClick}
                        />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default HeaderSelectUserAccount
