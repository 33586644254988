import { diff } from './diff'

export const validateConsumerProfileUpdate = (currentUser, params) => {
    const changed = diff(currentUser, params)

    return (
        !!params.firstname &&
        !!params.lastname &&
        (!params.telephone || /^0\d{9}$/.test(params.telephone)) &&
        Object.keys(changed).length > 0
    )
}
