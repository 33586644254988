import { CREATE_CONSUMER_FAIL } from "../../types";

export const failureCallback = ({ response, dispatch }) => {
    try {
        const message = response.body.message
        dispatch({
            type: CREATE_CONSUMER_FAIL,
            message,
        })

        if (message === 'Email already exists') {
            return 'email'
        }

        if (message === 'Invalid zip code') {
            return 'zipcode'
        }

        if (message === 'Invalid address') {
            return 'address'
        }

        if (message === 'Invalid signup data') {
            return 'data'
        }

        return false
    } catch (error) {
        dispatch({ type: CREATE_CONSUMER_FAIL })
        return false
    }
}
