import React from 'react'
import { Message, Grid, Image, Header, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'

import { addImgPrefix } from '../../../../utils'

import Styles from './style.module.css'

export const CheckoutOrderConfirmed = ({ location: { search } }) => (
    <Grid>
        <Grid.Row className={Styles.ImageRow}>
            <Grid.Column width={16}>
                <Image
                    src={addImgPrefix('apple.svg')}
                    alt='Logo Pomme'
                    size="small"
                    centered
                />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={16}>
                <div className={Styles.Upper}>
                    <Header
                        as="h2"
                        textAlign="center"
                    >
                        <Header.Content>
                            Votre commande est bien confirmée.
                            <Header.Subheader>
                                Merci d'avoir fait confiance à Kelbongoo !
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </div>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column
                width={16}
                textAlign="center"
            >
                <Link
                    replace
                    className={Styles.OrderLink}
                    to={`/account/orders/${search.split('order_id=')[1]}`}
                >
                    <Icon name="circle right arrow" />
                    Voir le d&eacute;tail de ma commande
                </Link>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginTop: '25px' }}>
            <Grid.Column
                width={16}
                textAlign="center"
            >
                <Message
                    className={Styles.Message}
                    icon="envelope"
                    size="tiny"
                    header="Un mail de confirmation vous a été envoyé"
                    content="Il contient toutes les infos sur votre commande et comment la récupérer."
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

export default withRouter(CheckoutOrderConfirmed)
