import * as Types from '../actions/types'

const cart = (state = { items: {}, list: [] }, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            // redux persist
            return {
                ...state,
                ...(action.payload && action.payload.cart
                    ? {
                        list: action.payload.cart.list,
                        items: action.payload.cart.items,
                        globalorder_id: action.payload.cart.globalorder_id,
                    }
                    : {}),
            }

        case Types.UPDATE_CART_ITEM:
            return {
                list: action.list,
                cartChange: action.cartChange,
                items: action.items,
                timeout: action.timeout,
                globalorder_id: action.globalorder_id,
            }

        case Types.EMPTY_CART:
            return {
                list: [],
                cartChange: undefined,
                items: {},
                globalorder_id: undefined,
                timeout: undefined,
            }

        case Types.HIDE_UPDATE_CART_NOTIFICATION:
            return {
                ...state,
                timeout: null,
            }

        default:
            return state
    }
}

export default cart
