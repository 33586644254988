import * as Types from '../actions/types'

const producersList = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.PRODUCERS_LIST_UPDATE:
            return action.list

        case Types.RESET_INIT:
            return []

        default:
            return state
    }
}

export default producersList
