import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Modal, Header, Image, Button, Grid } from 'semantic-ui-react'

import Navet from '../../../../assets/apple.svg'

export class FailedPaymentOrderModalView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: this.props.showModal,
        }
        this.onClose = this.onClose.bind(this)
        this.onWarningSeen = this.onWarningSeen.bind(this)
    }

    static propTypes = {
        showModal: PropTypes.bool.isRequired,
        onFailedPaymentOrderWarningSeen: PropTypes.func,
    }

    onWarningSeen() {
        this.props.onFailedPaymentOrderWarningSeen()
        this.setState({ showModal: false })
    }

    onClose() {
        this.setState({ showModal: false })
    }

    render() {
        return (
            <Modal
                closeIcon={{ color: 'red', name: 'close' }}
                open={this.state.showModal}
                onClose={this.onClose}
                size="tiny"
                style={{ textAlign: 'center' }}
                data-testid="failed-payment-order-modal"
            >
                <Modal.Content
                    image={true}
                    scrolling={true}
                >
                    <Modal.Description>
                        <Header as="h2">Une seconde...</Header>
                        <Image
                            style={{ width: 'auto' }}
                            centered={true}
                            size="medium"
                            src={Navet}
                            wrapped
                        />
                        <p style={{ marginTop: '10px' }}>
                            Vous avez une commande en attente de
                            r&egrave;glement !
                        </p>
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Link to="/account/orders">
                                    <Button
                                        icon
                                        labelPosition="right"
                                        primary
                                        fluid
                                        onClick={this.onWarningSeen}
                                    >
                                        J'y vais
                                    </Button>
                                </Link>
                            </Grid.Column>

                            <Grid.Column>
                                <Button
                                    icon
                                    labelPosition="right"
                                    basic
                                    fluid
                                    onClick={this.onClose}
                                >
                                    Je laisse tomber
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Actions>
            </Modal>
        )
    }
}
