import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Header, Segment, Dimmer, Loader } from 'semantic-ui-react'
import {
    retryOnlinePayment,
    setInStorePayment,
    fetchLocales,
    fetchConsumerOrderDetail,
    clearError,
    fetchConsumerPaymentTokens,
    deleteConsumerPaymentToken,
} from '../../../../actions'

import { parseUrlQuery } from '../../../../utils'

import { CheckoutPaymentConfirmationInner } from '../CheckoutPaymentConfirmationInner'

import { retryPayment, mapStateToProps } from './helpers'

export class CheckoutRetryPayment extends Component {
    constructor(props) {
        super(props)

        let query = parseUrlQuery(this.props.location.search)

        let order_id = query.order_id
        let locale = query.locale

        this.state = {
            order_id,
            locale,
            paymentType: 'CB',
            confirming: false,
            confirmed: false,
            loading: true,
            showOfflinePayment: true,
        }
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
        this.retryPayment = retryPayment.bind(this)
        this.handleDeleteToken = this.handleDeleteToken.bind(this)
    }

    UNSAFE_componentWillMount() {
        Promise.all([
            this.props.fetchLocales(),
            this.props.fetchConsumerOrderDetail(this.state.order_id),
            this.props.fetchConsumerPaymentTokens(),
        ]).finally(() => this.setState({ loading: false }))
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleErrorDismiss() {
        this.setState({
            confirming: false,
            confirmed: false,
        })
        this.props.clearServerError()
    }

    handleDeleteToken(token) {
        this.props.deleteConsumerPaymentToken(token)
            .then((result) => {
                window.location.reload()
            })
            .catch(error => {
                console.error('Error deleting token:', error);
            });
    }

    render() {
        let { confirming, confirmed, order_id, loading, paymentFormData } =
            this.state

        let {
            blockInStorePayment,
            blockOrderChanges,
            isHomeDelivery,
            isOrderLocalePrivate,
            loadingPaymentTokens,
            paymentTokens,
            serverError,
        } = this.props

        if ((loading || loadingPaymentTokens) && !serverError) {
            return (
                <Segment>
                    <Dimmer
                        active
                        inverted
                        style={{ height: '300px' }}
                    >
                        <Loader
                            inverted
                            content="Chargement"
                        />
                    </Dimmer>
                </Segment>
            )
        }

        return (
            <CheckoutPaymentConfirmationInner
                blockInStorePayment={blockInStorePayment}
                blockOrderChanges={blockOrderChanges}
                confirmed={confirmed}
                confirming={confirming}
                handleConfirmation={this.retryPayment}
                handleErrorDismiss={this.handleErrorDismiss}
                handleDeleteToken={this.handleDeleteToken}
                isHomeDelivery={isHomeDelivery}
                isOrderLocalePrivate={isOrderLocalePrivate}
                order_id={order_id}
                paymentFormData={paymentFormData}
                paymentTokens={paymentTokens}
                serverError={serverError}
            >
                <div style={{ marginBottom: '1em' }}>
                    <Header
                        as="h2"
                        textAlign="center"
                    >
                        Nouvelle tentative de règlement
                        <Header.Subheader>
                            Confirmer votre mode de paiement
                        </Header.Subheader>
                    </Header>
                </div>
            </CheckoutPaymentConfirmationInner>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        retryOnlinePayment: order_id => dispatch(retryOnlinePayment(order_id)),
        setInStorePayment: order_id => dispatch(setInStorePayment(order_id)),
        fetchLocales: () => dispatch(fetchLocales()),
        fetchConsumerOrderDetail: order_id =>
            dispatch(fetchConsumerOrderDetail(order_id)),
        clearServerError: () => dispatch(clearError('RETRY_PAYMENT')),
        fetchConsumerPaymentTokens: () =>
            dispatch(fetchConsumerPaymentTokens()),
        deleteConsumerPaymentToken: token => dispatch(deleteConsumerPaymentToken(token)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutRetryPayment)
