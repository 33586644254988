import * as Types from '../../types'
import { scheduleSyncOrder } from '../scheduleSyncOrder'
import { handleGtm } from './handleGtm'
import { isQuantityValid } from './isQuantityValid'
import { handleKlaviyoAdd } from './handleKlaviyoAdd'
import { isDataValid } from './isDataValid'

/**
 * action handler for cart item quantity changes / triggering server-side orderreservation updates
 * if quantity is 0, it should remove the item... and if the only item is removed, it should empty the cart
 * if quantity is < 0 or > MAX, it should just do nothing... UNLESS the currentUser has permission to go over the max
 * it should do a final check for any degenerate cart items before dispatching the update cart action
 * @param {string} productId - producerproduct_id for the item to update / add / remove
 * @param {number} quantity - new quantity for the item
 * @param {boolean} showCartNotification - should we swing down the cart total display ?
 * @param {date} currentDate - for testing only
 * @returns
 */

export const updateCartItem = ({
    productId,
    quantity,
    lastAction,
    showCartNotification = true,
    currentDate,
}) => {
    return (dispatch, getState) => {
        // close the swing-down cart display in 3 seconds
        const timeout =
            showCartNotification &&
            window.setTimeout(() => {
                dispatch({
                    type: Types.HIDE_UPDATE_CART_NOTIFICATION,
                })
            }, 2000)

        const {
            cart: { items, list },
            products,
            currentGlobalOrder: { _id: globalorder_id },
            currentUser,
        } = getState()

        if (!isQuantityValid({ currentUser, quantity })) {
            return
        }

        const product = products[productId]

        // we save the order in which items were added
        let updatedItems = Object.assign({}, items)
        let updatedList
        let cartChange

        if (quantity === 0) {
            // gtm cart item removed
            handleGtm({ type: 'remove', product })

            // filter out the item if quantity is 0
            updatedList = list.filter(i => i !== productId)
            delete updatedItems[productId]

            cartChange = { product, change: 'remove' }
        }
        else if (lastAction === 'remove') {
            // change quantity of existing product
            updatedList = [...list]
            updatedItems[productId] = quantity

            cartChange = { product, change: 'remove' }
        }
        else if (list.indexOf(productId) === -1) {
            handleGtm({ type: 'add', product })

            try {
                handleKlaviyoAdd({ currentUser, productId })
            } catch (e) {
                console.log(e)
            }

            // a new product
            updatedList = [...list, productId]
            updatedItems[productId] = quantity

            cartChange = { product, change: 'add' }
        }
        else if (lastAction === 'add') {
            // change quantity of existing product
            updatedList = [...list]
            updatedItems[productId] = quantity

            cartChange = { product, change: 'add' }
        }
        else {
            return
        }

        // last check on the items update before dispatching...
        if (!isDataValid({ updatedList, updatedItems })) {
            return
        }

        // update cart item in the store
        dispatch({
            type: Types.UPDATE_CART_ITEM,
            cartChange: cartChange,
            list: updatedList,
            items: updatedItems,
            globalorder_id,
            timeout: showCartNotification && timeout,
        })

        // SET server update
        scheduleSyncOrder(dispatch, currentDate)

        if (quantity === 0 &&
            (updatedList.length === 0 ||
                Object.keys(updatedItems).length === 0)
        ) {
            /* if either updatedList or updatedItems is empty, empty the cart */

            setTimeout(() => {
                dispatch({
                    type: Types.EMPTY_CART,
                })
            }, 1000);
        }

        return true
    }
}
