import React from 'react'
import { Table } from 'semantic-ui-react'
import  Responsive  from "../../../../ui/Responsive"
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'

export const CartListAddedChargeListItem = ({ item }) => (
    <Table.Row key="added_charge">
        <Responsive
            as={Table.Cell}
            colSpan={2}
            maxWidth={MAX_WIDTH.SM}
        >
            <div style={{ float: 'left' }}>
                <b>{item.title}</b>
            </div>
        </Responsive>
        <Responsive
            as={Table.Cell}
            colSpan={4}
            minWidth={MIN_WIDTH.MD}
        >
            <div style={{ float: 'left' }}>
                <b>{item.title}</b>
            </div>
        </Responsive>

        <Table.Cell
            textAlign="center"
            singleLine
        >
            <strong>{formatMoney.run(item.amount)}</strong>
        </Table.Cell>
    </Table.Row>
)
