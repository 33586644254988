export const getPaymentLabelData = order => {
    if (order.isPaid()) {
        if (order.hasOnlinePayment()) {
            return {
                color: 'green',
                icon: 'check',
                text: 'Payée par carte',
            }
        }
        if (order.payments && order.payments.length > 0 && order.payments[0].method === 4) {
            return {
                color: 'green',
                icon: 'check',
                text: 'Payée en avoirs',
            }
        }
        return {
            color: 'blue',
            icon: 'check',
            text: 'Payée sur place',
        }
    } else {
        if (order.hasCancelledOnlinePayment()) {
            return {
                color: 'grey',
                icon: 'remove',
                text: 'Paiement par carte annulé',
            }
        }
        if (order.hasFailedOnlinePayment() && order.status === 0) {
            return {
                color: 'red',
                icon: 'remove',
                text: 'Paiement par carte échoué',
            }
        }
        if (order.status > 0) {
            return {
                color: 'orange',
                icon: 'warning circle',
                text: 'Paiement lors du retrait',
            }
        }
        // default no payment
        return {
            color: 'red',
            icon: 'remove',
            text: 'Paiement par carte échoué',
        }
    }
}
