import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

export const UnavailableButton = ({ isZoom, buttonClassName }) => {
    return isZoom ? (
        <Button
            primary
            size="huge"
            disabled
            className={buttonClassName}
        >
            Épuisé
        </Button>
    ) : (
        <Fragment>
            <Responsive maxWidth={MAX_WIDTH.XS}>
                <Button
                    size="medium"
                    disabled
                    className={buttonClassName}
                    title="soldout"
                    icon
                    style={{ margin: '0' }}
                >
                    <Icon name="remove" />
                </Button>
            </Responsive>

            <Responsive
                minWidth={MIN_WIDTH.SM}
                maxWidth={MAX_WIDTH.SM}
            >
                <Button
                    primary
                    size="medium"
                    disabled
                    className={buttonClassName}
                    title="soldout"
                >
                    Épuisé
                </Button>
            </Responsive>

            <Responsive minWidth={MIN_WIDTH.MD}>
                <Button
                    primary
                    size="large"
                    disabled
                    className={buttonClassName}
                    title="soldout"
                >
                    Épuisé
                </Button>
            </Responsive>
        </Fragment>
    )
}
