import * as Types from '../actions/types'

const nextGlobalOrdersList = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.NEXT_GLOBALORDERS_LIST_UPDATE:
            return action.nextGlobalOrders

        default:
            return state
    }
}

export default nextGlobalOrdersList
