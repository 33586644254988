export const getCenter = locales => {
    const lats = locales.map(
        l => l.geolocation && (l.geolocation.lat || 48.872787)
    )
    const lngs = locales.map(
        l => l.geolocation && (l.geolocation.lng || 2.399944)
    )

    if (window.innerWidth < 500) {
        return {
            lat: (Math.max(...lats) + Math.min(...lats)) / 2 + 0.01,
            lng: (Math.max(...lngs) + Math.min(...lngs)) / 2,
        }
    }
    else {
        return {
            lat: (Math.max(...lats) + Math.min(...lats)) / 2 + 0.02,
            lng: (Math.max(...lngs) + Math.min(...lngs)) / 2,
        }
    }
}
