import { combineReducers } from 'redux'

import activeGlobalOrdersList from './activeGlobalOrdersList'
import available from './available'
import cart from './cart'
import categories from './categories'
import livdomZipcodes from './livdomZipcodes'
import consumercarts from './consumercarts'
import consumerSignupReasons from './consumerSignupReasons'
import creditsHistory from './creditsHistory'
import currentFeedback from './currentFeedback'
import currentGlobalOrder from './currentGlobalOrder'
import currentLocale from './currentLocale'
import currentOrder from './currentOrder'
import currentUser from './currentUser'
import families from './families'
import feedbackHistory from './feedbackHistory'
import initRequest from './initRequest'
import localeList from './localeList'
import locales from './locales'
import nextGlobalOrdersList from './nextGlobalOrdersList'
import order from './order'
import orderRecovery from './orderRecovery'
import ordersHistory from './ordersHistory'
import producers from './producers'
import producersContent from './producersContent'
import producersList from './producersList'
import products from './products'
import productsHeaderContent from './productsHeaderContent'
import search from './search'
import session from './session'
import switchTo from './switchTo'

const rootReducer = combineReducers({
    activeGlobalOrdersList,
    available,
    cart,
    livdomZipcodes,
    categories,
    consumercarts,
    consumerSignupReasons,
    creditsHistory,
    currentFeedback,
    currentGlobalOrder,
    currentLocale,
    currentOrder,
    currentUser,
    families,
    feedbackHistory,
    initRequest,
    localeList,
    locales,
    nextGlobalOrdersList,
    order,
    orderRecovery,
    ordersHistory,
    producers,
    producersContent,
    producersList,
    products,
    productsHeaderContent,
    search,
    session,
    switchTo,
})

export default rootReducer
