import 'whatwg-fetch'
import * as Types from '../../../../actions/types'
import { API_ADDRESS } from '../../../../app-constants.js'
import { confirmSwitchTo } from '../../../../actions/distribution/switchTo/confirm'

/**
 * fetch from the api a list of all currently available quantities for all products
 * available in the current globalorder
 * this method is called every X seconds while a user is connected, to minimise the
 * possibility of over-ordering products
 * @param {object} currentGlobalOrder - globalorder object for the current sale
 * @param {date} currentDate - optional peg for current date, testing only
 * @returns {function} - a function to be dispatched
 */

const fetchAPI = function (url, fallback) {
    return fetch(`${API_ADDRESS}${url}`).then(function (response) {
        if (response.status === 200) {
            return response.json()
        } else {
            return fallback
        }
    })
}

export const fetchSubstitutes = ({
    schedule: { globalOrder },
    locale,
    cart,
}) => {
    return (dispatch, getState) => {
        dispatch({
            type: Types.FETCH_SUBSTITUTE_REQUEST,
        })

        return Promise.all(
            cart.list.map(async producerproduct_id => {
                const r = await fetchAPI(
                    `/get-substitutables?locale=${locale.code}&globalorder_id=${globalOrder._id}&producerproduct_id=${producerproduct_id}`,
                    { substitutables: [] }
                )
                const substitutes = await r.substitutables
                return {
                    producerproduct_id,
                    substitutes,
                }
            })
        )
            .then(producerproductSubstitutes => {
                // we'll need a list of new products
                return fetchAPI(
                    `/init?locale=${locale.code}&globalorder_id=${globalOrder._id}`,
                    { products: [] }
                )
                    .then(({ products }) => {
                        return (products
                            ? products.reduce((r, i) => {
                                r[i.producerproduct_id] = i
                                return r
                            }, {})
                            : {}
                        )
                    })
                    .then(targetProducts => {
                        // we'll need a list of new products
                        const { currentGlobalOrder, currentLocale } = getState()

                        return fetchAPI(
                            `/init?locale=${currentLocale.code}&globalorder_id=${currentGlobalOrder._id}`,
                            { products: [] }
                        )
                            .then(({ products }) =>
                                products
                                    ? products.reduce((r, i) => {
                                        r[i.producerproduct_id] = i
                                        return r
                                    }, {})
                                    : {}
                            )
                            .then(originalProducts => ({
                                targetProducts,
                                originalProducts,
                                producerproductSubstitutes,
                            }))
                    })
            })
            .then(
                ({
                    originalProducts,
                    targetProducts,
                    producerproductSubstitutes,
                }) => {
                    const removedProducts = []
                    const substituteProducts = []
                    const availableProducts = []

                    // CREATE 3 SWITCH_TO OBJECTS
                    producerproductSubstitutes.forEach(
                        ({ producerproduct_id, substitutes }) => {
                            if (targetProducts[producerproduct_id]) {
                                // THESE ARE AVAILABLE
                                availableProducts.push({
                                    ...targetProducts[producerproduct_id],
                                    quantity: cart.items[producerproduct_id],
                                })
                            }
                            else if (substitutes.length === 0) {
                                // REMOVE THESE
                                removedProducts.push({
                                    ...originalProducts[producerproduct_id],
                                    quantity: cart.items[producerproduct_id],
                                })
                            } else if (
                                substitutes.indexOf(producerproduct_id) !== -1
                            ) {
                                // THESE ARE AVAILABLE
                                availableProducts.push({
                                    ...targetProducts[producerproduct_id],
                                    quantity: cart.items[producerproduct_id],
                                })
                            } else {
                                // THESE ARE SUBSTITUTES
                                // does this sub already exist?
                                const index = substituteProducts.findIndex(
                                    i => i.producerproduct_id === substitutes[0]
                                )
                                if (index !== -1) {
                                    substituteProducts[index] = {
                                        ...substituteProducts[index],
                                        quantity:
                                            substituteProducts[index].quantity +
                                            cart.items[producerproduct_id],
                                    }
                                } else {
                                    substituteProducts.push({
                                        ...targetProducts[substitutes[0]],
                                        original_producerproduct_id:
                                            producerproduct_id,
                                        quantity:
                                            cart.items[producerproduct_id],
                                    })
                                }
                            }
                        }
                    )

                    const confirmable =
                        removedProducts.length === 0 &&
                        substituteProducts.length === 0

                    dispatch({
                        type: Types.FETCH_SUBSTITUTE_SUCCESS,
                        removedProducts,
                        substituteProducts,
                        availableProducts,
                        confirmable,
                        lastUpdate: new Date(),
                    })

                    return true
                    // if (confirmable) {
                    //     return dispatch(confirmSwitchTo())
                    // } else {
                    //     return true
                    // }
                }
            )
            .catch(error => {
                dispatch({
                    type: Types.FETCH_SUBSTITUTE_FAIL,
                    error,
                })
            })
    }
}