import React, { Fragment } from 'react'
import { Header } from 'semantic-ui-react'

export const ProductIngredients = ({ ingredientsMarkup }) => (
    <Fragment>
        <Header>
            Ingrédients
            <Header.Subheader></Header.Subheader>
        </Header>
        <div dangerouslySetInnerHTML={ingredientsMarkup}></div>
    </Fragment>
)
