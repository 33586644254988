import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import Datatable from '../../../ui/Datatable'
import EmptyFooter from '../../../ui/EmptyFooter'
import InfoModal from '../../../ui/InfoModal'

import {
    fetchConsumerOrders,
    clearError,
    fetchLocales,
} from '../../../../actions'
import { Order, GlobalOrder } from '../../../../models'

import ErrorBoundary from '../../../layout/ErrorBoundary'
import { AccountPageOrdersRow } from './AccountPageOrdersRow'
import { formatItems } from './formatItems'

import Styles from './styles.module.css'

export class OrdersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            loading:
                typeof props.loading !== 'undefined' ? props.loading : true,
            loadingMore: false,
            retryPayment: props.retryPayment || false,
        }

        this.fetchMoreOrders = this.fetchMoreOrders.bind(this)
    }

    componentDidMount() {
        let ensureActiveGlobalOrders = this.props.initialized
            ? () => Promise.resolve()
            : this.props.fetchLocales

        return Promise.all([
            ensureActiveGlobalOrders(),
            this.props.fetchConsumerOrders(),
        ]).finally(() => {
            this.setState({ loading: false })
            window.scrollTo(0, 0)
        })
    }

    fetchMoreOrders() {
        this.setState({
            currentPage: this.state.currentPage + 1,
            loadingMore: true,
        })

        return this.props.fetchConsumerOrders(this.state.currentPage + 1)
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    handleRetryPayment(order_id) {
        this.setState({
            retryPayment: order_id,
        })
    }

    render() {
        const {
            orders = [],
            hasMoreOrders,
            locales,
            serverError,
            activeGlobalOrdersList = [],
        } = this.props

        if (this.state.retryPayment) {
            return (
                <Redirect
                    to={`/checkout/retry-payment?order_id=${this.state.retryPayment}`}
                />
            )
        }

        if (!this.state.loading && (!orders || orders.length === 0)) {
            return (
                <EmptyFooter
                    text="Aucune commande trouvée"
                    icon="frown"
                />
            )
        }

        let items = formatItems({
            orders,
            activeGlobalOrdersList,
            handleRetryPayment: this.handleRetryPayment.bind(this),
            locales,
        })

        return (
            <div className={Styles.Container}>
                <div className={Styles.CreditsLink}>
                    <Link to="/account/credits">Mes cr&eacute;dits &rarr;</Link>
                </div>

                <InfoModal
                    show={!!serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />

                <Datatable
                    listParams={{
                        header: {
                            columns: [
                                { name: 'distribution_date', title: 'Date' },
                                {
                                    name: 'locale',
                                    title: 'Boutique',
                                    hidden: 600,
                                },
                                {
                                    name: 'units_total',
                                    title: 'Articles',
                                    hidden: 600,
                                },
                                {
                                    name: 'amount_total',
                                    title: 'Montant',
                                    width: '3em',
                                },
                                { name: 'status', title: 'Statut' },
                            ],
                        },
                        RowComponent: AccountPageOrdersRow,
                        fetchMore: this.fetchMoreOrders,
                    }}
                    items={this.state.loading ? [] : items}
                    hasMore={hasMoreOrders}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

OrdersList.propTypes = {
    initialized: PropTypes.bool,
    fetchLocales: PropTypes.func.isRequired,
    fetchConsumerOrders: PropTypes.func.isRequired,
    clearServerError: PropTypes.func.isRequired,
    orders: PropTypes.array,
    hasMoreOrders: PropTypes.bool,
    locales: PropTypes.array,
    serverError: PropTypes.bool,
    activeGlobalOrdersList: PropTypes.array,
}

export const AccountPageOrders = ({
    orders,
    hasMoreOrders,
    fetchConsumerOrders,
    fetchLocales,
    locales,
    activeGlobalOrdersList,
    initialized,
}) => (
    <ErrorBoundary page="account-page-orders">
        <OrdersList
            orders={orders}
            hasMoreOrders={hasMoreOrders}
            fetchConsumerOrders={fetchConsumerOrders}
            locales={locales}
            fetchLocales={fetchLocales}
            activeGlobalOrdersList={activeGlobalOrdersList}
            initialized={initialized}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({
    currentUser,
    ordersHistory,
    currentLocale,
    locales,
    activeGlobalOrdersList,
    initRequest,
}) => {
    return {
        currentUser,
        activeGlobalOrdersList: activeGlobalOrdersList.map(
            g => new GlobalOrder(g)
        ),
        currentLocale,
        orders:
            ordersHistory &&
            ordersHistory.orders &&
            ordersHistory.orders.map(order => new Order(order)),
        hasMoreOrders:
            ordersHistory &&
            typeof ordersHistory.count !== 'undefined' &&
            typeof ordersHistory.orders !== 'undefined' &&
            ordersHistory.count > ordersHistory.orders.length,
        locales,
        initialized: initRequest && initRequest.ready,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchConsumerOrders: page => dispatch(fetchConsumerOrders(page)),
        clearServerError: () => dispatch(clearError('FETCH_CONSUMER_ORDERS')),
        fetchLocales: () => dispatch(fetchLocales()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageOrders)
