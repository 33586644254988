/** This function allows you to highlight the special ingredients
 * by defining a marker
 * eg: let stringTest = 'salut les copains  __bbbbbb__ moi c\'est __toto__ il y a parmi __moi__ des **ingredients** speciaux c\'est tous'
 */

export const getProductSpecialIngredients = ingredients => {
    let markerBegin = /\b__(\S)/g;
    let markerEnd = /(\S)__\b/g;
    let tagBegin = '<strong>';
    let tagEnd = '</strong>';

    let specialIngredients = ingredients
        ? ingredients.replace(markerBegin, tagBegin + '$1').replace(markerEnd, '$1' + tagEnd)
        : ingredients;

    return '<p>' + specialIngredients + '</p>';
}
