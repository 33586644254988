import React from 'react'
import { Table, Image } from 'semantic-ui-react'
import Responsive from "../../../../../ui/Responsive"
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'
import { addImgPrefix } from '../../../../../../utils'
import { MAX_WIDTH, MIN_WIDTH } from '../../../../../../app-constants'
import Styles from './styles.module.css'

export const CartItem = ({ item }) => (
    <Table.Row
        key={item.producerproduct_id}
        data-testid="cart-item"
    >
        <Table.Cell textAlign="center">{item.quantity}</Table.Cell>

        <Table.Cell title={[item.product_name, item.unit_display].join(' - ')}>
            <div className={Styles.Products}>
                {item.src ? (
                    <Responsive
                        minWidth={MIN_WIDTH.MD}
                        style={{ float: 'left' }}
                    >
                        <Image
                            src={addImgPrefix(item.src, 'thumbnail')}
                            className={Styles.ImgProduct}
                            alt={item.product_name}
                        />
                    </Responsive>
                ) : null}

                <div
                    className={Styles.DetailProduct}
                    title="product-info"
                >
                    <div className={Styles.ProductName}>
                        {item.product_name}
                    </div>
                    <div className={Styles.ProducerName}>
                        {item.producer_name}
                    </div>
                    <Responsive maxWidth={MAX_WIDTH.SM}>
                        {formatMoney.run(item.consumer_price, true)} /{' '}
                        {item.unit_display}
                    </Responsive>
                </div>
            </div>
        </Table.Cell>

        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
            textAlign="center"
            singleLine
        >
            {formatMoney.run(item.consumer_price, true)}
        </Responsive>

        <Responsive
            as={Table.Cell}
            minWidth={MIN_WIDTH.MD}
            textAlign="center"
            singleLine
        >
            {item.unit_display}
        </Responsive>

        <Table.Cell
            textAlign="center"
            singleLine
        >
            <b>{formatMoney.run(item.quantity * item.consumer_price, true)}</b>
        </Table.Cell>
    </Table.Row>
)
