import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Message } from 'semantic-ui-react'

/**
 *
 * @param {*} error a JSON Object
 * @param {*} homeDeliveryConditions
 * @returns
 */
export const getErrorMessage = (error, homeDeliveryConditions) => {
    let innerHtml

    switch (JSON.parse(error).message) {
        case 'Consumer password added successfully':
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    <b>Cette adresse mail existe d&eacute;j&agrave; !</b> <br />
                    Un mail de changement de mot de passe a &eacute;t&eacute;
                    envoy&eacute; &agrave; l'adresse fournie.
                </div>
            )
            break
        case 'email':
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    <b>Cette adresse mail existe d&eacute;j&agrave; !</b> <br />
                    Si vous avez oubli&eacute; votre mot de passe,
                    veuillez&nbsp;
                    <Link to="/mot-de-passe-oublie">
                        <b>cliquer ici</b>
                    </Link>
                    &nbsp;pour le r&eacute;cup&eacute;rer.
                </div>
            )
            break
        case 'zipcode':
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    <b>
                        Livraison &agrave; domicile pas encore disponible pour
                        votre code postal.
                    </b>{' '}
                    <br />
                    {homeDeliveryConditions}
                </div>
            )
            break
        case 'address':
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    <b>Rue ou num&eacute;ro de rue non reconnu</b> <br />
                    {homeDeliveryConditions}
                </div>
            )
            break
        case 'data':
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    <b>Les donn&eacute;es fournies ne sont pas valides</b>
                </div>
            )
            break
        default:
            innerHtml = (
                <div style={{ textAlign: 'center' }}>
                    Impossible de cr&eacute;er ce compte: une erreur s'est
                    produite.
                </div>
            )
            break
    }

    return (
        <Message
            negative
            icon
        >
            <Icon name="warning sign" /> {innerHtml}
        </Message>
    )
}
