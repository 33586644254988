import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import React from 'react'
import { Button } from 'semantic-ui-react'
import Responsive  from "../../../../ui/Responsive"

import Styles from './styles.module.css'

const CheckoutPaymentConfirmationButton = ({
    paymentType,
    confirming,
    handleConfirmation,
}) => (
    <div
        className={Styles.PersistentCheckoutBar}
        data-testid="confirmation-button-container"
    >
        <div className={Styles.ConditionsContainer}>
            <i>
                En cliquant sur &laquo; Finaliser la commande &raquo;, je
                d&eacute;clare avoir lu et accept&eacute;&nbsp;
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.kelbongoo.com/cgu-cgv"
                >
                    les conditions g&eacute;n&eacute;rales de vente
                </a>
            </i>
        </div>

        <div>
            <Responsive maxWidth={MAX_WIDTH.SM}>
                <Button
                    color="blue"
                    disabled={paymentType ? false : true}
                    size="huge"
                    content="Finaliser la commande"
                    icon="right arrow"
                    labelPosition="right"
                    fluid
                    onClick={handleConfirmation}
                    loading={confirming}
                    className={Styles.PaymentButton}
                />
            </Responsive>

            <Responsive minWidth={MIN_WIDTH.MD}>
                <Button
                    color="blue"
                    disabled={paymentType ? false : true}
                    size="huge"
                    content="Finaliser la commande"
                    icon="right arrow"
                    labelPosition="right"
                    onClick={handleConfirmation}
                    loading={confirming}
                    className={Styles.PaymentButton}
                />
            </Responsive>
        </div>
    </div>
)

export default CheckoutPaymentConfirmationButton
