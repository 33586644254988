import React from 'react'
import { Grid } from 'semantic-ui-react'

import QuickFilter from '../QuickFilter'
import SearchFilter from '../SearchFilter'
import DaterangeFilter from '../DaterangeFilter'
import { getColumnCount } from './getColumnCount'
import { getInnerColumnCount } from './getInnerColumnCount'

const SearchFilterContainer = ({
    quickFilterParams,
    searchFilterParams,
    daterangeFilterParams,
}) => {
    let columns = getColumnCount({
        quickFilterParams,
        searchFilterParams,
        daterangeFilterParams,
    })
    let innerColumns = getInnerColumnCount({
        quickFilterParams,
        daterangeFilterParams,
    })
    return (
        <Grid
            stackable
            columns={columns}
        >
            <Grid.Row>
                <Grid.Column>
                    {searchFilterParams && (
                        <SearchFilter {...searchFilterParams} />
                    )}
                </Grid.Column>
                <Grid.Column>
                    <Grid
                        stackable
                        columns={innerColumns}
                    >
                        <Grid.Row>
                            {quickFilterParams && (
                                <Grid.Column>
                                    <QuickFilter {...quickFilterParams} />
                                </Grid.Column>
                            )}
                            {daterangeFilterParams && (
                                <Grid.Column>
                                    <DaterangeFilter
                                        {...daterangeFilterParams}
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SearchFilterContainer
