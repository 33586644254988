import { trackConnected } from '../../../utils'

export const handleSubmit = function (event) {
    event.preventDefault()

    const { reset_password_token } = this.props.match.params

    const { mot_de_passe, confirmation_mot_de_passe } = this.state

    if (mot_de_passe !== confirmation_mot_de_passe) {
        this.setState({ error: true })
        return
    }

    if (this.state.error) {
        return
    }

    this.props
        .resetPassword({
            reset_password_token,
            password: mot_de_passe,
        })
        .then(orderreservation_id => {
            if (this.props.currentUser && this.props.currentUser._id) {
                trackConnected({ _id: this.props.currentUser._id })
            }

            if (!orderreservation_id) {
                return this.props.history.push('/')
            }
        })
}
