import React from 'react'
import { Icon } from 'semantic-ui-react'

const ClearButton = ({ onClear }) => (
    <Icon
        link
        name="close"
        onClick={onClear}
    />
)

export default ClearButton
