import React from 'react'
import { Table, Button } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

export const CartItem = ({ item, bumpUp, bumpDown }) => (
    <Table.Row
        key={item.producerproduct_id}
        title={[item.product_name, item.unit_display].join(' - ')}
    >
        <Table.Cell width={5}>
            <Button.Group size="tiny">
                <Button
                    icon="minus"
                    primary
                    onClick={bumpDown}
                    title="less"
                />

                <Button.Or text={item.quantity} />

                {item.quantity >= item.available || item.quantity >= 8 ? (
                    <Button
                        icon="plus"
                        primary
                        disabled
                    />
                ) : (
                    <Button
                        icon="plus"
                        primary
                        title="more"
                        onClick={bumpUp}
                    />
                )}
            </Button.Group>
        </Table.Cell>

        <Table.Cell width={13}>
            {`${item.product_name} ${item.unit_display}`}
        </Table.Cell>

        <Table.Cell
            width={4}
            textAlign="right"
        >
            {formatMoney.run(item.quantity * item.consumer_price, true)}
        </Table.Cell>
    </Table.Row>
)
