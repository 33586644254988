import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

export const CartPopupListFooter = ({ handleClose, currentCartValue }) => (
    <Table.Footer fullWidth>
        <Table.Row>
            <Table.HeaderCell colSpan="7">
                <Link
                    to="/cart"
                    onClick={handleClose}
                >
                    <Button
                        floated="left"
                        size="large"
                        primary
                    >
                        Voir le Panier
                    </Button>

                    <Button
                        floated="right"
                        icon
                        color="black"
                        label={{
                            basic: true,
                            content: formatMoney.run(currentCartValue, true),
                        }}
                        labelPosition="right"
                        content="Total"
                        size="large"
                    />
                </Link>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
)

CartPopupListFooter.propTypes = {
    handleClose: PropTypes.func.isRequired,
    currentCartValue: PropTypes.number.isRequired,
}
