import React from 'react'
import PropTypes from 'prop-types'

import { StatusBar } from './StatusBar'

import { EventsList } from './EventsList'
import { InfoListDropdown } from './InfoListDropdown'
import { PaymentHistory } from './PaymentHistory'

import Styles from './styles.module.css'

export const UpperRightView = ({ events, payments, status_name }) => {
    return (
        <div
            className={Styles.Container}
            data-testid="upper-right-view"
        >
            {status_name && (
                <StatusBar
                    status={status_name}
                    align="right"
                />
            )}

            {events && events.length > 0 && (
                <InfoListDropdown buttonText="Évènements">
                    <EventsList
                        events={events}
                        size="small"
                        className={Styles.EventsList}
                    />
                </InfoListDropdown>
            )}

            {payments && (
                <InfoListDropdown buttonText="Reglements">
                    <PaymentHistory
                        history={payments}
                        style={{ padding: '0.5em' }}
                    />
                </InfoListDropdown>
            )}
        </div>
    )
}

UpperRightView.propTypes = {
    status_name: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.object),
    payments: PropTypes.arrayOf(PropTypes.object),
}
