import React, { Component } from 'react'
import { Modal, Header, Button, Icon, Form, Message } from 'semantic-ui-react'
import LinkButton from '../../../../../../ui/LinkButton'

const getOptions = max => {
    let output = []
    for (let i = 0; i < max; i++) {
        output.push({ value: i, text: i, key: i })
    }
    return output
}

export class ItemsListItemEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: props.item.quantity,
            modalOpen: false,
        }
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    handleChange = (e, { value }) => {
        this.setState({ quantity: value })
    }

    onEditItem = () => {
        const originalQuantity = this.props.item.quantity
        const newQuantity = this.state.quantity
        return this.props.item
            .onEditItem({ quantity: originalQuantity - newQuantity })
            .finally(() => this.handleClose())
    }

    render() {
        const { quantity } = this.props.item

        return (
            <Modal
                trigger={
                    <LinkButton
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        href="#"
                        style={{ width: '100%', display: 'block' }}
                        onClick={this.handleOpen}
                        data-testid="item-edit-trigger"
                    >
                        {quantity}
                    </LinkButton>
                }
                closeIcon
                open={this.state.modalOpen}
                onClose={this.handleClose}
                data-testid="item-edit-modal"
            >
                <Header content="Mettre à jour la commande" />
                <Modal.Content>
                    <Message
                        warning
                        size="mini"
                        icon="warning sign"
                        header="Cette opération est irréversible."
                        content="Vous ne pouvez que baisser la quantité pour une commande déjà créée. Pour ajouter des produits, merci de passer une nouvelle commande."
                        style={{ marginBottom: '3em' }}
                    />
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Quantit&eacute; actuel</label>
                                <p
                                    style={{
                                        fontSize: '2em',
                                        textAlign: 'center',
                                    }}
                                >
                                    {quantity}
                                </p>
                            </Form.Field>
                            <Form.Select
                                fluid
                                label="Nouvelle quantité"
                                options={getOptions(quantity)}
                                placeholder="Choisir une nouvelle quantité"
                                onChange={this.handleChange}
                                data-testid="quantity-select"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="yellow"
                        onClick={() => this.onEditItem()}
                    >
                        <Icon name="checkmark" /> Valider
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
