import { wrapClientApiCall } from '../../../utils'

export const removeOrder = ({ order_id }) =>
    wrapClientApiCall({
        actionRoot: 'REMOVE_ORDER',
        url: `api/orders/cancel?order_id=${order_id}`,
        successPayload: data => ({
            order: data.updatedDoc,
        }),
    })
