import * as Types from '../actions/types'

const localeList = (state = {}, action = { type: 'not_set' }) => {
    switch (action.type) {
        case Types.LOCALES_LIST_UPDATE:
            return action.list

        default:
            return state
    }
}

export default localeList
