/* istanbul ignore file */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    Grid,
    Message,
    Button,
    Segment,
    Dimmer,
    Loader,
    Table,
    Form,
    Image,
    Rating,
    TextArea,
    Icon,
    GridColumn,
} from 'semantic-ui-react'

import formatDate from '@kelbongoo/shared-methods/utils/formatDate'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import { ProducerProductFeedback } from '../../../../models/producerproductfeedback'

import {
    fetchConsumerFeedbackDetail,
    createFeedback,
    clearError,
} from '../../../../actions'
import { handleServerError, addImgPrefix } from '../../../../utils'

import InfoModal from '../../../ui/InfoModal'
import ErrorBoundary from '../../../layout/ErrorBoundary'

export class FeedbackDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            init: true,
            feedback_quality: '',
            feedback_quality_price: '',
            feedback_message: '',
        }

        this.createFeedback = this.createFeedback.bind(this)
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    componentDidMount() {
        const path = this.props.pathname.split('/')
        const feedback_id = path[path.length - 1]
        this.props
            .fetchConsumerFeedbackDetail(feedback_id)
            .then(() => {
                this.setState({
                    init: false,
                })
            })
            .catch(handleServerError.bind(this, true))
    }

    handleQuality = (e, { rating }) =>
        this.setState({ feedback_quality: rating })
    handleQualityPrice = (e, { rating }) =>
        this.setState({ feedback_quality_price: rating })
    handleMessage = (e, { value }) => this.setState({ feedback_message: value })

    createFeedback(e) {
        const path = this.props.pathname.split('/')
        const feedback_id = path[path.length - 1]
        return this.props
            .createFeedback({
                feedback_id,
                feedback_quality: this.state.feedback_quality,
                feedback_quality_price: this.state.feedback_quality_price,
                feedback_message: this.state.feedback_message,
            })
            .catch(handleServerError.bind(this))
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    render() {
        if (this.state.init || this.props.reloading) {
            return (
                <Segment>
                    <Dimmer
                        active
                        inverted
                        style={{ height: '300px' }}
                    >
                        <Loader
                            inverted
                            content="Chargement"
                        />
                    </Dimmer>
                </Segment>
            )
        }

        if (!this.props.feedback && !this.props.reloading) {
            return <Redirect to="/404" />
        }

        if (this.state.errorRedirect && !this.props.reloading) {
            return <Redirect to="/error" />
        }

        const { feedback, serverError } = this.props

        return (
            <Grid
                stackable
                columns={2}
                style={{ marginBottom: '3em' }}
            >
                <GridColumn width={8}>
                    {feedback.image && (
                        <div style={{ marginBottom: '1em', height: '230px' }}>
                            <Image
                                size="medium"
                                style={{ width: '230px' }}
                                centered
                                src={addImgPrefix(
                                    `${feedback.producerproduct_id}/${feedback.image}`,
                                    'thumbail'
                                )}
                                alt={feedback.product_name}
                                fluid
                            />
                        </div>
                    )}
                    <div>
                        <InfoModal
                            show={!!serverError}
                            onClose={this.handleErrorDismiss}
                            isError={true}
                        />
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Produit</Table.Cell>
                                    <Table.Cell>
                                        {feedback.product_name}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Producteur</Table.Cell>
                                    <Table.Cell>
                                        {feedback.producer_name}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Prix pay&eacute;</Table.Cell>
                                    <Table.Cell>
                                        {formatMoney.run(
                                            feedback.consumer_price
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Distribution</Table.Cell>
                                    <Table.Cell>
                                        {formatDate.run(
                                            feedback.createdAt,
                                            'dddd DD MMMM YYYY'
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </GridColumn>
                <GridColumn width={8}>
                    <Form>
                        <Form.Field required>
                            <label>Retour qualit&eacute;</label>
                            <Rating
                                icon="star"
                                size="massive"
                                maxRating={5}
                                clearable
                                defaultRating={feedback.feedback_quality}
                                disabled={feedback.hasFeedback()}
                                onRate={this.handleQuality}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Rapport qualit&eacute; / prix</label>
                            <Rating
                                icon="star"
                                size="massive"
                                maxRating={5}
                                clearable
                                defaultRating={feedback.feedback_quality_price}
                                disabled={feedback.hasFeedback()}
                                onRate={this.handleQualityPrice}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Retour suppl&eacute;mentaire</label>
                            <TextArea
                                name="extra"
                                rows="5"
                                disabled={feedback.hasFeedback()}
                                onChange={this.handleMessage}
                                value={feedback.feedback_message}
                            />
                        </Form.Field>
                        {feedback.hasFeedback() && feedback.hasProducerReply() && (
                            <Message>
                                <Message.Header>
                                    <Icon
                                        name="warning"
                                        size="mini"
                                    />{' '}
                                    Le producteur a laiss&eacute; une
                                    r&eacute;ponse !
                                </Message.Header>
                                <p>{feedback.producer_message}</p>
                            </Message>
                        )}
                        {feedback.hasFeedback() &&
                            !feedback.hasProducerReply() && (
                                <Message>
                                    <Message.Header>
                                        <Icon
                                            name="thumbs up"
                                            size="mini"
                                        />{' '}
                                        Merci beaucoup pour votre retour !
                                    </Message.Header>
                                    <p>
                                        Ces informations nous aide &agrave;
                                        am&eacute;liorer notre service
                                    </p>
                                </Message>
                            )}
                        {!feedback.hasFeedback() && (
                            <Button
                                floated="right"
                                primary
                                onClick={e => this.createFeedback(e)}
                            >
                                Submit
                            </Button>
                        )}
                    </Form>
                </GridColumn>
            </Grid>
        )
    }
}

export const AccountPageFeedbackDetail = ({
    feedback,
    fetchConsumerFeedbackDetail,
    createFeedback,
    pathname,
    reloading,
}) => (
    <ErrorBoundary page="account-page-feedback-detail">
        <FeedbackDetail
            feedback={feedback}
            fetchConsumerFeedbackDetail={fetchConsumerFeedbackDetail}
            createFeedback={createFeedback}
            pathname={pathname}
            reloading={reloading}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({
    currentUser,
    initialized,
    currentFeedback,
    router,
}) => {
    return {
        currentUser,
        initialized,
        feedback:
            currentFeedback &&
            currentFeedback.feedback &&
            new ProducerProductFeedback(currentFeedback.feedback),
        pathname: router.location.pathname,
        reloading: currentFeedback.loading,
        serverError: !!currentFeedback.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchConsumerFeedbackDetail: feedback_id =>
            dispatch(fetchConsumerFeedbackDetail(feedback_id)),
        createFeedback: params => dispatch(createFeedback(params)),
        clearServerError: () =>
            dispatch(clearError('FETCH_CONSUMER_FEEDBACK_DETAIL')),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageFeedbackDetail)
