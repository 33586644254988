import React from 'react'
import { Header, Image } from 'semantic-ui-react'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import Styles from './styles.module.css'
import { addImgPrefix } from '../../../../utils'

export const LocaleFullView = ({
    currentLocale,
    homedeliveryLocale,
    children,
}) => {
    const showHomeDeliveryOption =
        homedeliveryLocale && currentLocale.code !== homedeliveryLocale.code

    return (
        <div
            className={Styles.Container}
            data-testid="locale-full-view"
        >
            <div className={Styles.ImageContainer}>
                <Image
                    alt="Logo champignon"
                    className={Styles.Image}
                    src={addImgPrefix('mushroom.svg')}
                />
            </div>

            <Header as="h2">
                Vente compl&egrave;te !
                <Header.Subheader>
                    {`L'espace disponible pour la distribution des paniers dans la boutique ${currentLocale.name} est épuisé...`}
                </Header.Subheader>
            </Header>

            {showHomeDeliveryOption ? (
                <div className={Styles.HomeDeliveryTextContainer}>
                    <p>
                        En revanche, vous pouvez encore commander en livraison à
                        domicile !
                        <br />
                        Livraison dans tout Paris, grâce à notre partenaire Olvo
                        (coopérative de livreurs à vélo).
                        <br />
                        5,50 € la livraison, gratuite à partir de{' '}
                        {formatMoney.run(
                            homedeliveryLocale.home_delivery_free_amount
                        )}{' '}
                        d'achat.
                    </p>
                </div>
            ) : (
                <div className={Styles.BoutiquesTextContainer}>
                    ...mais il reste de la place dans nos autres boutiques
                    Kelbongoo &agrave; proximit&eacute; !
                </div>
            )}

            {children}
        </div>
    )
}
