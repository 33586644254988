export const API_ADDRESS = process.env.REACT_APP_API_URL

// Beta https://courses-api.kbgbeta.com
// Staging https://courses-api.kbgstaging.com
// Local http://localhost:3010
// Prod https://courses-api.kelbongoo.com

export const IMG_PREFIX = process.env.REACT_APP_IMG_PREFIX
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const SYNC_QUANTITIES_TIME_IN_SECONDS = 6
export const MAX_ITEM_QUANTITY = process.env.REACT_APP_MAX_ITEM_QUANTITY || 8
export const DEFAULT_LOCALE = 'BOR'

export const MAX_WIDTH = {
    XS: 575,
    SM: 767,
    MD: 1024,
    LG: 1199,
    XL: 1399,
}

export const MIN_WIDTH = {
    SM: 576,
    MD: 768,
    LG: 1025,
    XL: 1200,
    XXL: 1400,
}
