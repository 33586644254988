import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'
import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'
import {
    Form,
    Message,
    Icon,
    Header,
    Button,
    Popup,
    Dimmer,
    Loader,
    Checkbox,
} from 'semantic-ui-react'

import { Redirect } from 'react-router'
import Styles from './styles.module.css'

import { validateConsumerProfileUpdate } from './helpers/validateConsumerProfileUpdate'
import { diff } from './helpers/diff'

import {
    forgotPassword,
    sendUpdateConsumerEmailConfirmation,
    updateConsumerData,
    clearError,
} from '../../../../actions'

import usePlacesAutocomplete, {
    getGeocode,
    getZipCode,
} from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxPopover, ComboboxList } from '@reach/combobox'

import InfoModal from '../../../ui/InfoModal'
import ErrorBoundary from '../../../layout/ErrorBoundary'

import { AddressHeader } from './AddressHeader'
import { AccountPageDetailsUpdateEmailModal } from './AccountPageDetailsUpdateEmailModal'
import { AccountPageDetailsInvalidAddressWarning } from './AccountPageDetailsInvalidAddressWarning'
import {
    isUrlDeliveryAddressInvalid,
    formatErrorText,
    userKeys,
} from './helpers'

const countryCodeOptions = [
    { key: 0, value: '+33', text: "France (+33)" },
    { key: 1, value: '+93', text: "Afghanistan (+93)" },
    { key: 2, value: '+27', text: "Afrique du Sud (+27)" },
    { key: 3, value: '+355', text: "Albanie (+355)" },
    { key: 4, value: '+213', text: "Algérie (+213)" },
    { key: 5, value: '+49', text: "Allemagne (+49)" },
    { key: 6, value: '+376', text: "Andorre (+376)" },
    { key: 7, value: '+244', text: "Angola (+244)" },
    { key: 8, value: '+1264', text: "Anguilla (+1 264)" },
    { key: 9, value: '+1268', text: "Antigua-et-Barbuda (+1 268)" },
    { key: 10, value: '+966', text: "Arabie saoudite (+966)" },
    { key: 11, value: '+54', text: "Argentine (+54)" },
    { key: 12, value: '+374', text: "Arménie (+374)" },
    { key: 13, value: '+297', text: "Aruba (+297)" },
    { key: 15, value: '+247', text: "Ascension (Sainte-Hélène, Ascension et Tristan da Cunha) (+247)" },
    { key: 16, value: '+61', text: "Australie (+61)" },
    { key: 17, value: '+43', text: "Autriche (+43)" },
    { key: 18, value: '+994', text: "Azerbaïdjan (+994)" },
    { key: 19, value: '+1242', text: "Bahamas (+1 242)" },
    { key: 20, value: '+973', text: "Bahreïn (+973)" },
    { key: 21, value: "+880", text: "Bangladesh (+880)" },
    { key: 22, value: "+1 246", text: "Barbade (+1 246)" },
    { key: 23, value: "+32", text: "Belgique (+32)" },
    { key: 24, value: "+501", text: "Belize (+501)" },
    { key: 25, value: "+229", text: "Bénin (+229)" },
    { key: 26, value: "+1 441", text: "Bermudes (+1 441)" },
    { key: 27, value: "+975", text: "Bhoutan (+975)" },
    { key: 28, value: "+375", text: "Biélorussie (+375)" },
    { key: 29, value: "+95", text: "Birmanie (+95)" },
    { key: 30, value: "+591", text: "Bolivie (+591)" },
    { key: 31, value: "+387", text: "Bosnie-Herzégovine (+387)" },
    { key: 32, value: "+267", text: "Botswana (+267)" },
    { key: 33, value: "+55", text: "Brésil (+55)" },
    { key: 34, value: "+673", text: "Brunei (+673)" },
    { key: 35, value: "+359", text: "Bulgarie (+359)" },
    { key: 36, value: "+226", text: "Burkina Faso (+226)" },
    { key: 37, value: "+257", text: "Burundi (+257)" },
    { key: 38, value: "+855", text: "Cambodge (+855)" },
    { key: 39, value: "+237", text: "Cameroun (+237)" },
    { key: 40, value: "+1", text: "Canada (+1)" },
    { key: 41, value: "+238", text: "Cap-Vert (+238)" },
    { key: 42, value: "+1 345", text: "Îles Caïmans (+1 345)" },
    { key: 43, value: "+236", text: "République centrafricaine (+236)" },
    { key: 44, value: "+56", text: "Chili (+56)" },
    { key: 45, value: "+86", text: "Chine (République Populaire de Chine) (+86)" },
    { key: 46, value: "+357", text: "Chypre (+357)" },
    { key: 47, value: "+57", text: "Colombie (+57)" },
    { key: 48, value: "+269", text: "Comores (+269)" },
    { key: 49, value: "+243", text: "République démocratique du Congo (+243)" },
    { key: 50, value: "+242", text: "République du Congo (+242)" },
    { key: 51, value: "+682", text: "Îles Cook (+682)" },
    { key: 52, value: "+850", text: "Corée du Nord (+850)" },
    { key: 53, value: "+82", text: "Corée du Sud (+82)" },
    { key: 54, value: "+506", text: "Costa Rica (+506)" },
    { key: 55, value: "+225", text: "Côte d'Ivoire (+225)" },
    { key: 56, value: "+385", text: "Croatie (+385)" },
    { key: 57, value: "+53", text: "Cuba (+53)" },
    { key: 58, value: "+599", text: "Curaçao et Pays-Bas caribéens (+599)" },
    { key: 59, value: "+45", text: "Danemark (+45)" },
    { key: 60, value: "+246", text: "Diego Garcia (+246)" },
    { key: 61, value: "+253", text: "Djibouti (+253)" },
    { key: 62, value: "+1", text: "République dominicaine (+1)" },
    { key: 63, value: "+1 767", text: "Dominique (+1 767)" },
    { key: 64, value: "+20", text: "Égypte (+20)" },
    { key: 65, value: "+971", text: "Émirats arabes unis (+971)" },
    { key: 66, value: "+593", text: "Équateur (+593)" },
    { key: 67, value: "+291", text: "Érythrée (+291)" },
    { key: 68, value: "+34", text: "Espagne (+34)" },
    { key: 69, value: "+372", text: "Estonie (+372)" },
    { key: 70, value: "+1", text: "États-Unis (+1)" },
    { key: 71, value: "+251", text: "Éthiopie (+251)" },
    { key: 72, value: "+298", text: "Îles Féroé (+298)" },
    { key: 73, value: "+679", text: "Fidji (+679)" },
    { key: 74, value: "+358", text: "Finlande (+358)" },
    { key: 75, value: "+241", text: "Gabon (+241)" },
    { key: 76, value: "+220", text: "Gambie (+220)" },
    { key: 77, value: "+995", text: "Géorgie (+995)" },
    { key: 78, value: "+233", text: "Ghana (+233)" },
    { key: 79, value: "+350", text: "Gibraltar (+350)" },
    { key: 80, value: "+30", text: "Grèce (+30)" },
    { key: 81, value: "+1 473", text: "Grenade (+1 473)" },
    { key: 82, value: "+299", text: "Groenland (+299)" },
    { key: 83, value: "+590", text: "Guadeloupe (+590)" },
    { key: 84, value: "+1 671", text: "Guam (+1 671)" },
    { key: 85, value: "+502", text: "Guatemala (+502)" },
    { key: 86, value: "+224", text: "Guinée (+224)" },
    { key: 87, value: "+240", text: "Guinée équatoriale (+240)" },
    { key: 88, value: "+245", text: "Guinée-Bissau (+245)" },
    { key: 89, value: "+592", text: "Guyana (+592)" },
    { key: 90, value: "+594", text: "Guyane (+594)" },
    { key: 91, value: "+509", text: "Haïti (+509)" },
    { key: 92, value: "+504", text: "Honduras (+504)" },
    { key: 93, value: "+852", text: "Hong Kong (+852)" },
    { key: 94, value: "+36", text: "Hongrie (+36)" },
    { key: 95, value: "+91", text: "Inde (+91)" },
    { key: 96, value: "+62", text: "Indonésie (+62)" },
    { key: 97, value: "+964", text: "Irak (+964)" },
    { key: 98, value: "+98", text: "Iran (+98)" },
    { key: 99, value: "+353", text: "Irlande (+353)" },
    { key: 100, value: "+354", text: "Islande (+354)" },
    { key: 101, value: "+972", text: "Israël (+972)" },
    { key: 102, value: "+39", text: "Italie (+39)" },
    { key: 103, value: "+1 876", text: "Jamaïque (+1 876)" },
    { key: 104, value: "+81", text: "Japon (+81)" },
    { key: 105, value: "+962", text: "Jordanie (+962)" },
    { key: 106, value: "+7", text: "Kazakhstan (+7)" },
    { key: 107, value: "+254", text: "Kenya (+254)" },
    { key: 108, value: "+996", text: "Kirghizistan (+996)" },
    { key: 109, value: "+686", text: "Kiribati (+686)" },
    { key: 110, value: "+383", text: "Kosovo (+383)" },
    { key: 111, value: "+965", text: "Koweït (+965)" },
    { key: 112, value: "+856", text: "Laos (+856)" },
    { key: 113, value: "+266", text: "Lesotho (+266)" },
    { key: 114, value: "+371", text: "Lettonie (+371)" },
    { key: 115, value: "+961", text: "Liban (+961)" },
    { key: 116, value: "+231", text: "Liberia (+231)" },
    { key: 117, value: "+218", text: "Libye (+218)" },
    { key: 118, value: "+423", text: "Liechtenstein (+423)" },
    { key: 119, value: "+370", text: "Lituanie (+370)" },
    { key: 120, value: "+352", text: "Luxembourg (+352)" },
    { key: 121, value: "+853", text: "Macao (+853)" },
    { key: 122, value: "+389", text: "Macédoine du Nord (+389)" },
    { key: 123, value: "+261", text: "Madagascar (+261)" },
    { key: 124, value: "+60", text: "Malaisie (+60)" },
    { key: 125, value: "+265", text: "Malawi (+265)" },
    { key: 126, value: "+960", text: "Maldives (+960)" },
    { key: 127, value: "+223", text: "Mali (+223)" },
    { key: 128, value: "+500", text: "Malouines (+500)" },
    { key: 129, value: "+356", text: "Malte (+356)" },
    { key: 130, value: "+1 670", text: "Îles Mariannes du Nord (+1 670)" },
    { key: 131, value: "+212", text: "Maroc (+212)" },
    { key: 132, value: "+692", text: "Îles Marshall (+692)" },
    { key: 133, value: "+596", text: "Martinique (+596)" },
    { key: 134, value: "+230", text: "Maurice (+230)" },
    { key: 135, value: "+222", text: "Mauritanie (+222)" },
    { key: 136, value: "+262", text: "Mayotte (+262)" },
    { key: 137, value: "+52", text: "Mexique (+52)" },
    { key: 138, value: "+691", text: "États fédérés de Micronésie (+691)" },
    { key: 139, value: "+373", text: "Moldavie (+373)" },
    { key: 140, value: "+377", text: "Monaco (+377)" },
    { key: 141, value: "+976", text: "Mongolie (+976)" },
    { key: 142, value: "+382", text: "Monténégro (+382)" },
    { key: 143, value: "+1 664", text: "Montserrat (+1 664)" },
    { key: 144, value: "+258", text: "Mozambique (+258)" },
    { key: 145, value: "+264", text: "Namibie (+264)" },
    { key: 146, value: "+674", text: "Nauru (+674)" },
    { key: 147, value: "+977", text: "Népal (+977)" },
    { key: 148, value: "+505", text: "Nicaragua (+505)" },
    { key: 149, value: "+227", text: "Niger (+227)" },
    { key: 150, value: "+234", text: "Nigeria (+234)" },
    { key: 151, value: "+683", text: "Niue (+683)" },
    { key: 152, value: "+47", text: "Norvège (+47)" },
    { key: 153, value: "+687", text: "Nouvelle-Calédonie (+687)" },
    { key: 154, value: "+64", text: "Nouvelle-Zélande (+64)" },
    { key: 155, value: "+968", text: "Oman (+968)" },
    { key: 156, value: "+256", text: "Ouganda (+256)" },
    { key: 157, value: "+998", text: "Ouzbékistan (+998)" },
    { key: 158, value: "+92", text: "Pakistan (+92)" },
    { key: 159, value: "+680", text: "Palaos (+680)" },
    { key: 160, value: "+970", text: "Palestine (réservé) (+970)" },
    { key: 161, value: "+507", text: "Panama (+507)" },
    { key: 162, value: "+675", text: "Papouasie-Nouvelle-Guinée (+675)" },
    { key: 163, value: "+595", text: "Paraguay (+595)" },
    { key: 164, value: "+31", text: "Pays-Bas (+31)" },
    { key: 165, value: "+51", text: "Pérou (+51)" },
    { key: 166, value: "+63", text: "Philippines (+63)" },
    { key: 167, value: "+48", text: "Pologne (+48)" },
    { key: 168, value: "+689", text: "Polynésie française (+689)" },
    { key: 169, value: "+1", text: "Porto Rico (+1)" },
    { key: 170, value: "+351", text: "Portugal (+351)" },
    { key: 171, value: "+974", text: "Qatar (+974)" },
    { key: 172, value: "+262", text: "La Réunion (+262)" },
    { key: 173, value: "+40", text: "Roumanie (+40)" },
    { key: 174, value: "+44", text: "Royaume-Uni (+44)" },
    { key: 175, value: "+7", text: "Russie (+7)" },
    { key: 176, value: "+250", text: "Rwanda (+250)" },
    { key: 177, value: "+1 869", text: "Saint-Christophe-et-Niévès (+1 869)" },
    { key: 178, Value: "+290", text: "Sainte - Hélène, Ascension et Tristan da Cunha, île(+290)" },
    { key: 179, value: "+1 758", text: "Sainte-Lucie (+1 758)" },
    { key: 180, value: "+378", text: "Saint-Marin (+378)" },
    { key: 181, value: "+508", text: "Saint-Pierre-et-Miquelon (+508)" },
    { key: 182, value: "+1 784", text: "Saint-Vincent-et-les-Grenadines (+1 784)" },
    { key: 183, value: "+677", text: "Îles Salomon (+677)" },
    { key: 184, value: "+503", text: "Salvador (+503)" },
    { key: 185, value: "+685", text: "Samoa (+685)" },
    { key: 186, value: "+1 684", text: "Samoa américaines (+1 684)" },
    { key: 187, value: "+239", text: "Sao Tomé-et-Principe (+239)" },
    { key: 188, value: "+221", text: "Sénégal (+221)" },
    { key: 189, value: "+381", text: "Serbie (+381)" },
    { key: 190, value: "+248", text: "Seychelles (+248)" },
    { key: 191, value: "+232", text: "Sierra Leone (+232)" },
    { key: 192, value: "+65", text: "Singapour (+65)" },
    { key: 193, value: "+421", text: "Slovaquie (+421)" },
    { key: 194, value: "+386", text: "Slovénie (+386)" },
    { key: 195, value: "+252", text: "Somalie (+252)" },
    { key: 196, value: "+249", text: "Soudan (+249)" },
    { key: 197, value: "+211", text: "Soudan du Sud (+211)" },
    { key: 198, value: "+94", text: "Sri Lanka (+94)" },
    { key: 199, value: "+46", text: "Suède (+46)" },
    { key: 200, value: "+41", text: "Suisse (+41)" },
    { key: 201, value: "+597", text: "Suriname (+597)" },
    { key: 202, value: "+268", text: "Eswatini (+268)" },
    { key: 203, value: "+963", text: "Syrie (+963)" },
    { key: 204, value: "+992", text: "Tadjikistan (+992)" },
    { key: 205, value: "+255", text: "Tanzanie (+255)" },
    { key: 206, value: "+886", text: "Taïwan (+886)" },
    { key: 207, value: "+235", text: "Tchad (+235)" },
    { key: 208, value: "+420", text: "République tchèque (+420)" },
    { key: 209, value: "+672", text: "Territoires extérieurs de l’Australie (+672)" },
    { key: 210, value: "+66", text: "Thaïlande(+66)" },
    { key: 211, value: "+670", text: "Timor oriental(+670)" },
    { key: 212, value: "+228", text: "Togo(+228)" },
    { key: 213, value: "+690", text: "Tokelau(+690)" },
    { key: 214, value: "+676", text: "Tonga(+676)" },
    { key: 215, value: "+1 868", text: "Trinité - et - Tobago(+1 868)" },
    { key: 216, value: "+216", text: "Tunisie(+216)" },
    { key: 217, value: "+993", text: "Turkménistan(+993)" },
    { key: 218, value: "+1 649", text: "Îles Turques - et - Caïques(+1 649)" },
    { key: 219, value: "+90", text: "Turquie(+90)" },
    { key: 220, value: "+688", text: "Tuvalu(+688)" },
    { key: 221, value: "+380", text: "Ukraine(+380)" },
    { key: 222, value: "+598", text: "Uruguay(+598)" },
    { key: 223, value: "+678", text: "Vanuatu(+678)" },
    { key: 224, value: "+39", text: "Vatican(Saint - Siège)(+39)" },
    { key: 225, value: "+58", text: "Venezuela(+58)" },
    { key: 226, value: "+1 340", text: "Îles Vierges des États - Unis(+1 340)" },
    { key: 227, value: "+1 284", text: "Îles Vierges britanniques(+1 284)" },
    { key: 228, value: "+84", text: "Viêt Nam(+84)" },
    { key: 229, value: "+681", text: "Wallis - et - Futuna(+681)" },
    { key: 230, value: "+967", text: "Yémen(+967)" },
    { key: 231, value: "+26", text: "Zambie(+260" },
]

const DetailsPage = ({
    serverError,
    updateParams,
    onResetPassword,
    clearServerError,
    currentUser,
    locales,
    sendUpdateEmailConfirmation,
}) => {
    let checkoutInvalidDeliveryAddressRedirect =
        isUrlDeliveryAddressInvalid(window.location)

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    const [validated, setValidated] = useState(true)
    const [loading, setLoading] = useState(false)
    const [init, setInit] = useState(true)
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
    const [validationRequired, setValidationRequired] = useState(false)
    const [redirectCheckout, setRedirectCheckout] = useState(false)
    const [showFormError, setShowFormError] = useState(null)
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(null)
    const [showFormSuccess, setShowFormSuccess] = useState(null)
    const [updateAddress, setUpdateAddress] = useState(false)

    //Current user data fields
    const [telephone, setTelephone] = useState(currentUser && currentUser.telephone)
    const [countryCode, setCountryCode] = useState(currentUser?.countryCode ? currentUser.countryCode : countryCodeOptions[0].value)
    const [email, setEmail] = useState(currentUser && currentUser.email)
    const [firstname, setFirstname] = useState(currentUser && currentUser.firstname)
    const [lastname, setLastname] = useState(currentUser && currentUser.lastname)
    const [default_locale, setDefault_locale] = useState(currentUser && currentUser.default_locale)
    const [send_launch_email, setSend_launch_email] = useState(currentUser && currentUser.send_launch_email)
    const [address_street, setAddress_street] = useState(currentUser && currentUser.address_street)
    const [address_city, setAddress_city] = useState(currentUser && currentUser.address_city)
    const [address_zip, setAddress_zip] = useState(currentUser && currentUser.address_zip)
    const [address_code, setAddress_code] = useState(currentUser && currentUser.address_code)
    // Fix this once .door field is added to consumer schema
    const [door, setDoor] = useState(currentUser?.door ? currentUser.door : false)
    const [address_instructions, setAddress_instructions] = useState(currentUser && currentUser.address_instructions)
    const [has_valid_delivery_address, setHas_valid_delivery_address] = useState(currentUser && currentUser.has_valid_delivery_address)

    let handleSelectAddress = async function (address) {
        clearSuggestions()
        setValue(address)

        const results = await getGeocode({ address })
        setAddress_city(results[0].address_components[2].long_name)
        setAddress_zip(getZipCode(results[0], false))
        setAddress_street(`${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`)
    }

    let handleResetPasswordSuccessDismiss = function () {
        setShowResetPasswordSuccess(false)
    }

    let handleFormSuccessDismiss = function () {
        setShowFormSuccess(false)
    }

    let handleFormErrorDismiss = function () {
        setShowFormError(false)
    }

    let handleErrorDismiss = function () {
        clearServerError()
    }

    const showInvalidAddressWarning =
        checkoutInvalidDeliveryAddressRedirect &&
        !has_valid_delivery_address


    const localeOptions = locales && locales
        .map(l => ({
            key: l.code,
            value: l.code,
            text: l.name,
        }))
        .slice()
        .sort(sortByProps(['text']))

    const handleSubmit = function () {
        setValue('')
        setUpdateAddress(false)

        let updatedUser = { firstname, lastname, countryCode, telephone, default_locale, send_launch_email, has_valid_delivery_address, address_city, address_code, door, address_instructions, address_street, address_zip }
        const changed = diff(currentUser, updatedUser)

        if (!validateConsumerProfileUpdate(currentUser, updatedUser)) {
            return
        }

        setLoading(true)

        // validate the form
        return updateParams(changed)
            .then(ok => {
                if (ok) {
                    setValidated(false)
                    setHas_valid_delivery_address(currentUser.has_valid_delivery_address)
                    // setRedirectCheckout(checkoutInvalidDeliveryAddressRedirect)
                } else {
                    setLoading(false)
                    setShowFormError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    if (validationRequired) {
        return (
            <div>
                <Form>
                    <Header
                        as="h3"
                        textAlign="center"
                        icon
                    >
                        <Icon name="user plus" />
                        Validation email requise!
                    </Header>
                </Form>
            </div>
        )
    }

    if (redirectCheckout) {
        return (
            <Redirect
                to="/checkout"
                push
            />
        )
    }

    return (
        <Dimmer.Dimmable dimmed={loading}>
            <Dimmer
                active={loading}
                inverted
            >
                <Loader size="medium">Chargement</Loader>
            </Dimmer>

            <InfoModal
                show={!!serverError}
                icon="info circle"
                header=""
                body={serverError}
                onClose={handleErrorDismiss}
                isError={true}
            />
            <InfoModal
                show={!!showFormError}
                onClose={handleFormErrorDismiss}
                isError={true}
                header={"Merci de vérifier cette adresse"}
                body={
                    "Cette adresse n'est pas reconnue comme une adresse de livraison valide"
                }
            />
            <InfoModal
                show={!!showFormSuccess}
                onClose={handleFormSuccessDismiss}
                isSuccess={true}
            />
            <InfoModal
                show={!!showResetPasswordSuccess}
                body="Un mail vient de vous être envoyé avec un lien pour changer votre mot de passe."
                onClose={handleResetPasswordSuccessDismiss}
                isInfo={true}
            />

            <div className={Styles.Container}>
                <Form>
                    {currentUser && currentUser.pro && (
                        <Message
                            size="mini"
                            icon="info circle"
                            header="Vous avez un compte professionnel"
                            style={{ marginBottom: '2em' }}
                        />
                    )}

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>Mon profil :</div>
                        <Form.Field
                            required
                            style={{ margin: '1em 0' }}
                        >
                            <label htmlFor="lastname">Nom</label>
                            <input
                                name="lastname"
                                id="lastname"
                                placeholder="Nom"
                                value={lastname || currentUser.lastname || ''}
                                onChange={e => setLastname(e.target.value)}
                            />
                        </Form.Field>

                        <Form.Field
                            required
                            style={{ margin: '1em 0' }}
                        >
                            <label htmlFor="firstname">Pr&eacute;nom</label>
                            <input
                                name="firstname"
                                id="firstname"
                                placeholder="Prénom"
                                value={firstname || currentUser.firstname || ''}
                                onChange={e => setFirstname(e.target.value)}
                            />
                        </Form.Field>

                        <div className={Styles.Align}>
                            <Form.Field>
                                <label htmlFor="email">Adresse mail</label>
                                <AccountPageDetailsUpdateEmailModal
                                    email={email}
                                    sendUpdateEmailConfirmation={
                                        sendUpdateEmailConfirmation
                                    }
                                />
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="password">Mot de passe</label>
                                <Button
                                    type="button"
                                    loading={
                                        resetPasswordLoading
                                    }
                                    content="Changer mon mot de passe"
                                    onClick={onResetPassword}
                                />
                            </Form.Field>
                        </div>
                        <Form.Field>
                            <label htmlFor="telephone">
                                T&eacute;l&eacute;phone &nbsp;
                                <Popup
                                    trigger={
                                        <Icon name="question circle" />
                                    }
                                    content="Si vous choisissez de régler votre commande lors du retrait,
                    nous aurons besoin de votre numéro de téléphone"
                                />
                            </label>
                            <div style={{ display: 'flex' }}>
                                <Form.Select
                                    fluid
                                    style={{ width: '200px !important' }}
                                    options={countryCodeOptions}
                                    name="countryCode"
                                    value={countryCode || currentUser.countryCode || ''}
                                    onChange={(e, data) => setCountryCode(data.value)}
                                    data-testid="countryCode"
                                />
                                <Form.Field style={{ width: 'calc(100% - 205px) !important', marginLeft: '5px' }}>
                                    <input
                                        name="telephone"
                                        id="telephone"
                                        placeholder="Numéro de téléphone"
                                        value={telephone || currentUser.telephone || ''}
                                        onChange={e => setTelephone(e.target.value)}
                                    />
                                </Form.Field>
                            </div>
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>
                            Mes préférences :
                        </div>
                        <Form.Field>
                            <label style={{ marginTop: '15px' }}>
                                Boutique par d&eacute;faut &nbsp;
                                <Popup
                                    trigger={
                                        <Icon name="question circle" />
                                    }
                                    content="La boutique où vous commandez habituellement.
                      Vous pouvez toujours choisir une autre boutique si vous voulez !"
                                />
                            </label>
                            <Form.Select
                                fluid
                                options={localeOptions}
                                placeholder="Choisir une boutique"
                                name="default_locale"
                                value={default_locale || currentUser.default_locale || ''}
                                onChange={(e, data) => setDefault_locale(data.value)}
                                data-testid="default_locale"
                            />
                        </Form.Field>

                        <Form.Field style={{ margin: '2em 0 1em' }}>
                            <Checkbox
                                toggle
                                label="Rappel à l'ouverture des commandes"
                                name="send_launch_email"
                                id="send_launch_email"
                                onChange={(e, data) => setSend_launch_email(data.checked)}
                                checked={send_launch_email || currentUser.send_launch_email || false}
                                data-testid="send_launch_email"
                            />
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>Mon adresse :</div>

                        {/* Delivery address group */}
                        <AccountPageDetailsInvalidAddressWarning
                            showInvalidAddressWarning={
                                showInvalidAddressWarning
                            }
                        />

                        <AddressHeader
                            has_valid_delivery_address={
                                currentUser.has_valid_delivery_address
                            }
                        />

                        <div className={Styles.Align}>
                            <Form.Field className={Styles.Street}>
                                <label htmlFor="address_street">Rue</label>
                                <input
                                    name="address_street"
                                    id="address_street"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_street || currentUser.address_street || ''}
                                />
                            </Form.Field>
                            <Form.Field className={Styles.City}>
                                <label htmlFor="address_city">Ville</label>
                                <input
                                    name="address_city"
                                    id="address_city"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_city || currentUser.address_city || ''}
                                />
                            </Form.Field>
                            <Form.Field className={Styles.PostalCode}>
                                <label htmlFor="address_zip">
                                    Code postal
                                </label>
                                <input
                                    name="address_zip"
                                    id="address_zip"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_zip || currentUser.address_zip || ''}
                                />
                            </Form.Field>
                        </div>
                        {!updateAddress ?
                            <Form.Field style={{ margin: '10px 0 15px 0 !important' }}>
                                <Button
                                    type="button"
                                    style={{ backgroundColor: '#63AE71', color: '#075244' }}
                                    content="Mettre a jour mon adresse"
                                    onClick={() => setUpdateAddress(true)}
                                />
                            </Form.Field>
                            :
                            <div
                                className={Styles.Street}
                                style={{ margin: '10px 0px 15px 0', width: '100%' }}>
                                <label htmlFor="address" style={{ fontWeight: 'bold', fontSize: '.92857143em' }}>Rechercher une adresse</label>
                                <Combobox onSelect={handleSelectAddress} style={{ margin: '.28571429rem 0 0 !important' }}>
                                    <ComboboxInput
                                        name="address"
                                        id="address"
                                        autocomplete="off"
                                        placeholder="Renseignez une adresse..."
                                        onChange={e => {
                                            setValue(e.target.value)
                                        }}
                                        value={value}
                                    />
                                    <ComboboxPopover style={{ zIndex: '1500' }}>
                                        <ComboboxList>
                                            {data.map(({ place_id, description }, index) => {
                                                return (
                                                    <ComboboxOption
                                                        key={place_id}
                                                        value={description}
                                                    />
                                                )
                                            })}
                                        </ComboboxList>
                                    </ComboboxPopover>
                                </Combobox>
                            </div>
                        }
                        <div className={Styles.Align}>
                            <Form.Field style={{ marginBottom: '1em' }}>
                                <label htmlFor="address_code">
                                    Code immeuble
                                </label>
                                <input
                                    name="address_code"
                                    id="address_code"
                                    placeholder=''
                                    onChange={e => setAddress_code(e.target.value)}
                                    value={address_code || currentUser.address_code || ''}
                                />
                            </Form.Field>
                            <Form.Field style={{ marginBottom: '1em', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <label htmlFor="livdom_door">
                                    Livraison à domicile
                                </label>
                                <Checkbox
                                    toggle
                                    label="J’autorise Kelbongoo  à déposer ma commande devant ma porte et je déclare que celle-ci est accessible au livreur."
                                    name="door"
                                    id="door"
                                    onChange={(e, data) => {
                                        setDoor(data.checked)
                                    }}
                                    checked={door || currentUser.door || false}
                                    data-testid="door"
                                />
                            </Form.Field>
                        </div>
                        <Form.Field style={{ marginBottom: '1em' }}>
                            <label htmlFor="address_instructions">
                                Instructions &agrave; donner au livreur
                            </label>
                            <textarea
                                name="address_instructions"
                                id="address_instructions"
                                rows={8}
                                placeholder="Instructions supplémentaires (200 caracteres max)"
                                onChange={e => setAddress_instructions(e.target.value)}
                                value={address_instructions || currentUser.address_instructions || ''}
                                className={Styles.Textarea}
                            />
                            <label htmlFor="address_instructions">
                                Livraison en pied d'immeuble. Exception
                                possible pour les personnes à mobilité
                                réduite, auquel cas merci de préciser
                                ci-dessus votre souhait d'être livré à la
                                porte.
                            </label>
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.ActionPanel}>
                            <Button
                                secondary
                                disabled={
                                    checkoutInvalidDeliveryAddressRedirect
                                        ? true
                                        : !!validated
                                }
                                content={'Retour à la vente'}
                                icon="shopping basket"
                                href="/commande"
                            />

                            <Form.Field>
                                <Button
                                    primary
                                    disabled={!validated}
                                    content={
                                        checkoutInvalidDeliveryAddressRedirect
                                            ? 'Valider et procéder au paiement'
                                            : 'Valider'
                                    }
                                    icon="checkmark"
                                    onClick={() => handleSubmit()}
                                />
                            </Form.Field>
                        </div>
                    </div>
                </Form>
            </div>
        </Dimmer.Dimmable>
    )
}

const AccountPageDetails = ({
    currentUser,
    locales,
    serverError = '',
    sendUpdateEmailConfirmation,
    updateParams,
    onResetPassword,
    clearServerError,
    location,
}) => (
    <ErrorBoundary page="account-page-details">
        <DetailsPage
            currentUser={currentUser}
            locales={locales}
            serverError={serverError}
            sendUpdateEmailConfirmation={sendUpdateEmailConfirmation}
            updateParams={updateParams}
            onResetPassword={onResetPassword}
            clearServerError={clearServerError}
            location={location}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, localeList, locales }) => {
    return {
        currentUser,
        locales: localeList
            .filter(code =>
                locales[code] &&
                !locales[code].private &&
                locales[code].code !== 'PRO'
            )
            .map(code => locales[code]),
        serverError: !!currentUser.error && formatErrorText(currentUser.error),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUpdateEmailConfirmation: email =>
            dispatch(sendUpdateConsumerEmailConfirmation(email)),
        updateParams: params => dispatch(updateConsumerData(params)),
        onResetPassword: params => dispatch(forgotPassword(params)),
        clearServerError: () => dispatch(clearError('CURRENT_USER')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDetails)
